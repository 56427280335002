import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { formatDate } from "src/helper/formatDate";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function RequestCard(props: any) {
  const [isNew, setIsNew] = useState<boolean>(false);
  const { i18n, t } = useTranslation(["CompanyDetails"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  useEffect(() => {
    const todayDate = new Date();
    const temp = new Date(
      props.parentRef === "companyChatCard"
        ? formatDate(props?.data?.createdAt)
        : props?.lastUpdateDate
    );
    if (
      todayDate.getFullYear() === temp.getFullYear() &&
      todayDate.getMonth() === temp.getMonth() &&
      todayDate.getDate() === temp.getDate()
    ) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [props]);

  const handleChatRequest = (chatHistoryId: any) => {
    console.log("props.parentRef", props.parentRef, chatHistoryId);
    if (props.parentRef === "companyChatCard") {
      let historyData = {
        activeChatReqId: chatHistoryId,
        notificationId: chatHistoryId,
        reference: "chatPage",
      };
      navigate(`/chat-details`, { state: historyData });
    } else {
      let historyData = {
        notificationId: chatHistoryId,
        reference: "chatPage",
      };
      navigate(`/chat-message-page`, { state: historyData });
    }
  };

  // console.log("selectedCard", props?.status, props?.cardType, props);
  return (
    <div className={`${styles.cardWrap} `}>
      <div className={`${styles.headWrap}`}>
        <span className={`${styles.cardTitle}`}>
          <i className={`${styles.icon} ${styles.company}`}></i>
          {`${
            (props.parentRef === "companyChatCard" && props?.status === "pending" && props?.cardType === "outgoing") 
              ? props?.data?.Sender_Detail?.lrDetails.length > 0 ? props?.data?.Sender_Detail?.lrDetails[0]?.companyname : props?.data?.Sender_Detail?.userdetail?.firstname
              : props.parentRef === "companyDetails"
              ? `${t("companydetails_requestTitle")} #${props.index}`
              : props.parentRef === "companyChatCard"
              ? props?.data?.firstname
              : props?.Company?.name
          }`}
        </span>
        {isNew ? (
          <span className={`${styles.cardTag}`}>
            {t("companydetails_newTagTitle")}
          </span>
        ) : (
          <span className={`${styles.date}`}>
            {props.parentRef === "companyChatCard"
              ? formatDate(props?.data?.createdAt)
              : formatDate(props?.lastUpdateDate)}
          </span>
        )}
      </div>
      <div className={`${styles.footWrap}`}>
        <p className={`${styles.cardDesc}`}>
          {`${
            props.parentRef === "companyDetails"
              ? `Hey, I am interested in ${props.companyName}. Do you want to chat with me?`
              : props.parentRef === "companyChatCard"
              ? props?.data?.message
              : props?.message
          }`}
        </p>
        {props.parentRef === "companyDetails" || (props.parentRef === "companyChatCard" && props?.status === "pending" && props?.cardType === "outgoing") ? (
          <>
            <></>
          </>
        ) : props.parentRef === "companyChatCard" &&
          (getSessionKey("userRole") === "individual_investor" ||
            getSessionKey("userRole") === "legalrepresent" ||
            getSessionKey("userRole") === "advisor") ? (
            // (getSessionKey("userRole") === "advisor" && getSessionKey("selectedClient")?.participateinChat)) ? (
          <>
            <button
              className="cm-chat-btn"
              onClick={() => {
                handleChatRequest(props?.data?.activechatrequestid);
              }}
            >
              Chat
            </button>
          </>
        ) : getSessionKey("userRole") === "individual_investor" ||
          getSessionKey("userRole") === "legalrepresent" ||
          getSessionKey("userRole") === "advisor" ? (
          // (getSessionKey("userRole") === "advisor" && getSessionKey("selectedClient")?.participateinChat) ? (
          <>
            <button
              className="cm-chat-btn"
              onClick={() => {
                handleChatRequest(props?.id);
              }}
            >
              Chat
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default RequestCard;
