import HighlightCard, {
  HighlightCardData,
} from "src/components/highlight-card/HighlightCard";
import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import Header from "src/components/common/header/Header";
import axios from "axios";
import { authToken, headers } from "../../helper/authToken";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import { getSessionKey } from "src/helper/sessionServices";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { Tab, Tabs } from "@mui/material";
import CheckBox from "src/components/common/checkbox/CheckBox";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

let filterTabItems: any[] = [];

type FilterDataType = {
  Category: string[];
  Date: string[];
  "Provience / Cities": string[];
};

function HighlightListing() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Highlight"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const [highlightData, setHighlightData] = useState<HighlightCardData[]>([]);
  const [highlightSearchData, setHighlightSearchData] = useState<
    HighlightCardData[]
  >([]);
  const [triggerGetHighlight, setTriggerGetHighlight] = useState<boolean>(true);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [provienceData, setProvienceData] = useState<string[]>([]);

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    axios
      .get("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/country", {
        headers: {
          Authorization: `Bearer ${
            getSessionKey("authToken") !== null
              ? getSessionKey("authToken").accesstoken
              : ""
          }`,
        },
      })
      .then((response) => {
        let tempList: string[] = [];
        response.data.data.forEach((country: any) => {
          if (country.region.length > 0) {
            country.region.forEach((region: any) => {
              if (region.city.length > 0) {
                region.city.forEach((item: any) => {
                  tempList.push(item?.cityname);
                });
              }
            });
          }
        });
        setProvienceData(tempList);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);

  useEffect(() => {
    if (provienceData.length > 0) {
      let jsonArray = provienceData.map((item) => {
        return {
          itemType: "checkbox",
          itemId: item,
          label: item,
          count: 0,
          isChecked: false,
          value: item,
        };
      });

      filterTabItems = [
        {
          id: "tab01",
          title: "Category",
          isActive: false,
          tabPanelItems: [
            {
              itemType: "checkbox",
              itemId: "online",
              label: "Online Event",
              count: 15,
              isChecked: false,
              value: "online",
            },
            {
              itemType: "checkbox",
              itemId: "offline",
              label: "Offline Event",
              count: 12,
              isChecked: false,
              value: "offline",
            },
            {
              itemType: "checkbox",
              itemId: "communication",
              label: "Communication",
              count: 12,
              isChecked: false,
              value: "communication",
            },
            {
              itemType: "checkbox",
              itemId: "Read",
              label: "Reads",
              count: 5,
              isChecked: false,
              value: "Read",
            },
            {
              itemType: "checkbox",
              itemId: "important",
              label: "Important",
              count: 1,
              isChecked: false,
              value: "important",
            },
            {
              itemType: "checkbox",
              itemId: "Research",
              label: "Research",
              count: 2,
              isChecked: false,
              value: "Research",
            },
          ],
        },
        {
          id: "tab03",
          title: "Date",
          isActive: false,
          tabPanelItems: [
            {
              itemType: "radio",
              itemId: "30",
              label: "Last 1 Month",
              count: 0,
              isChecked: false,
              value: "30",
            },
            {
              itemType: "radio",
              itemId: "90",
              label: "Last 3 Months",
              count: 0,
              isChecked: false,
              value: "90",
            },
            {
              itemType: "radio",
              itemId: "",
              label: "All",
              count: 0,
              isChecked: false,
              value: "",
            },
          ],
        },
        {
          id: "tab04",
          title: "Provience / Cities",
          isActive: false,
          tabPanelItems: jsonArray,
        },
      ];
    }
  }, [provienceData]);
  //  en: [
  //         {
  //           id: "tab01",
  //           title: "Category",
  //           isActive: false,
  //           tabPanelItems: [
  //             {
  //               itemType: "checkbox",
  //               itemId: "online",
  //               label: "Online Event",
  //               count: 15,
  //               isChecked: false,
  //               value: "online",
  //             },
  //             {
  //               itemType: "checkbox",
  //               itemId: "offline",
  //               label: "Offline Event",
  //               count: 12,
  //               isChecked: false,
  //               value: "offline",
  //             },
  //             {
  //               itemType: "checkbox",
  //               itemId: "communication",
  //               label: "Communication",
  //               count: 12,
  //               isChecked: false,
  //               value: "communication",
  //             },
  //             {
  //               itemType: "checkbox",
  //               itemId: "Read",
  //               label: "Reads",
  //               count: 5,
  //               isChecked: false,
  //               value: "Read",
  //             },
  //             {
  //               itemType: "checkbox",
  //               itemId: "important",
  //               label: "Important",
  //               count: 1,
  //               isChecked: false,
  //               value: "important",
  //             },
  //             {
  //               itemType: "checkbox",
  //               itemId: "Research",
  //               label: "Research",
  //               count: 2,
  //               isChecked: false,
  //               value: "Research",
  //             },
  //           ],
  //         },
  //         {
  //           id: "tab03",
  //           title: "Date",
  //           isActive: false,
  //           tabPanelItems: [
  //             {
  //               itemType: "radio",
  //               itemId: "30",
  //               label: "Last 1 Month",
  //               count: 0,
  //               isChecked: false,
  //               value: "30",
  //             },
  //             {
  //               itemType: "radio",
  //               itemId: "90",
  //               label: "Last 3 Months",
  //               count: 0,
  //               isChecked: false,
  //               value: "90",
  //             },
  //             {
  //               itemType: "radio",
  //               itemId: "",
  //               label: "All",
  //               count: 0,
  //               isChecked: false,
  //               value: "",
  //             },
  //           ],
  //         },
  //         {
  //           id: "tab04",
  //           title: "Provience / Cities",
  //           isActive: false,
  //           tabPanelItems: jsonArray,
  //         },
  //       ],
  //       it: [
  //         {
  //           id: "tab01",
  //           title: "Categoria",
  //           isActive: false,
  //           tabPanelItems: [
  //             {
  //               itemType: "checkbox",
  //               itemId: "online",
  //               label: "Evento online",
  //               count: 15,
  //               isChecked: false,
  //               value: "online",
  //             },
  //             {
  //               itemType: "checkbox",
  //               itemId: "offline",
  //               label: "Evento offline",
  //               count: 12,
  //               isChecked: false,
  //               value: "offline",
  //             },
  //             {
  //               itemType: "checkbox",
  //               itemId: "communication",
  //               label: "Communication",
  //               count: 12,
  //               isChecked: false,
  //               value: "communication",
  //             },
  //             {
  //               itemType: "checkbox",
  //               itemId: "Read",
  //               label: "Da leggere",
  //               count: 5,
  //               isChecked: false,
  //               value: "Read",
  //             },
  //             {
  //               itemType: "checkbox",
  //               itemId: "important",
  //               label: "Importante",
  //               count: 1,
  //               isChecked: false,
  //               value: "important",
  //             },
  //             {
  //               itemType: "checkbox",
  //               itemId: "Research",
  //               label: "Ricerca",
  //               count: 2,
  //               isChecked: false,
  //               value: "Research",
  //             },
  //           ],
  //         },
  //         {
  //           id: "tab03",
  //           title: "Data",
  //           isActive: false,
  //           tabPanelItems: [
  //             {
  //               itemType: "radio",
  //               itemId: "30",
  //               label: "Ultimo mese",
  //               count: 0,
  //               isChecked: false,
  //               value: "30",
  //             },
  //             {
  //               itemType: "radio",
  //               itemId: "90",
  //               label: "Ultimo 3 mese",
  //               count: 0,
  //               isChecked: false,
  //               value: "90",
  //             },
  //             {
  //               itemType: "radio",
  //               itemId: "",
  //               label: "Tutte",
  //               count: 0,
  //               isChecked: false,
  //               value: "",
  //             },
  //           ],
  //         },
  //         {
  //           id: "tab04",
  //           title: "Provincia / Città",
  //           isActive: false,
  //           tabPanelItems: jsonArray,
  //         },
  //       ],

  useEffect(() => {
    if (triggerGetHighlight) {
      setLoading(true);
      // Make a GET request with the specified headers
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/highlight?lang_id=${getSessionKey("userData")?.lang_id}`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          setHighlightData(response?.data?.data);
          setHighlightSearchData(response?.data?.data);
          setLoading(false);
          setSelectedFilter({
            Category: [],
            Date: [],
            "Provience / Cities": [],
          });
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });

      setTriggerGetHighlight(false);
    }
  }, [triggerGetHighlight]);

  useEffect(() => {
    // setHighlightSearchData(highlightData);
    setSearchTerm("");
  }, [highlightData]);

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (searchTerm.length >= 3) {
      let tempHighArr = [];
      tempHighArr = highlightSearchData.filter((item) => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setHighlightSearchData(tempHighArr);
    } else {
      setHighlightSearchData(highlightData);
    }
  }, [searchTerm]);

  const isWithinLast30Days = (date: any, count: any) => {
    const currentDate: any = new Date();
    const givenDate: any = new Date(date);
    const diffTime = Math.abs(currentDate - givenDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= parseInt(count);
  };

  const [selectedFilter, setSelectedFilter] = useState<FilterDataType>({
    Category: [],
    Date: [],
    "Provience / Cities": [],
  });
  const handleSelectFilter = () => {
    let tempHighArr = [...highlightData];
    Object.keys(selectedFilter).forEach((key) => {
      tempHighArr = tempHighArr.filter((item) => {
        if (key === "Category" && selectedFilter[key].length > 0)
          return (
            selectedFilter[key].includes(item.eventType) ||
            selectedFilter[key].includes(item.subtype!)
          );
        else if (key === "Provience / Cities" && selectedFilter[key].length > 0)
          return selectedFilter[key].includes(item.provience);
        else if (key === "Date" && selectedFilter[key].length > 0) {
          return selectedFilter[key].includes("30") ||
            selectedFilter[key].includes("90")
            ? isWithinLast30Days(item.eventdate, selectedFilter[key][0])
            : item;
        } else return item;
      });
    });
    setHighlightSearchData(tempHighArr);
    setOpenFilterDrawer(false);
  };

  const handleClearAll = () => {
    setSelectedFilter({
      Category: [],
      Date: [],
      "Provience / Cities": [],
    });
    setHighlightSearchData(highlightData);
    setOpenFilterDrawer(false);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    //setSelectedFilter(newValue);
  };

  const handleFilterChange = (value: any, name: any, secName: any) => {
    if (name === "radio") {
      setSelectedFilter((prevData: any) => {
        const updatedArray = [];
        updatedArray.push(value.value);
        return { ...prevData, [secName]: updatedArray };
      });
    } else {
      setSelectedFilter((prevData: any) => {
        // Create a copy of the specific array
        const updatedArray = [...prevData[secName]];
        // Check if the value is already present
        const valueIndex = updatedArray.indexOf(name);

        if (valueIndex === -1) {
          // If the value is not present, add it
          updatedArray.push(name);
        } else {
          // If the value is present, remove it
          updatedArray.splice(valueIndex, 1);
        }

        // Return the new state object with the updated array
        return { ...prevData, [secName]: updatedArray };
      });
    }
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header backLink={true} pageTitle="Highlights"></Header>
        <button
          className="filter-btn"
          type="button"
          onClick={() => setOpenFilterDrawer(true)}
        >
          <i></i>
          {t("highlight_filterBtn")}
        </button>
      </header>
      <main>
        <div className="lyt-main">
          {/* <section>
            <div className="bs-section"></div>
          </section> */}
          <section>
            <div className="bs-section typ-depth">
              <div className="sec-cont">
                <div className="bs-form">
                  <div className="search-wrap">
                    <span className="search-icon"></span>
                    <input
                      type="text"
                      placeholder={t("highlight_searchPlaceholder")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button
                      className="close-btn"
                      type="button"
                      onClick={() => setSearchTerm("")}
                    >
                      <i></i>
                    </button>
                  </div>
                </div>
                <div className={`${styles.highlightList}`}>
                  {highlightSearchData.map((item, index) => {
                    return (
                      <div className={`${styles.item}`} key={index}>
                        <HighlightCard
                          {...item}
                          setTriggerGetHighlight={setTriggerGetHighlight}
                        ></HighlightCard>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
        <BottomSheet
          title={t("highlight_filterBtn")}
          primaryBtnText={t("highlight_applyBtn")}
          secondaryBtnText={t("highlight_clearAllBtn")}
          size="large"
          open={openFilterDrawer}
          setOpen={setOpenFilterDrawer}
          btnOnClick={handleSelectFilter}
          btnSecondaryOnClick={handleClearAll}
        >
          <div className="bs-filter">
            <div className="left-wrap">
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                {filterTabItems.map((item, index) => {
                  return <Tab key={index} label={item.title} />;
                })}
              </Tabs>
            </div>
            <div className="right-wrap">
              {filterTabItems.map((ele, index) => {
                return (
                  <div
                    className="tabpanel"
                    role="tabpanel"
                    hidden={value !== index}
                    key={index}
                  >
                    <div className="panel-list">
                      {ele.tabPanelItems.map((item: any, index: number) => {
                        return (
                          <div className="filter-item" key={index}>
                            {item.itemType === "radio" ? (
                              <>
                                <div className="radio-group">
                                  <input
                                    type="radio"
                                    id={item.itemId}
                                    name={item.itemType}
                                    value={item.value}
                                    onChange={(e: any) => {
                                      handleFilterChange(
                                        e.target,
                                        e.target.name,
                                        ele.title
                                      );
                                    }}
                                    checked={selectedFilter[
                                      ele.title as keyof FilterDataType
                                    ].includes(item.value)}
                                  />
                                  <label htmlFor={item.itemId}>
                                    {item.label}
                                  </label>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {item.itemType === "checkbox" ? (
                              <>
                                <CheckBox
                                  id={item.itemId}
                                  label={item.label}
                                  name={item.itemId}
                                  value={item.value}
                                  onChange={(e: any) => {
                                    handleFilterChange(
                                      e.target,
                                      e.target.name,
                                      ele.title
                                    );
                                  }}
                                  isChecked={selectedFilter[
                                    ele.title as keyof FilterDataType
                                  ].includes(item.itemId)}
                                ></CheckBox>
                              </>
                            ) : (
                              ""
                            )}
                            {/* {item.count !== 0 ? (
                              <span className="count">{item.count}</span>
                            ) : (
                              <></>
                            )} */}
                          </div>
                        );
                      })}
                      `
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </BottomSheet>
      </main>
    </>
  );
}

export default HighlightListing;
