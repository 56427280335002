import RadioGroupField, {
  RadioField,
} from "src/components/common/radio-group/RadioGroupField";
import styles from "./style.module.scss";
import SwiperComponent from "src/components/common/swiper/SwiperComponent";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { actionCreators, useAppDispatch, useAppSelector } from "../../store";
import { setSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

interface LanguageIcon {
  language_code: string;
  language_icon: string;
}

interface LanguageData {
  language_code: string;
  language_name: string;
  language_icon: string | undefined;
  id?: string | number;
}

function Onboarding() {
  const [showNextSection, setShowNextSection] = useState(false);
  const [languageCode, setLanguageCode] = useState<string | undefined>("en");
  const [activeSlide, setActiveSlide] = useState("languageSelection");
  const [languageData, setLanguageData] = useState<LanguageData[]>([]);
  const [languageSelectionRadio, setLanguageSelectionRadio] = useState<
    RadioField[]
  >([]);
  const [selectedLang, setSelectedLang] = useState<string | number>(1);
  const dispatch = useAppDispatch();
  const { data, error, loading } = useAppSelector((state) => state.getLanguage);
  const langIconArr: LanguageIcon[] = [
    {
      language_code: "it",
      language_icon: "/assets/flags/italy.png",
    },
    {
      language_code: "en",
      language_icon: "/assets/flags/england.png",
    },
    {
      language_code: "hin",
      language_icon: "/assets/flags/india.png",
    },
  ];

  useEffect(() => {
    dispatch(actionCreators.getLanguages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSessionKey("langMaster", JSON.stringify(data));
    if (data?.length > 0) {
      const modifiedData = data.map((item) => ({
        ...item,
        language_icon: langIconArr.find(
          (ele) => ele?.language_code === item?.language_code
        )?.language_icon,
      }));
      setLanguageData(modifiedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (languageData.length > 0) {
      let languageSelectionRadioArr: any[] = [];
      languageData.forEach((item) => {
        let temp = {
          value: item.id,
          label: (
            <span>
              <img
                // srcSet={`https://flagcdn.com/w40/${item.language_code.toLowerCase()}.png 2x`}
                // src={`https://flagcdn.com/w20/${item.language_code.toLowerCase()}.png`}
                src={item.language_icon}
                alt="lang icon"
              />
              <span>
                {`${
                  item.language_code === "en"
                    ? "Continue in English"
                    : item.language_code === "it"
                    ? "Continua in Italiano"
                    : "Continue in "
                }`}
                {/* Continue in {item.language_name} */}
              </span>
            </span>
          ),
        };
        languageSelectionRadioArr.push(temp);
      });
      setSessionKey("langMaster", JSON.stringify(languageSelectionRadioArr));
      setLanguageSelectionRadio(languageSelectionRadioArr);
    }
  }, [languageData]);

  useEffect(() => {
    // if(languageSelectionRadio.length > 0)
    //   setSelectedLang(languageSelectionRadio[0]?.value)
  }, [languageSelectionRadio]);

  // const languageSelectionRadio: RadioField[] = [
  //   {
  //     value: "italian",
  //     label: (
  //       <span>
  //         <img src="/assets/flags/italy.png" alt="" />
  //         <span>Continua in Italiano</span>
  //       </span>
  //     ),
  //   },
  //   {
  //     value: "english",
  //     label: (
  //       <span>
  //         <img src="/assets/flags/england.png" alt="" />
  //         <span>Continue in English</span>
  //       </span>
  //     ),
  //   },
  // ];
  const onboardingSlides: { [key: string]: any[] } = {
    en: [
      {
        id: "slide01",
        smallTitle: "AI-X:",
        bigTitle: "Your Partner of choice for India and South East Asia",
      },
      {
        id: "slide02",
        smallTitle: "Connect with",
        bigTitle: "Other Members and find new impact opportunities",
      },
      {
        id: "slide01",
        smallTitle: "Your",
        bigTitle: "Portfolio with just one click",
      },
    ],
    it: [
      {
        id: "slide01",
        smallTitle: "AI-X:",
        bigTitle: "Il tuo partner di fiducia per l'India e il Sud-Est Asiatico",
      },
      {
        id: "slide02",
        smallTitle: "Entra in contatto",
        bigTitle: "Con altri Utenti e trova nuove opportunità Impact",
      },
      {
        id: "slide01",
        smallTitle: "Il tuo",
        bigTitle: "Portfolio a portata di click",
      },
    ],
  };
  const { i18n, t } = useTranslation(["Onboarding"]);
  const handleLanguageChange = (e: any) => {
    i18next.changeLanguage(e.language_code);
  };

  const languageChange = (e: any) => {
    setSelectedLang(e.target.value);
    const temp = languageData.find((item) => item.id == e.target.value);
    handleLanguageChange(temp);
    setLanguageCode(temp?.language_code);
  };

  return (
    <>
      {loading && <h3>Loading....</h3>}
      {!error && !loading && data && (
        <div className={`${styles.onboardingWrap}`}>
          {activeSlide === "languageSelection" ? (
            <section>
              <div className="bs-section">
                <div className="sec-cont">
                  <div className={`${styles.logoWrap}`}>
                    <img src="assets/company-logo.png" alt="ai-x" />
                  </div>
                  <div className={`${styles.languageSelection}`}>
                    <h2>{t("onboard_pgTitle")}</h2>
                    <div className="card-radio-group typ-flat">
                      <RadioGroupField
                        name="language-selection"
                        radioField={languageSelectionRadio}
                        value={selectedLang}
                        radioChange={languageChange}
                      ></RadioGroupField>
                    </div>
                  </div>
                  <div className={`${styles.footWrap}`}>
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(actionCreators.setLanguage(selectedLang));
                        setSessionKey(
                          "selectedLang",
                          JSON.stringify(selectedLang)
                        );
                        setSessionKey(
                          "selectedLangCode",
                          JSON.stringify(languageCode)
                        );
                        setActiveSlide("onboardingStep");
                      }}
                    >
                      {t("onboard_proceedBtn")}
                    </button>
                  </div>
                </div>
              </div>
            </section>
          ) : activeSlide === "loginSection" ? (
            <section>
              <div className="bs-section">
                <div className={`${styles.secCont} sec-cont`}>
                  <div className={`${styles.logoWrap} ${styles.typHead}`}>
                    <img src="assets/company-logo.png" alt="ai-x" />
                  </div>
                  <div className={`${styles.featureWrap}`}>
                    <h2>{t("onboard_loginTitle")}</h2>
                    <ul>
                      <li>
                        <i className={`${styles.tikIcon}`}></i>
                        <p>{t("onboard_loginFeature1")}</p>
                      </li>
                      <li>
                        <i className={`${styles.opportunitiesIcon}`}></i>
                        <p>{t("onboard_loginFeature2")}</p>
                      </li>
                      <li>
                        <i className={`${styles.keyIcon}`}></i>
                        <p>{t("onboard_loginFeature3")}</p>
                      </li>
                    </ul>
                  </div>
                  <div className={`${styles.footWrap}`}>
                    <NavLink to={"/login"}>
                      {t("onboard_loginBtn")}
                      <i className={`${styles.iconNext}`}></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </section>
          ) : activeSlide === "onboardingStep" ? (
            <section>
              <div className="bs-section typ-onboarding-steps">
                <div className="sec-cont">
                  <SwiperComponent
                    swiperConfig={{
                      slidesPerView: 1,
                      loop: false,
                      modules: [Autoplay, EffectFade, Pagination, Navigation],
                      spaceBetween: 0,
                      effect: "fade",
                      autoplay: {
                        delay: 4800,
                      },
                      fadeEffect: {
                        crossFade: true,
                      },
                      pagination: {
                        clickable: false,
                      },
                      navigation: {
                        nextEl: ".next-btn",
                        prevEl: ".prev-btn",
                      },
                      on: {
                        paginationUpdate: (swiper, paginationEl) => {
                          paginationEl
                            .querySelectorAll(".swiper-pagination-bullet")
                            [swiper.previousIndex]?.classList.add("visited");
                        },
                        slideChangeTransitionEnd(swiper) {
                          const nextSlide =
                            swiper.slides[swiper.activeIndex + 1];
                          if (
                            nextSlide &&
                            !nextSlide.classList.contains("visited")
                          ) {
                            swiper.autoplay.start();
                          }
                        },
                        slideChange: (swiper) => {
                          const paginationBullets = swiper.pagination.bullets;
                          paginationBullets.forEach((bullet, index) => {
                            if (index > swiper.activeIndex) {
                              bullet.classList.remove("visited");
                            }
                          });
                          if (
                            swiper.activeIndex ===
                            onboardingSlides.en.length - 1
                          ) {
                            setTimeout(() => {
                              setShowNextSection(true);
                            }, 1000);
                          } else {
                            setShowNextSection(false);
                          }
                        },
                        reachEnd: (swiper) => {
                          swiper.autoplay.stop();
                        },
                      },
                    }}
                  >
                    {onboardingSlides[i18n.language].map(
                      (item: any, index: number) => {
                        return (
                          <div key={item.id} className={`${styles.stepsWrap}`}>
                            <h2>
                              <span>{item.smallTitle}</span>
                              {item.bigTitle}
                            </h2>
                          </div>
                        );
                      }
                    )}
                  </SwiperComponent>
                  <div className={`${styles.footWrap}`}>
                    {!showNextSection ? (
                      <button
                        className={`next-btn ${styles.nextSlide}`}
                        type="button"
                      >
                        {t("onboard_nextBtn")}
                        <i className={`${styles.iconNext}`}></i>
                      </button>
                    ) : (
                      <button
                        className={`${styles.nextSlide}`}
                        type="button"
                        onClick={() => setActiveSlide("loginSection")}
                        disabled={false}
                      >
                        {t("onboard_nextBtn")}
                        <i className={`${styles.iconNext}`}></i>
                      </button>
                    )}
                    <button
                      className={`prev-btn ${styles.prevSlide}`}
                      type="button"
                    ></button>
                  </div>
                  <button
                    className={`${styles.skipBtn}`}
                    onClick={() => setActiveSlide("loginSection")}
                  >
                    {t("onboard_skipBtn")}
                  </button>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}

export default Onboarding;
