import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getSessionKey, setSessionKey } from './sessionServices';

interface DeviceInfo {
  userAgent: string;
  deviceId: string;
  timeZone: string;
  deviceType: 'mobile' | 'tablet' | 'desktop';
  deviceOrientation: 'portrait' | 'landscape';
  connectionType: string | null;
  deviceName: string;
  location: {
    latitude: number | null;
    longitude: number | null;
  };
}

const useDeviceInfo = (): DeviceInfo => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    userAgent: '',
    deviceId: '',
    timeZone: '',
    deviceType: 'desktop',
    deviceOrientation: 'landscape',
    connectionType: null,
    deviceName: '',
    location: {
      latitude: null,
      longitude: null,
    },
  });

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let deviceId = getSessionKey('deviceId');
    
    if (!deviceId) {
      deviceId = uuidv4();
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const getDeviceType = (): 'mobile' | 'tablet' | 'desktop' => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/mobi|android|touch|mini/.test(userAgent)) {
        if (/tablet|ipad|playbook/.test(userAgent)) {
          return 'tablet';
        }
        return 'mobile';
      }
      return 'desktop';
    };

    const getDeviceOrientation = (): 'portrait' | 'landscape' => {
      return window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape';
    };

    const getConnectionType = (): string | null => {
      const connection = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
      return connection ? connection.effectiveType : null;
    };

    const getDeviceName = (): string => {
      const userAgent = navigator.userAgent;
      if (/iPhone/.test(userAgent)) {
        return 'iPhone';
      } else if (/iPad/.test(userAgent)) {
        return 'iPad';
      } else if (/Android/.test(userAgent)) {
        return 'Android Device';
      } else if (/Mac/.test(userAgent)) {
        return 'Mac';
      } else if (/Win/.test(userAgent)) {
        return 'Windows PC';
      } else {
        return 'Unknown Device';
      }
    };

    const updateLocation = (position: GeolocationPosition) => {
      setDeviceInfo(prevInfo => ({
        ...prevInfo,
        location: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }
      }));
    };

    const handleLocationError = (error: GeolocationPositionError) => {
      console.error('Error getting location:', error.message);
    };

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(updateLocation, handleLocationError);
      }
    };

    setDeviceInfo({
      userAgent,
      deviceId: deviceId || uuidv4(),
      timeZone,
      deviceType: getDeviceType(),
      deviceOrientation: getDeviceOrientation(),
      connectionType: getConnectionType(),
      deviceName: getDeviceName(),
      location: {
        latitude: null,
        longitude: null,
      },
    });

    getLocation();

    const handleOrientationChange = () => {
      setDeviceInfo(prevInfo => ({
        ...prevInfo,
        deviceOrientation: getDeviceOrientation()
      }));
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return deviceInfo;
};

export default useDeviceInfo;
