import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import Button from "src/components/common/button/Button";
import axios from "axios";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
export interface NotificationItem {
  id?: any;
  title: string;
  isNew: boolean;
  description: string;
  timestamp: string;
  viewBtnText?: string;
  viewBtnLink?: string;
  itemType?: "report" | "chat";
  porfolioName?: string;
  inPortfolio?: boolean;
  chatMessage?: string;
  status?: string;
  lastUpdateDate?: string;
  message?: string;
  type?: any;
  Company?: any;
  is_read?: boolean;
  receiver_id?: number;
  sender_id?: number;
  company_details?: any;
  companyid?: number;
}

function Notification() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Notification"]);
  const lang = getSessionKey("selectedLangCode");
  const userData: any = getSessionKey("userData");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  const [notificationData, setNotificationData] = useState<NotificationItem[]>([
    // {
    //   id: "001",
    //   title: `${t("notification_accessPinTitle")}`,
    //   isNew: false,
    //   description: `${t("notification_accessPinSubTitle")}`,
    //   timestamp: "Wednesday, 14 February 2024 13:27:13 GMT+05:30",
    // },
    // {
    //   id: "002",
    //   title: `${t("notification_newRequestTitle")}`,
    //   description: `${t("notification_newRequestSubTitle")}`,
    //   isNew: true,
    //   itemType: "chat",
    //   timestamp: "Wednesday, 22 April 2024 13:27:13 GMT+05:30",
    //   viewBtnText: `${t("notification_viewChat")}`,
    //   viewBtnLink: "",
    //   porfolioName: "Banyan Nation",
    //   inPortfolio: true,
    //   chatMessage: "I am interested in buying 500 DRs for 5€/DR.",
    // },
    // {
    //   id: "003",
    //   title: `${t("notification_approvalPending")}`,
    //   isNew: true,
    //   description: `${t("notification_advisorMessage")}`,
    //   timestamp: "Wednesday, 23 January 2024 13:27:13 GMT+05:30",
    //   viewBtnText: `${t("notification_viewBtn")}`,
    //   viewBtnLink: "",
    // },
    // {
    //   id: "004",
    //   title: "Veditas 2nd Quaterly Report",
    //   isNew: true,
    //   description:
    //     "Veditas observes a new change in the sustainable market in India.",
    //   timestamp: "Wednesday, 7 February 2024 13:27:13 GMT+05:30",
    //   viewBtnText: `${t("notification_viewReportBtn")}`,
    //   viewBtnLink: "",
    //   itemType: "report",
    // },
  ]);
  const [todayData, setTodayData] = useState<NotificationItem[]>([]);
  const [olderData, setOlderData] = useState<NotificationItem[]>([]);
  const [triggeredGetNotification, setTriggeredGetNotification] =
    useState<boolean>(true);

  useEffect(() => {
    let todayData: NotificationItem[] = [],
      olderData: NotificationItem[] = [];

    console.log("notificationData", notificationData);
    if (notificationData.length > 0) {
      //   useEffect(() => {
      let todayDate = new Date();
      todayData = notificationData.filter((item) => {
        if (item?.lastUpdateDate !== undefined || item?.timestamp !== undefined) {
          const temp = new Date(item?.lastUpdateDate || item?.timestamp);
          return (
            todayDate.getFullYear() === temp.getFullYear() &&
            todayDate.getMonth() === temp.getMonth() &&
            todayDate.getDate() === temp.getDate()
          );
        }
      });
      olderData = notificationData.filter((item) => {
        if (item?.lastUpdateDate !== undefined || item?.timestamp !== undefined) {
          const temp = new Date(item?.lastUpdateDate || item?.timestamp);
          // return temp.getTime() < todayDate.getTime();
          return todayDate.getDate() !== temp.getDate();
        }
      });

      console.log("todayData", todayData, olderData);
      setTodayData(todayData);
      setOlderData(olderData);
    }
  }, [notificationData]);

  useEffect(() => {
    if (triggeredGetNotification) {
      axios
        .get(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/notification",
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.success) {
            setNotificationData(response?.data?.data);
            setSessionKey("notificationData", response?.data?.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });

      setTriggeredGetNotification(false);
    }
  }, [triggeredGetNotification]);

  function getTimeDifference(targetDate: string | undefined): any {
    const currentDate = new Date();
    if (targetDate !== undefined) {
      const givenDate = new Date(targetDate);

      const diffTime = Math.abs(givenDate.getTime() - currentDate.getTime());

      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor(
        (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMinutes = Math.floor(
        (diffTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const diffSeconds = Math.floor((diffTime % (1000 * 60)) / 1000);

      return {
        days: `${diffDays} ${t("notification_timelineDays")}`,
        hours: `${diffHours} ${t("notification_timelineHours")}`,
        minutes: `${diffMinutes} ${t("notification_timelineMinutes")}`,
        seconds: `${diffSeconds} ${t("notification_timelineSeconds")}`,
      };
    }
    return {
      days: "",
      hours: "",
      minutes: "",
      seconds: "",
    };
  }

  const handleNotification = (
    notificationId: number,
    notificationType: string,
    item: any
  ) => {
    if (item.type === "message" || item.type === "socketmessage") {
      let payload: any = {
        id: notificationId,
        is_read: true,
      };
      if(item.type === "socketmessage") {
        payload['conversation_id'] = item?.conversation_id;
      }
      axios
        .put(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/notification",
          payload,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.success) {
            if(item.type === "socketmessage") {
              let historyData = {
                notificationId: notificationId,
                reference: "notification",
              };
              navigate(
                `${"/chat-details-socket"}`,
                { state: historyData }
              );
            } else {
              if (
                item.activerequestchat?.request_status === "accepted" &&
                notificationType === "activechatrequesthistory"
              ) {
                let historyData = {
                  notificationId: notificationId,
                  activeChatReqId: item?.activechatrequestid,
                  reference: "notification",
                };
                navigate("/chat-details", { state: historyData });
              } else {
                let historyData = {
                  notificationId: notificationId,
                  reference: "notification",
                };
                navigate(
                  `${
                    notificationType === "activechatrequesthistory"
                      ? "/chat-message-page"
                      : notificationType === "approval"
                      ? "/approval-page"
                      : notificationType === "approval pending"
                      ? "/chat-confirm-request"
                      : item.title === "Message from AI Officer"
                      ? "/ai-message"
                      : item.title.toLowerCase() ===
                        "New report Added".toLowerCase()
                      ? `/report-detail?reportcode=${item?.otherdetail}`
                      : item.title.toLowerCase() ===
                        "New Highlight Added".toLowerCase()
                      ? `/highlight-detail?highlightcode=${item?.otherdetail}`
                      : ""
                  }`,
                  { state: historyData }
                );
              }
              setTriggeredGetNotification(true);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
            // let historyData = {
            //   notificationId: notificationId,
            //   reference: "notification",
            // };
            // navigate(
            //   `${"/chat-details-socket"}`,
            //   { state: historyData }
            // );
          }
        });
    } else if (
      item.activerequestchat?.request_status === "accepted" &&
      notificationType === "activechatrequesthistory"
    ) {
      let historyData = {
        notificationId: notificationId,
        activeChatReqId: item?.activechatrequestid,
        reference: "notification",
      };
      navigate("/chat-details", { state: historyData });
    } else {
      let historyData = {
        notificationId: notificationId,
        reference: "notification",
      };
      navigate(
        `${
          notificationType === "activechatrequesthistory"
            ? "/chat-message-page"
            : notificationType === "approval"
            ? "/approval-page"
            : notificationType === "approval pending"
            ? "/chat-confirm-request"
            : item.title === "Message from AI Officer"
            ? "/ai-message"
            : ""
        }`,
        { state: historyData }
      );
    }
  };

  const checkCompanyId = (arr: any[], companyId: number | undefined) => {
    return arr.some((item) => item.companyid === companyId);
  };

  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-notification">
          {todayData.length !== 0 ? (
            <div className={`${styles.section}`}>
              <h2 className={`${styles.sectionTitle}`}>
                {t("notification_today")}
              </h2>
              <div className={`${styles.notificationWrapper}`}>
                <ul className={`${styles.list}`}>
                  {todayData.map((item, index) => {
                    return (
                      item.status !== "approved" &&
                      item.is_read !== true &&
                      item.receiver_id !== item.sender_id && (
                        <li
                          key={index}
                          className={`${styles.item} ${
                            item.is_read === null || item.is_read === false
                              ? styles.typNew
                              : ""
                          }`}
                        >
                          <h3 className={`${styles.notificationTitle}`}>
                            {item.type === "approval pending" &&
                            item?.message?.includes("invite the AI Officer")
                              ? `${t("notification_OfficerInvitedTitle")}`
                              : item.type === "approval" ||
                                item.type === "message"
                              ? item.title
                              : item.type === "activechatrequesthistory"
                              ? `${t("notification_newRequestTitle")}`
                              : item.type === "socketmessage"
                              ? `${userData?.role.toLowerCase() === 'advisor' ? 'Message from Client' : 'Message from Advisor'}`
                              : ""}
                          </h3>
                          <p className={`${styles.notificationDesc}`}>
                            {item.type === "approval" ||
                            item.type === "message" ||
                            item.type === "approval pending" || item.type === "socketmessage"
                              ? item?.message
                              : item.type === "activechatrequesthistory"
                              ? `${t("notification_newRequestSubTitle")}`
                              : ""}
                            {/* {item?.description !== undefined ? item?.description : item?.message} */}
                          </p>
                          {item.type === "activechatrequesthistory" ? (
                            <div className={`${styles.requestCard}`}>
                              <div className={`${styles.headWrap}`}>
                                {/* <strong>{item.porfolioName}</strong> */}
                                <strong>{item?.company_details?.name}</strong>
                                {/* {item.inPortfolio ? ( */}
                                {checkCompanyId(
                                  userData?.Portfolios,
                                  item.companyid
                                ) && (
                                  <span className={`${styles.tag}`}>
                                    In Portfolio
                                  </span>
                                )}
                                {/* ) : (
                                ""
                              )} */}
                              </div>
                              <div className={`${styles.footWrap}`}>
                                <p className={`${styles.cardDesc}`}>
                                  {item?.message}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className={`${styles.footWrap}`}>
                            <span className={`${styles.time}`}>
                              {!getTimeDifference(
                                item?.lastUpdateDate || item?.timestamp
                              ).hours.includes("0")
                                ? getTimeDifference(item?.lastUpdateDate || item?.timestamp).hours
                                : !getTimeDifference(
                                    item?.lastUpdateDate || item?.timestamp
                                  ).minutes.includes("0")
                                ? getTimeDifference(item?.lastUpdateDate || item?.timestamp)
                                    .minutes
                                : getTimeDifference(item?.lastUpdateDate || item?.timestamp)
                                    .seconds}{" "}
                              {/* Hours ago */}
                            </span>
                            {item.type === "activechatrequesthistory" ||
                            item.type === "approval" ||
                            item.type === "approval pending" ||
                            item.type === "message" || item.type === "socketmessage" ? (
                              <Button
                                btnStyle="text"
                                onClick={() => {
                                  handleNotification(
                                    item?.id,
                                    item?.type,
                                    item
                                  );
                                }}
                              >
                                {item.type === "activechatrequesthistory" || item.type === "socketmessage"
                                  ? `${t("notification_viewMsgBtn")}`
                                  : item.type === "approval" ||
                                    item.type === "approval pending" ||
                                    item?.title?.includes("AI Officer")
                                  ? `${t("notification_viewBtn")}`
                                  : item?.title?.includes("report")
                                  ? `${t("notification_viewReportBtn")}`
                                  : item.type === "message"
                                  ? `${t("notification_markAsReadBtn")}`
                                  : `${t("notification_viewChat")}`}
                                <i className={`${styles.iconNext}`}></i>
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
          {olderData.length !== 0 ? (
            <div className={`${styles.section}`}>
              <h2 className={`${styles.sectionTitle}`}>
                {t("notification_older")}
              </h2>
              <div className={`${styles.notificationWrapper}`}>
                <ul className={`${styles.list}`}>
                  {olderData.map((item, index) => {
                    return (
                      item.status !== "approved" &&
                      item.is_read !== true &&
                      item.receiver_id !== item.sender_id && (
                        <li
                          key={index}
                          className={`${styles.item} ${
                            item.is_read === null || item.is_read === false
                              ? styles.typNew
                              : ""
                          }`}
                        >
                          <h3 className={`${styles.notificationTitle}`}>
                            {item.type === "approval" || item.type === "message"
                              ? item.title
                              : item.type === "activechatrequesthistory"
                              ? `${t("notification_newRequestTitle")}`
                              : item.type === "socketmessage"
                              ? `${userData?.role.toLowerCase() === 'advisor' ? 'Message from Client' : 'Message from Advisor'}`
                              : ""}
                          </h3>
                          <p className={`${styles.notificationDesc}`}>
                            {item.type === "approval" || item.type === "message" || item.type === "socketmessage"
                              ? item?.message
                              : item.type === "activechatrequesthistory"
                              ? `${t("notification_newRequestSubTitle")}`
                              : ""}
                            {/* {item?.description !== undefined ? item?.description : item?.message} */}
                          </p>
                          {item.type === "activechatrequesthistory" ? (
                            <div className={`${styles.requestCard}`}>
                              <div className={`${styles.headWrap}`}>
                                {/* <strong>{item.porfolioName}</strong> */}
                                <strong>{item?.company_details?.name}</strong>
                                {/* {item.inPortfolio ? ( */}
                                <span className={`${styles.tag}`}>
                                  In Portfolio
                                </span>
                                {/* ) : (
                                ""
                              )} */}
                              </div>
                              <div className={`${styles.footWrap}`}>
                                <p className={`${styles.cardDesc}`}>
                                  {item?.message}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className={`${styles.footWrap}`}>
                            <span className={`${styles.time}`}>
                              {getTimeDifference(
                                item?.lastUpdateDate || item?.timestamp
                              ).days.includes("0")
                                ? `1 Day ago`
                                : getTimeDifference(item?.lastUpdateDate || item?.timestamp).days}
                              {/* Days ago */}
                            </span>
                            {item.type === "activechatrequesthistory" ||
                            item.type === "approval" ||
                            item.type === "approval pending" ||
                            item.type === "message" || item.type === "socketmessage" ? (
                              <Button
                                btnStyle="text"
                                onClick={() => {
                                  handleNotification(
                                    item?.id,
                                    item?.type,
                                    item
                                  );
                                }}
                              >
                                {item.type === "activechatrequesthistory" || item.type === "socketmessage"
                                  ? `${t("notification_viewMsgBtn")}`
                                  : item.type === "approval" ||
                                    item.type === "approval pending" ||
                                    item?.title?.includes("AI Officer")
                                  ? `${t("notification_viewBtn")}`
                                  : item?.title?.includes("report")
                                  ? `${t("notification_viewReportBtn")}`
                                  : item.type === "message"
                                  ? `${t("notification_markAsReadBtn")}`
                                  : `${t("notification_viewChat")}`}
                                <i className={`${styles.iconNext}`}></i>
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </main>
    </>
  );
}

export default Notification;
