import React, { useState } from "react";
import styles from "./style.module.scss";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function ProfileDrawer() {
  const { i18n, t } = useTranslation(["ProfileSettings"]);
  const navigate = useNavigate();
  const [openProfileDrawer, setOpenProfileDrawer] = useState(true);
  const handleProfileDrawerButtonClick = () => {
    setOpenProfileDrawer(false);
    navigate("/profile-update/create-pin");
  };
  return (
    <>
      <BottomSheet
        open={openProfileDrawer}
        primaryBtnText={t("profilesettings_drawerCompleteBtn")}
        title={t("profilesettings_drawerSetupTitle")}
        subText={t("profilesettings_drawerSetupSubTitle")}
        size="medium"
        setOpen={setOpenProfileDrawer}
        btnOnClick={handleProfileDrawerButtonClick}
      >
        <div className={`${styles.cardCont}`}>
          <ul className={`${styles.list}`}>
            <li className={`${styles.item}`}>
              <i className={`${styles.lock}`}></i>
              <span className={`${styles.text}`}>
                {t("profilesettings_drawerStepPin")}
              </span>
            </li>
            <li className={`${styles.item}`}>
              <i className={`${styles.addGallery}`}></i>
              <span className={`${styles.text}`}>
                {t("profilesettings_drawerStepPicture")}
              </span>
            </li>
            <li className={`${styles.item}`}>
              <i className={`${styles.addProfile}`}></i>
              <span className={`${styles.text}`}>
                {t("profilesettings_drawerStepAdvisor")}
              </span>
            </li>
          </ul>
        </div>
      </BottomSheet>
    </>
  );
}
