import Header from "src/components/common/header/Header";
import Button from "../../components/common/button/Button";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { useEffect } from "react";

function AdvisorIntro() {
  const { i18n, t } = useTranslation(["Advisor"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/add-advisor");
  };
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="introScreen">
          <div className={`${styles.logoContentWrap}`}>
            <i className={`${styles.success}`}></i>
            <div className={`${styles.contentWrap}`}>
              <h2 className={`${styles.title}`}>{t("advisor_introTitle")}</h2>
              <p className={`${styles.description}`}>
                {t("advisor_introSubTitle")}
              </p>
            </div>
          </div>
        </div>
      </main>
      <div className={`${styles.footWrap}`}>
        <Button btnStyle="primary" onClick={handleClick}>
          {t("advisor_introTitle")}
        </Button>
      </div>
    </>
  );
}

export default AdvisorIntro;
