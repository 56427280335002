import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import axios from "axios";

function ConfirmRequest() {
  const location = useLocation();
  const { i18n, t } = useTranslation(["Chat"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const [openRejectDrawer, setOpenRejectDrawer] = useState(false);
  const [clientData, setClientData] = useState<any>({});

  useEffect(() => {
    i18next.changeLanguage(lang);

    const userDataNotification =
      location.state.reference === "notification"
        ? getSessionKey("notificationData")
        : getSessionKey("activeChatRequestData");
    const filteredData = userDataNotification.filter((item: any) => {
      return item.id === location.state.notificationId;
    });

    setClientData(filteredData[0]);
  }, []);

  const handleAcceptButtonClick = () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${
          getSessionKey("authToken") !== null
            ? getSessionKey("authToken").accesstoken
            : ""
        }`,
        "Content-Type": "application/json",
      },
    };

    const payload = {
      id: clientData?.id,
      companyid: clientData?.companyid,
      activerequestid: clientData?.activerequestid,
      activechatrequestid:
        location.state.reference === "notification"
          ? clientData?.activechatrequestid
          : clientData?.activerequestchathistory[0]?.activechatrequestid,
      activechatrequest: {
        // "ai_officer_request_raised_by": getSessionKey("authToken").id,
        "ai_officer_status": "confirm", // pending,accepted,rejected
        "notification":{  // to be passed only when user called ai officier
          "companyid": clientData?.companyid,
          "activerequestid": clientData?.activerequestid,
          "activechatrequestid": location.state.reference === "notification" ? clientData?.activechatrequestid : clientData?.activerequestchathistory[0]?.activechatrequestid,
          "sender_id": getSessionKey("userData").id,
          "receiver_id": clientData?.activerequestchat?.sender_id === getSessionKey("userData").id ? clientData?.activerequestchat?.receiver_id : clientData?.activerequestchat?.sender_id,
          "type": "message",
          "title": "AI-X Request Accepted",
          "message": `${getSessionKey("userRole") === "legalrepresent" ? getSessionKey("userData")?.userdetail?.userlrdetail[0]?.companyname : getSessionKey("userData").userdetail?.firstname+' '+getSessionKey("userData").userdetail?.lastname} had confirm your invitation request to invite the AI Officer.`
        }
      }
    }

    axios
      .put(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequesthistory",
        payload,
        headers
      )
      .then((res) => {
        if (res.data.success) {
          let historyData = {
            reference: "AI officer Request Confirm",
          };
          navigate("/acknowledgement", { state: historyData });
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
    // navigate("/chat-details");
  };

  const handleRejectButtonClick = () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${
          getSessionKey("authToken") !== null
            ? getSessionKey("authToken").accesstoken
            : ""
        }`,
        "Content-Type": "application/json",
      },
    };

    const payload = {
      id: clientData?.id,
      companyid: clientData?.companyid,
      activerequestid: clientData?.activerequestid,
      activechatrequestid:
        location.state.reference === "notification"
          ? clientData?.activechatrequestid
          : clientData?.activerequestchathistory[0]?.activechatrequestid,
      activechatrequest: {
        // "ai_officer_request_raised_by": getSessionKey("authToken").id,
        "ai_officer_status": "rejected", // pending,accepted,rejected
        "notification":{  // to be passed only when user called ai officier
          "companyid": clientData?.companyid,
          "activerequestid": clientData?.activerequestid,
          "activechatrequestid": location.state.reference === "notification" ? clientData?.activechatrequestid : clientData?.activerequestchathistory[0]?.activechatrequestid,
          "sender_id": getSessionKey("userData").id,
          "receiver_id": clientData?.activerequestchat?.sender_id === getSessionKey("userData").id ? clientData?.activerequestchat?.receiver_id : clientData?.activerequestchat?.sender_id,
          "type": "message",
          "title": "AI-X Request Rejected",
          "message": `${getSessionKey("userRole") === "legalrepresent" ? getSessionKey("userData")?.userdetail?.userlrdetail[0]?.companyname : getSessionKey("userData").userdetail?.firstname+' '+getSessionKey("userData").userdetail?.lastname} had rejected your invitation request to invite the AI Officer.`
        }
      }
    }

    axios
      .put(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequesthistory",
        payload,
        headers
      )
      .then((res) => {
        if (res.data.success) {
          let historyData = {
            reference: "AI officer Request Rejected",
          };
          navigate("/acknowledgement", { state: historyData });
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  return (
    <>
      <header>
        <Header backLink={true} actionItems={["more"]}></Header>
      </header>
      <main>
        <div className="lyt-main typ-request">
          <h1 className={`${styles.title}`}>
            {t("chat_confirmRequestPgTitle")}
          </h1>
          <p className={`${styles.subTitle}`}>
            {t("chat_confirmRequestPgSubTitle")}
          </p>
          <div className={`${styles.section}`}>
            <h2 className={`${styles.secTitle}`}>
              {t("chat_detailsInviteProcess")}
            </h2>
            <div className={`${styles.cardWrap}`}>
              <div className={`${styles.card}`}>
                <div className={`${styles.infoCont}`}>
                  <i className={`${styles.error}`}></i>
                  <span className={`${styles.desc}`}>
                    {t("chat_confirmRequestProcessInfo")}
                  </span>
                </div>
                <div className={`${styles.stepsList}`}>
                  <div className={`${styles.steps} ${styles.active}`}>
                    <p>{t("chat_confirmRequestWaitingStep")}</p>
                  </div>
                  <div className={`${styles.steps} ${styles.active}`}>
                    <p>{t("chat_confirmRequestFirstPartyStep")}</p>
                  </div>
                  <div className={`${styles.steps}`}>
                    <p>{t("chat_confirmRequestPostApprovalStep")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.footWrap}`}>
            <Button
              btnStyle="secondary"
              onClick={() => {
                setOpenRejectDrawer(true);
              }}
            >
              {t("chat_rejectBtn")}
            </Button>
            <Button
              btnStyle="primary"
              type="button"
              onClick={handleAcceptButtonClick}
            >
              {t("chat_confirmBtn")}
            </Button>
          </div>
        </div>
      </main>
      <BottomSheet
        title={t("chat_messageDrawerTitle")}
        primaryBtnText={t("chat_rejectBtn")}
        size="large"
        center={true}
        open={openRejectDrawer}
        setOpen={setOpenRejectDrawer}
        btnOnClick={handleRejectButtonClick}
      >
        <div className={`${styles.rejectDrawer}`}>
          <div className={`${styles.rejectWrap}`}>
            <span className={`${styles.remove}`}></span>
            <p className={`${styles.content}`}>
              {t("chat_messageDrawerSubTitle")}
            </p>
          </div>
        </div>
      </BottomSheet>
    </>
  );
}

export default ConfirmRequest;
