import { useEffect, useState } from "react";
import Header from "src/components/common/header/Header";
import DeviceCard, {
  DeviceCardData,
} from "src/components/device-card/DeviceCard";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import { getSessionKey } from "src/helper/sessionServices";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import { useNavigate } from "react-router-dom";

function ActiveDevices() {
  const navigate = useNavigate();
  const { loading, setLoading } = useLoader();
  const { i18n, t } = useTranslation(["ActiveDevice"]);
  const [deviceList, setDeviceList] = useState<any[]>([]);
  const deviceId = getSessionKey("deviceId");
  const [triggeredGetDeviceList, setTriggeredGetDeviceList] =
    useState<boolean>(true);
  const lang = getSessionKey("selectedLangCode");

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    if (triggeredGetDeviceList) {
      setLoading(true);
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/devicedetail?userid=${getSessionKey("authToken").id}`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          setDeviceList(response?.data?.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
      setTriggeredGetDeviceList(false);
    }
  }, [triggeredGetDeviceList]);

  const removeSelectedDevice = (id: string | number) => {
    setLoading(true);
    axios
      .delete(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/devicedetail?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) setTriggeredGetDeviceList(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };
  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main">
          <section>
            <div className="bs-section">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  {t("activedevices_pgTitle")}
                </h1>
                <p className={`${styles.subtitle}`}>
                  {t("activedevices_pgSubTitle")}
                </p>
              </div>
              <div className="sec-cont">
                <div className={`${styles.section}`}>
                  <h2 className={`${styles.secTitle}`}>
                    {t("activedevices_yourProfile")}
                  </h2>
                  {deviceList.map((item, index) => {
                    return (
                      <div key={index} className={`${styles.item}`}>
                        <DeviceCard
                          {...item}
                          currentDeviceId={deviceId}
                          removeSelectedDevice={removeSelectedDevice}
                        ></DeviceCard>
                      </div>
                    );
                  })}
                </div>
                {/* <div className={`${styles.section}`}>
                  <h2 className={`${styles.secTitle}`}>
                    {t("activedevices_thisDevice")}
                  </h2>
                  {advisorDeviceCards.map((item, index) => {
                    return (
                      <div key={index} className={`${styles.item}`}>
                        <DeviceCard {...item}></DeviceCard>
                      </div>
                    );
                  })}
                </div> */}
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default ActiveDevices;
