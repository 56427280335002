// src/socket.ts
import { Socket } from "socket.io-client";
import * as io from "socket.io-client";
import { getSessionKey } from "./sessionServices";

const SERVER_URL = "https://asiaimpactapi.digitalsalt.in/"; // Replace with your server URL



// Function to initialize the socket connection
export const connectSocket = (token:string,email:string,id:number,navigate:any): Socket => {
    console.log('====================================');
    console.log(email,id,'check data from socket connect');
    console.log('====================================');
    let userdetail  = getSessionKey("authToken");
    let socket = io.connect("https://asiaimpactapi.digitalsalt.in/", {
      auth: {
        token: `Bearer ${token? token : userdetail?.accesstoken}`,
      },
      query: {
        email: email ? email : userdetail?.email,
        id: id ? id : userdetail?.id,
      },
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${token? token : userdetail?.accesstoken}`,
          },
        },
      },
    })
  //   : Socket | null = null;
  // if (!socket) {
  //   console.log(email ? email : getSessionKey("userData")?.email,'check emial inside the socket');
    
  //   socket =
     
    // Handle connection errors
    socket.on("connect_error", (error) => {
        // token expire logic
    // navigate("/enter-pin");
    socket?.disconnect();
      console.error("Socket connection error:", error.message);
    });

    // Handle reconnection attempts
    socket.on("reconnect_attempt", () => {
      console.log("Trying to reconnect...");
    });

    // Handle reconnection success
    socket.on("reconnect", (attemptNumber) => {
      console.log(`Reconnected successfully after ${attemptNumber} attempts`);
    });


    socket.on("connect", () => {
        console.info("Socket connection Successfull");
        socket.emit("get_notifications_count",{
          email: email? email:userdetail?.email,id: id?id:userdetail?.id
        })
      });
    // Handle reconnection failures
    // socket.on("reconnect_failed", () => {
    //   console.error("Failed to reconnect to the socket server.");
    // });
  // }
  return socket;
};

