import React, { useCallback, useEffect, useState } from "react";
import BottomSheet from "../bottom-sheet/BottomSheet";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useNavigate } from "react-router-dom";

export interface ProfileData {
  id?: string;
  firstName?: string;
  lastName?: string;
  profileImage?: string;
}

const ProfileCard: React.FC<ProfileData> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["ProfileSettings"]);
  const [openUploadDrawer, setOpenUploadDrawer] = useState(false);
  const [profileImageURL, setProfileImageURL] = useState("");
  const [tempProfileImage, setTempProfileImage] = useState("");
  const [openPreviewDrawer, setOpenPreviewDrawer] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [previewFileObj, setPreviewFileObj] = useState<Blob>({} as Blob);
  const [isDisabledUpload, setIsDisabledUpload] = useState(true);
  const [uploadedFile, setUploadedFile] = useState({
    id: "",
    name: "",
    size: "",
    type: "",
  });

  useEffect(() => {
    if (props?.profileImage) {
      setProfileImageURL(props?.profileImage);
    }
  }, [props.profileImage]);

  const handleUpload = useCallback((e: any) => {
    const file = e.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        setTempProfileImage(event.target?.result as string);
      };
      fileReader.readAsDataURL(file);

      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage(`${t("profilesettings_errorFileSize")}`);
        return;
      }

      if (!file.type.includes("image") && !file.type.includes("pdf")) {
        setErrorMessage(`${t("profilesettings_errorUploadFile")}`);
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", file.type.includes("pdf") ? "document" : "images");

      axios.post(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/documentUpload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setErrorMessage(null);
          setUploadedFile({
            id: "" + file.lastModified,
            name: file.name,
            size: "" + Math.round(file.size / 1024),
            type: file.type,
          });
          setPreviewFileObj(file);
          setSessionKey("profilePicData", res.data);
          setIsDisabledUpload(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
    }
  }, [t, navigate]);

  const handleConfirmUpload = useCallback(() => {
    setProfileImageURL(tempProfileImage);
    const profileImgData = getSessionKey("profilePicData");
    const userData = getSessionKey("userData");
    if (userData) {
      const payload = {
        id: userData.id,
        userdetail: {
          img: profileImgData.data.fileurl,
        },
      };
      axios.put(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user",
        payload,
        {
          headers: {
            Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          userData.userdetail.img = profileImgData.data.fileurl;
          setSessionKey("userData", userData);
          setOpenUploadDrawer(false);
          setOpenPreviewDrawer(false);
          setProfileImageURL(profileImgData.data.fileurl);
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
    }
  }, [tempProfileImage, navigate]);

  const handleCancelPreview = useCallback(() => {
    setOpenPreviewDrawer(false);
    setUploadedFile({ id: "", name: "", size: "", type: "" });
    setTempProfileImage("");
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setOpenUploadDrawer(false);
  }, []);

  return (
    <>
      <div className={`${styles.cardWrap}`}>
        <strong>
          {props.firstName} {props.lastName}
        </strong>
        <div className={`${styles.logoWrap}`}>
          <button
            type="button"
            className={
              props.profileImage !== "" || profileImageURL !== ""
                ? styles.uploaded
                : ""
            }
            onClick={() => setOpenUploadDrawer(true)}
          >
            {props.profileImage !== "" || profileImageURL !== "" ? (
              <img
                src={profileImageURL || props?.profileImage}
                alt={`${props.firstName} ${props.lastName}`}
              />
            ) : (
              <>
                <span>{props.firstName?.charAt(0)}{props.lastName?.charAt(0)}</span>
                <i></i>
              </>
            )}
          </button>
        </div>
      </div>
      <BottomSheet
        size="medium"
        open={openUploadDrawer}
        setOpen={setOpenUploadDrawer}
        title={t("profilesettings_addProfilePgTitle")}
        primaryBtnText={t("profilesettings_confirmBtn")}
        secondaryBtnText={t("profilesettings_cancelBtn")}
        btnOnClick={() => setOpenPreviewDrawer(true)}
        btnSecondaryOnClick={handleCloseDrawer}
        btnDisabled={isDisabledUpload}
      >
        <div className={`${styles.uploadCont}`}>
          <div className="bs-upload">
            <div className="doc-card">
              {uploadedFile.id === "" ? (
                <div className="upload-section">
                  <div className="upload-item upload-file">
                    <span className="text">
                      {t("profilesettings_uploadTitle")}
                    </span>
                    <input
                      type="file"
                      id="upload-doc"
                      onChange={handleUpload}
                    />
                  </div>
                </div>
              ) : (
                <div className="uploaded-state">
                  <div className="cm-document-wrap">
                    <i
                      className={`document-icon ${
                        uploadedFile.type.includes("image")
                          ? "image"
                          : "document"
                      }`}
                    ></i>
                    <div className="info-wrap">
                      <span className="doc-title">{uploadedFile.name}</span>
                      <span className="foot-note">{uploadedFile.size} KB</span>
                    </div>
                    <div className="action-wrap">
                      <button
                        type="button"
                        className="show-media"
                        onClick={() => setOpenPreviewDrawer(true)}
                      >
                        <i></i>
                      </button>
                      <button
                        type="button"
                        className="trash-btn"
                        onClick={handleCancelPreview}
                      >
                        <i></i>
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="upload-note">
                <strong className="note-title">
                  <i></i>
                  {t("profilesettings_noteTitle")}
                </strong>
                <ul className="note-list">
                  <li className="note-item">
                    {t("profilesettings_maxFileSize")}
                  </li>
                </ul>
              </div>
            </div>
            {errorMessage && <p className="error-text">{errorMessage}</p>}
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        open={openPreviewDrawer}
        primaryBtnText={t("profilesettings_confirmBtn")}
        btnDisabled={false}
        size="large"
        title={uploadedFile.name}
        setOpen={setOpenPreviewDrawer}
        btnOnClick={handleConfirmUpload}
      >
        <div className="upload-preview">
          {uploadedFile.type.includes("image") ? (
            <img src={tempProfileImage} alt="" />
          ) : (
            <iframe src={tempProfileImage}></iframe>
          )}
        </div>
      </BottomSheet>
    </>
  );
};

export default React.memo(ProfileCard);
