import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import PhoneNumber from "src/components/phone-number/PhoneNumber";
import CountryData from "../../../../data/countryData.json";
import * as yup from "yup";
import { useFormik } from "formik";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useNavigate } from "react-router-dom";
import { StepContext } from "../../DetailsForm";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface PropsDataType {
  currentStep: number;
}
export interface PointOfContactDataType {
  id: number | string | null;
  userid: number;
  userdetailid: number;
  firstname: string;
  lastname: string;
  countryCode: string;
  contact: string | number;
  country: string;
  detailtype: string;
  email: string;
}

function ContactDetails(props: PropsDataType) {
  const { i18n, t } = useTranslation(["CompanyDetails"]);
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  const validationSchema = yup.object({
    firstname: yup
      .string()
      .required(`${t("companydetails_validationFirstName")}`),
    lastname: yup
      .string()
      .required(`${t("companydetails_validationLastName")}`),
    country: yup.string().required(`${t("companydetails_validationCountry")}`),
    email: yup
      .string()
      .email(`${t("companydetails_validationEmail")}`)
      .matches(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        `${t("companydetails_validationEmail")}`
      )
      .required(`${t("companydetails_validationEmailRequired")}`),
    contact: yup
      .number()
      .required(`${t("companydetails_validationMobileNumber")}`),
    // .test("phone-num-length", "Invalid phone number", (value) => {
    //   return String(value).length === 10;
    // }),
  });
  const { step, setStep } = useContext(StepContext)!;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const optionCountry = CountryData.countryList;
  const navigate = useNavigate();
  const initialValues: PointOfContactDataType = {
    id: null,
    userid: getSessionKey("authToken") ? getSessionKey("authToken").id : "",
    userdetailid: getSessionKey("authToken")
      ? getSessionKey("authToken")?.userdetail !== undefined
        ? getSessionKey("authToken").userdetail.id
        : getSessionKey("authToken").userDetailId
      : "",
    firstname: "",
    lastname: "",
    countryCode: "+91",
    contact: "",
    country: "",
    detailtype: "pointofcontact",
    email: "",
  };
  const formik = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSessionKey("lrPOIDetails", JSON.stringify(values));
      navigate("/signup/confirm-details");
    },
  });

  const filterItemsByType = (data: any, type: string) => {
    return data[0].userdetail.userlrdetail.filter(
      (item: any) => item.detailtype === type
    );
  };

  useEffect(() => {
    setStep(props.currentStep!);
    if (!isEdit) {
      let tempUserDetails: any;
      let userDetails: any;
      tempUserDetails = getSessionKey("lrPOIDetails")
        ? getSessionKey("lrPOIDetails")
        : getSessionKey("userExistData");

      if (tempUserDetails !== null) {
        if (
          (getSessionKey("userRegStatus").toLowerCase() === "declined" ||
            getSessionKey("userRegStatus").toLowerCase() ===
              "document approval pending") &&
          getSessionKey("lrPOIDetails") === null
        ) {
          userDetails = filterItemsByType(tempUserDetails, "pointofcontact")[0];
        } else {
          userDetails = tempUserDetails;
        }
        setIsEdit(true);
        setUserDetails(userDetails);
        console.log("userDetails", userDetails);

        formik.setFieldValue("id", userDetails?.id);
        formik.setFieldValue("firstname", userDetails?.firstname);
        formik.setFieldValue("lastname", userDetails?.lastname);
        formik.setFieldValue("countryCode", userDetails?.countrycode);
        formik.setFieldValue("contact", userDetails?.contact);
        formik.setFieldValue("country", userDetails?.country);
        formik.setFieldValue("email", userDetails?.email);
      }
    }
  }, []);

  return (
    <div className="lyt-main typ-details-form">
      <div className={`${styles.section}`}>
        <div className={`${styles.sectionHead}`}>
          <h1 className={`${styles.pgTitle}`}>
            {t("companydetails_pointOfContactTitle")}
          </h1>
        </div>
        <div className="bs-form">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-body">
              <div className="form-section">
                <div className="form-group">
                  <FormControl variant="standard">
                    <TextField
                      id="firstname"
                      name="firstname"
                      label={t("companydetails_firstName")}
                      placeholder={t("companydetails_firstName")}
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.firstname &&
                        Boolean(formik.errors.firstname)
                      }
                      helperText={
                        formik.touched.firstname && formik.errors.firstname
                      }
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl variant="standard">
                    <TextField
                      id="lastname"
                      name="lastname"
                      label={t("companydetails_lastName")}
                      placeholder={t("companydetails_lastName")}
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname)
                      }
                      helperText={
                        formik.touched.lastname && formik.errors.lastname
                      }
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <TextField
                    id="email"
                    name="email"
                    label={t("companydetails_email")}
                    placeholder={t("companydetails_email")}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  ></TextField>
                </div>
                <div className="form-group">
                  <div className="bs-select">
                    <FormControl fullWidth>
                      <InputLabel className="input-label">
                        {t("companydetails_country")}
                      </InputLabel>
                      <Select
                        id="country"
                        name="country"
                        label={t("companydetails_country")}
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                      >
                        {optionCountry
                          ? optionCountry.map((country, index) => {
                              // Here goes your models option
                              return (
                                <MenuItem value={country.value} key={index}>
                                  <em>{country.label}</em>
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                      {formik.touched.country && formik.errors.country ? (
                        <FormHelperText
                          sx={{
                            color: "#bf3333",
                            marginLeft: "16px !important",
                          }}
                        >
                          {formik.touched.country && formik.errors.country}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </div>
                </div>
                <div className="form-group typ-phone">
                  <PhoneNumber
                    id="contact"
                    countryCodeId="countryCode"
                    value={formik.values.contact}
                    formikObj={formik}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.contact && Boolean(formik.errors.contact)
                    }
                    helperText={formik.touched.contact && formik.errors.contact}
                  ></PhoneNumber>
                </div>
              </div>
            </div>
            <div className="form-foot">
              <Button
                btnStyle="primary"
                type="submit"
                disabled={!formik.isValid}
              >
                {t("companydetails_proceedBtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
