import Header from "src/components/common/header/Header";
import Button from "../../components/common/button/Button";
import { Link, useLocation } from "react-router-dom";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSessionKey } from "src/helper/sessionServices";
import axios from "axios";
function ApprovalPage() {
  const { i18n, t } = useTranslation(["Acknowledgement"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const location = useLocation();
  const [clientData, setClientData] = useState<any[]>([]);

  const handleSuccess = () => {
    //navigate("/acknowledgement");
    const payload = {
      id: clientData[0].notificationId,
      status: "approved",
      userrelation: {
        requestStatus: "approved", //"approved", "rejected"
      },
      sender_id: clientData[0]?.receiver_id,
      receiver_id: clientData[0]?.sender_id,
    };
    axios
      .put(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/notification",
        payload,
        {
          headers: {
            Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
          },
        }
      )
      .then((res) => {
        navigate("/client-approve");
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
    //navigate("/client-approve");
  };
  const handleReject = () => {
    //navigate("/acknowledgement");
    const payload = {
      id: clientData[0].notificationId,
      status: "rejected",
      userrelation: {
        requestStatus: "rejected", //"approved", "rejected"
      },
      sender_id: clientData[0]?.receiver_id,
      receiver_id: clientData[0]?.sender_id,
    };
    axios
      .put(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/notification",
        payload,
        {
          headers: {
            Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
          },
        }
      )
      .then((res) => {
        navigate("/client-reject");
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
    //navigate("/client-reject");
  };

  interface Client {
    id: number;
    firstName: string;
    lastName: string;
    profileImage?: string;
    profileImageURL?: string;
    email?: string;
    mobile?: number;
    country?: string;
    tag?: "Investor";
    aboutDesc?: string;
  }

  const nameInitials = (firstName: string, lastName: string) => {
    return firstName.charAt(0) + lastName.charAt(0);
  };

  useEffect(() => {
    const userDataNotification = getSessionKey("notificationData");
    const filteredData = userDataNotification.filter((item: any) => {
      return item.id === location.state.notificationId;
    });
    const temoClientObj = [
      {
        notificationId: filteredData[0].id,
        id: filteredData[0].User.id,
        firstName: filteredData[0].User?.role === "legalrepresent" ? filteredData[0].User.lrDetails[0]?.companyname : filteredData[0].User.userdetail.firstname,
        lastName: filteredData[0].User?.role === "legalrepresent" ? "" : filteredData[0].User.userdetail.lastname,
        profileImage: filteredData[0].User.userdetail.img,
        profileImageURL: "",
        email: filteredData[0].User.email,
        mobile: filteredData[0].User.contact,
        country: filteredData[0].User.userdetail.country,
        tag: filteredData[0].User.role,
        aboutDesc: filteredData[0].message,
        receiver_id: filteredData[0].receiver_id,
        sender_id: filteredData[0].sender_id,
      },
    ];
    // nameInitials(
    //   filteredData[0].User.userdetail.firstname,
    //   filteredData[0].User.userdetail.lastName
    // );
    setClientData(temoClientObj);
  }, []);
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-btn-spacing">
          <section>
            <div className="bs-section typ-lg-top">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  {t("approvalPending_title")}
                </h1>
                <p className={`${styles.subTitle}`}>
                  {t("approvalPending_desc")}
                </p>
              </div>
              <div className="sec-cont">
                <div className={`${styles.subWrap}`}>
                  {clientData.map((client: any, index: number) => (
                    <>
                      <div className={`${styles.profileCont}`}>
                        <div className={`${styles.logoWrap}`}>
                          {client.profileImage !== "" ||
                          client.profileImageURL !== "" ? (
                            <img
                              src={
                                client.profileImage || client.profileImageURL
                              }
                              alt={`${client.firstName} ${client.lastName}`}
                            />
                          ) : (
                            <>
                              <span>
                                {nameInitials(
                                  client?.firstName,
                                  client?.lastName
                                )}
                              </span>
                            </>
                          )}
                        </div>
                        <div className={`${styles.titleWrap}`}>
                          <strong className={`${styles.clientName}`}>
                            {client.firstName} {client.lastName}
                          </strong>
                          <p className={`${styles.clientTag}`}>{client.tag}</p>
                        </div>
                      </div>
                      {client.aboutDesc && (
                        <div className={`${styles.aboutCont}`}>
                          <p className={`${styles.contTitle}`}>
                            {t("approvalPending_aboutTitle")}
                          </p>
                          <span className={`${styles.contDesc}`}>
                            {client.aboutDesc}
                          </span>
                        </div>
                      )}
                      <div className={`${styles.infoCont}`}>
                        <ul className={`${styles.subList}`}>
                          <li className={`${styles.subItem}`}>
                            <i className={`${styles.sms}`}></i>
                            <div className={`${styles.textWrap}`}>
                              {t("approvalPending_emailTitle")}
                              <span className={`${styles.text}`}>
                                {client.email}
                              </span>
                            </div>
                          </li>
                          {client.mobile &&
                            <li className={`${styles.subItem}`}>
                              <i className={`${styles.call}`}></i>
                              <div className={`${styles.textWrap}`}>
                                {t("approvalPending_mobileNumberTitle")}{" "}
                                <span className={`${styles.text}`}>
                                  {client.mobile}
                                </span>
                              </div>
                            </li>
                          }
                          {/* <li className={`${styles.subItem}`} key={index}>
                            <i className={`${styles.map}`}></i>
                            <div className={`${styles.textWrap}`}>
                              Country
                              <span className={`${styles.text}`}>
                                {client.country}
                              </span>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className={`${styles.footWrap}`}>
          <Button btnStyle="secondary" onClick={handleReject}>
            {t("approvalPending_rejectBtn")}
          </Button>
          <Button btnStyle="primary" onClick={handleSuccess}>
            {t("approvalPending_approveBtn")}
          </Button>
        </div>
      </main>
    </>
  );
}

export default ApprovalPage;
