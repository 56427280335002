import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { useEffect } from "react";

function ProfileSettings() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["ProfileSettings"]);
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  const userData = getSessionKey("userData");

  const handleRedirectionClients = () => {
    if (userData.status === "document approval pending" || userData.status === "document reject") {
      let locationData = {
        reference: "document approval pending",
      };
      navigate("/acknowledgement", { state: locationData });
    } else if (userData?.role === "advisor") {
      navigate("/advisor-client");
    } else {
      if (userData?.addedClient) {
        navigate("/advisor-list");
      } else {
        navigate("/advisor-intro");
      }
    }
  };
  
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-ps">
          <div className="bs-icon-link-list">
            {userData !== null && (
              <div className="account-wrap">
                <Link to="/account-details" className="mod-icon-link">
                  <div className="icon-wrap">
                    <i className="profile"></i>
                  </div>
                  <div className="data-wrap">
                    <strong>
                      {getSessionKey("userRole") === "legalrepresent" ? (
                        <>
                          {userData?.userdetail?.userlrdetail[0]?.companyname}
                        </>
                      ) : (
                        <>
                          {userData?.userdetail?.firstname}{" "}
                          {userData?.userdetail?.lastname}
                        </>
                      )}
                    </strong>
                    <span>{t("profilesettings_accountDetails")}</span>
                  </div>
                  <i className="next-icon"></i>
                </Link>
              </div>
            )}
            <section>
              {userData !== null && (
                <>
                  <div className="links-section">
                    <div
                      onClick={handleRedirectionClients}
                      className="mod-icon-link"
                    >
                      <div className="icon-wrap">
                        <i className="advisor"></i>
                      </div>
                      <div className="data-wrap">
                        <strong>
                          {userData?.role !== "advisor"
                            ? t("profilesettings_advisorTitle")
                            : t("profilesettings_clientTitle")}
                        </strong>
                        <span>{t("profilesettings_advisorSubTitle")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="links-section">
                    <Link to="/chat" className="mod-icon-link">
                      <div className="icon-wrap">
                        <i className="chat-setting"></i>
                      </div>
                      <div className="data-wrap">
                        <strong>{t("profilesettings_chatHistoryTitle")}</strong>
                        <span>{t("profilesettings_chatHistorySubTitle")}</span>
                      </div>
                    </Link>
                  </div>
                  <div className="links-section">
                    <Link to="/user-highlights" className="mod-icon-link">
                      <div className="icon-wrap">
                        <i className="highlight-setting"></i>
                      </div>
                      <div className="data-wrap">
                        <strong>{t("profilesettings_highlightsTitle")}</strong>
                        <span>{t("profilesettings_highlightsSubTitle")}</span>
                      </div>
                    </Link>
                  </div>
                  <div className="links-section">
                    <Link to="/language-selection" className="mod-icon-link">
                      <div className="icon-wrap">
                        <i className="language"></i>
                      </div>
                      <div className="data-wrap">
                        <strong>{t("profilesettings_languageTitle")}</strong>
                        <span>{t("profilesettings_languageSubTitle")}</span>
                      </div>
                    </Link>
                  </div>

                  <div className="links-section">
                    <Link to="/privacy-security" className="mod-icon-link">
                      <div className="icon-wrap">
                        <i className="shield"></i>
                      </div>
                      <div className="data-wrap">
                        <strong>{t("profilesettings_privacyTitle")}</strong>
                        <span>{t("profilesettings_privacySubTitle")}</span>
                      </div>
                    </Link>
                  </div>
                </>
              )}
              <div className="links-section">
                <Link to="/customer-support" className="mod-icon-link">
                  <div className="icon-wrap">
                    <i className="sms"></i>
                  </div>
                  <div className="data-wrap">
                    <strong>{t("profilesettings_supportTitle")}</strong>
                    <span>{t("profilesettings_supportSubTitle")}</span>
                  </div>
                </Link>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
}

export default ProfileSettings;
