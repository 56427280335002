import styles from "./style.module.scss";
import Button from "../common/button/Button";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";

function SelectClient(props: any) {
  const { i18n, t } = useTranslation(["SelectClient"]);
  const lang = getSessionKey("selectedLangCode");
  return (
    <div className={`${styles.logoContentWrap}`}>
      <div className={`${styles.image}`}>
        <img src="/assets/icons/user-square.svg" alt="select-client" />
      </div>
      <div className={`${styles.contentWrap}`}>
        <h2 className={`${styles.title}`}>
          {t("selectclient_selectClientTitle")}
        </h2>
        <p className={`${styles.description}`}>
          {t("selectclient_selectClientMessage")}
        </p>
      </div>
      <div className={`${styles.footWrap}`}>
        <Button btnStyle="primary" onClick={props.handleSelectClient}>
          {t("selectclient_selectClientTitle")}
        </Button>
      </div>
    </div>
  );
}

export default SelectClient;
