import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Header from "src/components/common/header/Header";
import { FormControl, TextField } from "@mui/material";
import Button from "src/components/common/button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import { getSessionKey } from "src/helper/sessionServices";
export const StepContext = React.createContext<{
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
} | null>(null);

function ProfileAddAdvisor({ currentStep }: { currentStep: number }) {
  const { i18n, t } = useTranslation(["Advisor"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(`${t("advisor_validationEmail")}`)
        .required(`${t("advisor_validationEmailRequired")}`),
  });
  const [step, setStep] = useState(currentStep);
  const [errorFormik, setErrorFormik] = useState("");
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //navigate("/acknowledgement");
      const payload = {
        email: values.email,
      };
      axios
        .post(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/verifyuseremail",
          payload,
          {
            headers: {
              Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
            },
          }
        )
        .then((res) => {
          // console.log('res', res);
          if (res.data.success && (Object.keys(res.data.data).length && res.data.data.isActive !== 0)) {
            //navigate("/signup/details-form/ubo-details");
            /* setIsConfirmClicked(true);
          setIsDisabled(true);
          props.onConfirm();
          props.onDataFromChild(values.email); */
            const payload = {
              email: values.email,
              relationshipType: 1,
            };
            axios
              .post(
                "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/relation",
                payload,
                {
                  headers: {
                    Authorization: `Bearer ${
                      getSessionKey("authToken").accesstoken
                    }`,
                  },
                }
              )
              .then((res) => {
                let historyData = {
                  reference: "relation added",
                };
                navigate(`/acknowledgement`, { state: historyData });
              })
              .catch((error) => {
                if (error.response.status === 403 || error.response.status === 401) {
                  navigate("/enter-pin");
                } else {
                  console.error("Error fetching data:", error.message);
                }
              });
          } else {
            /* setIsDisabled(false);
          setIsConfirmClicked(false); */
            formik.errors.email = res.data.message;
            setErrorFormik(res.data.message);
          }
        })
        .catch((error) => {
          formik.errors.email = error.message;
          setErrorFormik(error.message);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    },
  });
  const handleSkipandExplore = () => {
    navigate("/impact");
  };

  return (
    <StepContext.Provider value={{ step, setStep }}>
      <header>
        <Header
          backLink={true}
          showProgress={true}
          currentStep={step}
          totalSteps={3}
        ></Header>
      </header>
      <main>
        <div className="lyt-main">
          <div className={`${styles.section}`}>
            <div className={`${styles.sectionHead}`}>
              <div className={`${styles.titleWrap}`}>
                <h1 className={`${styles.pgTitle}`}>
                  {t("advisor_introTitle")}
                </h1>
                <button
                  type="button"
                  className="edit-btn"
                  onClick={handleSkipandExplore}
                >
                  {t("advisor_skipBtn")}
                </button>
              </div>
              <p className={`${styles.subTitle}`}>{t("advisor_pgSubTitle")}</p>
            </div>
            <div className={`${styles.sectionCont}`}>
              <div className="bs-form">
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <FormControl variant="standard">
                      <TextField
                        id="email"
                        name="email"
                        label="Email ID"
                        placeholder="Email ID"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      ></TextField>
                      {/* {(errorFormik !== "") && (
                      <span className="MuiFormHelperText-root Mui-error">
                        {`${errorFormik}`}
                      </span>
                    )} */}
                    </FormControl>
                  </div>
                  <div className="form-foot">
                    <Button
                      btnStyle="primary"
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      {t("advisor_confirmBtn")}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </StepContext.Provider>
  );
}

export default ProfileAddAdvisor;
