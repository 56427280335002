import Header from "src/components/common/header/Header";
import Button from "../../components/common/button/Button";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import styles from "./style.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";

function AdvisorDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, setLoading } = useLoader();
  const searchParams = new URLSearchParams(location.search);
  const relationId = searchParams.get("id");
  const { i18n, t } = useTranslation(["Advisor"]);
  const lang = getSessionKey("selectedLangCode");
  interface ButtonStyleState {
    viewChat: "primary" | "text";
    participateinChat: "primary" | "text";
    expressInterest: "primary" | "text";
    viewPortfolio: "primary" | "text";
  }
  const [buttonStyle, setButtonStyle] = useState<ButtonStyleState>({
    viewChat: "primary",
    participateinChat: "primary",
    expressInterest: "primary",
    viewPortfolio: "primary",
  });

  //   const handleClick = () => {};
  const handleClick = (btnKey: keyof ButtonStyleState) => {
    setLoading(true);
    const payload = {
      id: advisorSettingData?.id,
      advisorId: advisorSettingData?.advisorUser?.id,
      userid: advisorSettingData?.investorId,
      [btnKey]: buttonStyle[btnKey] === "primary" ? false : true,
    };
    axios
      .put(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/setting`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        if (response?.data?.success) {
          // setAdvisorSettingData(response?.data?.data[0]);
          setButtonStyle((prevState: ButtonStyleState) => ({
            ...prevState,
            [btnKey]: prevState[btnKey] === "primary" ? "text" : "primary",
          }));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const [advisorData, setAdvisorData] = useState<any>({});
  const [advisorSettingData, setAdvisorSettingData] = useState<any>({});
  useEffect(() => {
    setLoading(true);
    i18next.changeLanguage(lang);

    const relationData = getSessionKey("relationData");
    const filteredData = relationData.filter((item: any) => {
      return Number(item.id) === Number(relationId);
    });
    setAdvisorData(filteredData[0]);

    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/setting?advisorId=${filteredData[0]?.advisor?.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        if (response?.data?.success) {
          setAdvisorSettingData(response?.data?.data[0]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);

  useEffect(() => {
    if (Object.keys(advisorSettingData).length > 0) {
      setButtonStyle((prevState: ButtonStyleState) => ({
        ...prevState,
        viewChat: !advisorSettingData?.viewChat ? "text" : "primary",
        participateinChat: !advisorSettingData?.participateinChat
          ? "text"
          : "primary",
        expressInterest: !advisorSettingData?.expressInterest
          ? "text"
          : "primary",
        viewPortfolio: !advisorSettingData?.viewPortfolio ? "text" : "primary",
      }));
    }
  }, [advisorSettingData]);

  console.log("buttonStyle", advisorSettingData);
  return (
    <>
      {loading ? <Loader /> : null}
      <header>
        <Header
          backLink={true}
          actionItems={["delete"]}
          detailData={advisorData}
        ></Header>
      </header>
      <main>
        <div className="lyt-main typ-ps">
          <section>
            <div className="bs-section ">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  {t("advisor_detailsTitle")}
                </h1>
              </div>
              <div className="sec-cont">
                <div className="bs-profile-card typ-lg">
                  <div className="client-profile">
                    <div className="logo-wrap">
                      <img
                        src={advisorData?.advisor?.userdetail?.img}
                        alt="ferruccio"
                      />
                    </div>
                    <div className="title-wrap">
                      <strong className="title-text">
                        {advisorData?.advisor?.userdetail?.firstname}{" "}
                        {advisorData?.advisor?.userdetail?.lastname}
                      </strong>
                      <p className="mail-text">{advisorData?.advisor?.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="bs-section typ-access">
              <div className="sec-head">
                <h1 className={`cm-sec-title ${styles.secTitle}`}>
                  {t("advisor_detailsAccessTitle")}
                </h1>
              </div>
              <div className="sec-cont">
                <div className={`${styles.cardCont}`}>
                  <h2 className={`${styles.contTitle}`}>Chat</h2>
                  <div className={`${styles.cardWrap}`}>
                    <div className={`${styles.card}`}>
                      <ul className={`${styles.list}`}>
                        <li className={`${styles.item}`}>
                          <div className={`${styles.textWrap}`}>
                            <span className={`${styles.text}`}>
                              {t("advisor_detailsViewChatTitle")}
                            </span>
                            <span className={`${styles.details}`}>
                              {t("advisor_detailsViewChatSubTitle")}
                            </span>
                          </div>
                          <div className={`${styles.ctaWrap}`}>
                            <Button
                              btnStyle={
                                buttonStyle.viewChat === "primary"
                                  ? "text"
                                  : "primary"
                              }
                              onClick={() => handleClick("viewChat")}
                            >
                              {t("advisor_noBtn")}
                            </Button>
                            <Button
                              btnStyle={
                                buttonStyle.viewChat === "primary"
                                  ? "primary"
                                  : "text"
                              }
                              onClick={() => handleClick("viewChat")}
                            >
                              {t("advisor_yesBtn")}
                            </Button>
                          </div>
                        </li>
                        <li className={`${styles.item}`}>
                          <div className={`${styles.textWrap}`}>
                            <span className={`${styles.text}`}>
                              {t("advisor_detailsParticipateTitle")}
                            </span>
                            <span className={`${styles.details}`}>
                              {t("advisor_detailsParticipateSubTitle")}
                            </span>
                          </div>
                          <div className={`${styles.ctaWrap}`}>
                            <Button
                              btnStyle={
                                buttonStyle.participateinChat === "primary"
                                  ? "text"
                                  : "primary"
                              }
                              onClick={() => handleClick("participateinChat")}
                            >
                              {t("advisor_noBtn")}
                            </Button>
                            <Button
                              btnStyle={
                                buttonStyle.participateinChat === "primary"
                                  ? "primary"
                                  : "text"
                              }
                              onClick={() => handleClick("participateinChat")}
                            >
                              {t("advisor_yesBtn")}
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={`${styles.cardCont}`}>
                  <h2 className={`${styles.contTitle}`}>
                    {t("advisor_detailsInterest")}
                  </h2>
                  <div className={`${styles.cardWrap}`}>
                    <div className={`${styles.card}`}>
                      <ul className={`${styles.list}`}>
                        <li className={`${styles.item}`}>
                          <div className={`${styles.textWrap}`}>
                            <span className={`${styles.text}`}>
                              {t("advisor_detailsInterestTitle")}
                            </span>
                            <span className={`${styles.details}`}>
                              {t("advisor_detailsInterestSubTitle")}
                            </span>
                          </div>
                          <div className={`${styles.ctaWrap}`}>
                            <Button
                              btnStyle={
                                buttonStyle.expressInterest === "primary"
                                  ? "text"
                                  : "primary"
                              }
                              onClick={() => handleClick("expressInterest")}
                            >
                              {t("advisor_noBtn")}
                            </Button>
                            <Button
                              btnStyle={
                                buttonStyle.expressInterest === "primary"
                                  ? "primary"
                                  : "text"
                              }
                              onClick={() => handleClick("expressInterest")}
                            >
                              {t("advisor_yesBtn")}
                            </Button>
                          </div>
                        </li>
                        <li className={`${styles.item}`}>
                          <div className={`${styles.textWrap}`}>
                            <span className={`${styles.text}`}>
                              {t("advisor_detailsPortfolioTitle")}
                            </span>
                            <span className={`${styles.details}`}>
                              {t("advisor_detailsPortfolioSubTitle")}
                            </span>
                          </div>
                          <div className={`${styles.ctaWrap}`}>
                            <Button
                              btnStyle={
                                buttonStyle.viewPortfolio === "primary"
                                  ? "text"
                                  : "primary"
                              }
                              onClick={() => handleClick("viewPortfolio")}
                            >
                              {t("advisor_noBtn")}
                            </Button>
                            <Button
                              btnStyle={
                                buttonStyle.viewPortfolio === "primary"
                                  ? "primary"
                                  : "text"
                              }
                              onClick={() => handleClick("viewPortfolio")}
                            >
                              {t("advisor_yesBtn")}
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default AdvisorDetails;
