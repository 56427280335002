import { Player } from "@lottiefiles/react-lottie-player";
import tickAnimation from "../../animation/tick-animation.json";
import styles from "./style.module.scss";
import Button from "../common/button/Button";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";

export interface AcknowledgementMsgProp {
  title: string;
  content: string;
  type: "success" | "error" | "account-error" | "kyc-error";
  showBtn?: boolean;
  handleSelectClient?: any;
}

function SuccessError(props: AcknowledgementMsgProp) {
  const { i18n, t } = useTranslation(["Chat"]);
  const lang = getSessionKey("selectedLangCode");
  return (
    <div className="splash-screen">
      <div className={`${styles.logoContentWrap}`}>
        {props.type === "success" ? (
          <Player
            style={{ height: "12rem", width: "12rem" }}
            src={tickAnimation}
            autoplay
          />
        ) : props.type === "error" ? (
          <i className={`${styles[props.type]}`}></i>
        ) : props.type === "account-error" ? (
          <i className={`${styles.accountError}`}></i>
        ) : props.type === "kyc-error" ? (
          <i className={`${styles.kycPending}`}></i>
        ) : null}
        <div className={`${styles.contentWrap}`}>
          <h2 className={`${styles.title}`}>{props.title}</h2>
          <p className={`${styles.content}`}>{props.content}</p>
        </div>
        {props.showBtn && (
          <div className={`${styles.footWrap}`}>
            <Button btnStyle="primary" onClick={props.handleSelectClient}>
              {t("portfoliodetails_drawerSelectTitle")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SuccessError;
