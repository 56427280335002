import { Link } from "react-router-dom";
import styles from "./style.module.scss";

export interface ReportCardData {
  id: string;
  filename: string;
  tenure: string;
  reporttype: string;
  tag?: string;
  addhome: boolean;
  Company: {
    country: string;
    name: string;
    sectiondata: [
      {
        sectionname: string;
        value: string;
        key: string;
      }
    ];
    sustainarr: [
      {
        SustainGoal: {
          value: string;
          classname: string;
        };
      }
    ];
  };
  sectiondata: [
    {
      sectionname: string;
      value: string;
      key: string;
    }
  ];
  headline: string;
  fileurl: string;
  reportdate?: Date;
  setTriggerGetReports: any;
  reportcode: string;
}

function newsTagGeneration(tag: string) {
  let resultTag = "";
  switch (tag) {
    case "Leading":
      resultTag = "leading";
      break;
    case "Highlight":
      resultTag = "highlight";
      break;
    case "Latest":
      resultTag = "latest";
      break;
    case "In Focus":
      resultTag = "focus";
      break;
    case "Trending":
      resultTag = "trending";
      break;
    default:
      resultTag = "";
      break;
  }
  return resultTag;
}

function ReportsCard(props: ReportCardData & { bigCard?: boolean }) {
  return (
    <div
      className={`${styles.cardWrap} ${
        props.bigCard ? `${styles.typBig}` : ""
      }`}
    >
      <Link to={`/report-detail?reportcode=${props.reportcode}`}>
        <div className={`${styles.headWrap}`}>
          <h3 className={`${styles.cardTitle}`}>{props.Company.name}</h3>
          {props.tag ? (
            <span
              className={`${styles.tag} cm-tag typ-${newsTagGeneration(
                props["tag"]
              )}`}
            >
              {props.tag}
            </span>
          ) : (
            ""
          )}
        </div>
        <div className={`${styles.footWrap}`}>
          <span className={`${styles.date}`}>{props.tenure}</span>
          <span className={`${styles.reportType}`}>
            {props.reporttype}{" "}
            <i className={`${styles.cmIcon} ${styles.arrowRight}`}></i>
          </span>
        </div>
      </Link>
    </div>
  );
}

export default ReportsCard;
