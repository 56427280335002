import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { useEffect } from "react";

function CustomerSupport() {
  const { i18n, t } = useTranslation(["CustomerSupport"]);
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-ps">
          <div className="bs-form">
            <div className="search-wrap">
              <span className="search-icon"></span>
              <input
                type="text"
                placeholder={t("customersupport_searchPlaceholder")}
              />
              <button className="close-btn">
                <i></i>
              </button>
            </div>
          </div>
          <div className="bs-icon-link-list">
            <section>
              <div className="links-section">
                <h2 className="section-title">
                  {t("customersupport_selectTopicTitle")}
                </h2>
                <Link to="" className="mod-icon-link">
                  <div className="icon-wrap">
                    <i className="message-question"></i>
                  </div>
                  <div className="data-wrap">
                    <strong>{t("customersupport_getStartedTitle")}</strong>
                    <span>{t("customersupport_getStartedSubTitle")}</span>
                  </div>
                </Link>
              </div>
              <div className="links-section">
                <Link to="" className="mod-icon-link">
                  <div className="icon-wrap">
                    <i className="user-square"></i>
                  </div>
                  <div className="data-wrap">
                    <strong>{t("customersupport_myAccountTitle")}</strong>
                    <span>{t("customersupport_myAccountSubTitle")}</span>
                  </div>
                </Link>
              </div>
              <div className="links-section">
                <Link to="" className="mod-icon-link">
                  <div className="icon-wrap">
                    <i className="user"></i>
                  </div>
                  <div className="data-wrap">
                    <strong>{t("customersupport_advisorsTitle")}</strong>
                    <span>{t("customersupport_advisorsTitle")}</span>
                  </div>
                </Link>
              </div>
              <div className="links-section">
                <Link to="" className="mod-icon-link">
                  <div className="icon-wrap">
                    <i className="chat"></i>
                  </div>
                  <div className="data-wrap">
                    <strong>Chat</strong>
                    <span>{t("customersupport_messagingTitle")}</span>
                  </div>
                </Link>
              </div>
            </section>
            <section>
              <div className="links-section">
                <h2 className="section-title">
                  {t("customersupport_needHelpTitle")}
                </h2>
                <Link to="" className="mod-icon-link">
                  <div className="icon-wrap">
                    <i className="support"></i>
                  </div>
                  <div className={`data-wrap ${styles.dataWrap}`}>
                    <strong>{t("customersupport_contactUsTitle")}</strong>
                    <i className="next-icon"></i>
                  </div>
                </Link>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
}

export default CustomerSupport;
