import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import { formatDate } from "../../helper/formatDate";

export interface NewsCardData {
  id: string;
  title: string;
  Newdate: string;
  chip: string;
  imgUrl: string;
  addhome: boolean;
  description: string;
  link: string;
  newcode: string;
}

function newsTagGeneration(tag: string) {
  let resultTag = "";
  switch (tag) {
    case "Leading":
      resultTag = "leading";
      break;
    case "Highlight":
      resultTag = "highlight";
      break;
    case "Latest":
      resultTag = "latest";
      break;
    case "In Focus":
      resultTag = "focus";
      break;
    case "Trending":
      resultTag = "trending";
      break;
    default:
      resultTag = "";
      break;
  }
  return resultTag;
}

function NewsCard(props: NewsCardData & { smallCard?: boolean }) {
  return (
    <div
      className={`${styles.cardWrap} ${
        props.smallCard ? `${styles.typSmall}` : ""
      }`}
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.80) 18.75%, rgba(0, 0, 0, 0.00) 54.67%, rgba(0, 0, 0, 0.10) 75.09%, rgba(0, 0, 0, 0.80) 98.44%), url(${props.imgUrl}), lightgray no-repeat`,
      }}
    >
      <Link to={`/news-detail?newcode=${props.newcode}`}>
        <span
          className={`${styles.tag} cm-tag typ-${newsTagGeneration(
            props["chip"]
          )}`}
        >
          {props["chip"]}
        </span>
        <h3 className={`${styles.cardTitle}`}>{props["title"]}</h3>
        <span className={`${styles.date}`}>{formatDate(props["Newdate"])}</span>
      </Link>
    </div>
  );
}

export default NewsCard;
