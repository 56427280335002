// src/store/reducers/index.ts

import { combineReducers } from 'redux';
import loginReducer from './auth/login';
import languageReducer from './common/language';

const reducers = combineReducers({
  // Your reducers will be added here
  login: loginReducer,
  getLanguage: languageReducer
});

export default reducers;