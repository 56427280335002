import React, { useEffect, useState } from "react";
import AddClientCard, {
  AddClientCardData,
} from "src/components/add-client-card/AddClientCard";
import Header from "src/components/common/header/Header";
import Button from "../../components/common/button/Button";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function AddClient() {
  const { i18n, t } = useTranslation(["Advisor"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();

  const [cardCount, setCardCount] = useState(1);
  const [isProceedDisabled, setIsProceedDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [addClientButtonClicked, setAddClientButtonClicked] = useState(false);
  const [dataFromChild, setDataFromChild] = useState<Item[]>([]);
  type Item = {
    email: string;
  };
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  const handleAddCard = () => {
    setCardCount((prevCount) => prevCount + 1);
    setIsDisabled(true);
    setAddClientButtonClicked(true);
    setIsProceedDisabled(false);
  };
  const handleConfirm = () => {
    setIsDisabled(false);
    setAddClientButtonClicked(false);
    setIsProceedDisabled(true);
  };
  const handleDataFromChild = (data: any) => {
    setDataFromChild((prevItems) => [...prevItems, data]);
    console.log(dataFromChild);
  };
  const handleClick = () => {
    navigate("/confirm-client");
    setSessionKey("addClientObj", JSON.stringify(dataFromChild));
  };
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-btn-spacing">
          <section>
            <div className="bs-section typ-lg-top">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  {t("advisor_addClientTitle")}
                </h1>
                <p className={`${styles.subtitle}`}>
                  {t("advisor_clientPgSubTitle")}
                </p>
              </div>
              <div className="sec-cont">
                <div className={`${styles.contWrap}`}>
                  <ul className={`${styles.cardList}`}>
                    {[...Array(cardCount)].map((_, index) => (
                      <li className={`${styles.cardItem}`} key={index}>
                        <AddClientCard
                          cardCount={index + 1}
                          onConfirm={handleConfirm}
                          onDataFromChild={handleDataFromChild}
                        />
                      </li>
                    ))}
                    <button
                      type="button"
                      disabled={isDisabled || addClientButtonClicked}
                      className={`${styles.addBtn}`}
                      onClick={handleAddCard}
                    >
                      <i className={`${styles.addIcon}`}></i>
                      {t("advisor_addClientBtn")}
                    </button>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <div className={`${styles.footWrap}`}>
            <Button
              btnStyle="primary"
              disabled={!isProceedDisabled}
              onClick={handleClick}
            >
              {t("advisor_proceedBtn")}
            </Button>
          </div>
        </div>
      </main>
    </>
  );
}

export default AddClient;
