import Header from "src/components/common/header/Header";
import { Link, useNavigate } from "react-router-dom";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useLoader } from "src/context/LoaderContext";
import { getSessionKey, logoutSession, removeSessionKey } from "src/helper/sessionServices";

function PrivacySecurity() {
  const { setUserData } = useLoader();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Privacy"]);
  const lang = getSessionKey("selectedLangCode");
  const [openLogoutDrawer, setOpenLogoutDrawer] = useState(false);
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  const userData = getSessionKey("userData");

  const logoutUser = () => {
    // const keysToKeep = ["selectedLangCode", "langMaster", "selectedLang", "deviceId"];
    // const keys = Object.keys(localStorage);
    // // Iterate over the keys and remove the ones not in keysToKeep
    // keys.forEach((key) => {
    //   if (!keysToKeep.includes(key)) {
    //     removeSessionKey(key);
    //   }
    // });
    // navigate("/login");
    setUserData(null);
    logoutSession(navigate);
  };
  return (
    <>
      <header>
        <Header backLink={true} pageTitle={t("privacy_pgTitle")}></Header>
      </header>
      <main>
        <div className="lyt-main typ-ps">
          <div className="bs-icon-link-list">
            <section>
              <div className="links-section">
                <h2 className="section-title">{t("privacy_title")}</h2>
                <Link
                  to={`${
                    userData.pin !== null && userData.pin !== ""
                      ? "/check-existing-pin"
                      : "/create-pin"
                  }`}
                  className="mod-icon-link"
                >
                  <div className="icon-wrap">
                    <i className="pin"></i>
                  </div>
                  <div className="data-wrap">
                    <strong>{t("privacy_changePin")}</strong>
                  </div>
                </Link>
              </div>
            </section>
            <section>
              <div className="links-section">
                <h2 className="section-title">{t("privacy_securityTitle")}</h2>
                <Link to="/active-devices" className="mod-icon-link">
                  <div className="icon-wrap">
                    <i className="active-devices"></i>
                  </div>
                  <div className="data-wrap">
                    <strong>{t("privacy_activeDevices")}</strong>
                  </div>
                </Link>
              </div>
              <div className="links-section">
                <Link to="/delete-page" className="mod-icon-link">
                  <div className="icon-wrap">
                    <i className="delete-account"></i>
                  </div>
                  <div className="data-wrap">
                    <strong>{t("privacy_deleteAccount")}</strong>
                  </div>
                </Link>
              </div>
              <div className="links-section">
                <button
                  onClick={() => {
                    setOpenLogoutDrawer(true);
                  }}
                  className={`mod-icon-link ${styles.modIconLink}`}
                >
                  <div className="icon-wrap">
                    <i className="logout"></i>
                  </div>
                  <div className="data-wrap">
                    <strong>{t("privacy_logout")}</strong>
                  </div>
                </button>
              </div>
            </section>
          </div>
        </div>
      </main>
      <BottomSheet
        title={t("privacy_drawerLogoutTitle")}
        primaryBtnText={t("privacy_logout")}
        size="large"
        open={openLogoutDrawer}
        setOpen={setOpenLogoutDrawer}
        btnOnClick={logoutUser}
      >
        <div className={`${styles.logoContentWrap}`}>
          <i className={`${styles.logout}`}></i>
          <div className={`${styles.contentWrap}`}>
            <span className={`${styles.desc}`}>
              {t("privacy_drawerLogoutMessage")}
            </span>
          </div>
        </div>
      </BottomSheet>
    </>
  );
}

export default PrivacySecurity;
