import { useState, useEffect } from "react";
import styles from "./style.module.scss";
import InsightCard, {
  InsightCardData,
} from "src/components/insight-card/InsightCard";
import SwiperComponent from "src/components/common/swiper/SwiperComponent";
import Header from "src/components/common/header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "src/helper/formatDate";
import { authToken, headers } from "src/helper/authToken";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function InsightDetail() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Home"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  /* const [insightData, setInsightData] = useState<InsightCardData[]>([
    {
      insightCardId: "001",
      headline:
      "Hatsun Agro Standalone September 2023 Net Sales at 1cr.",
      insightDate: "Nov 04th’ 23",
      tag: "Newsletter",
    },
    {
      insightCardId: "002",
      headline:
      "Buy CreditAccess Gramin; target of ₹1600: ICICI Securities",
      insightDate: "Oct 28th’ 23",
      tag: "Blog",
    },
    {
      insightCardId: "003",
      headline:
      "Green Growth in the Tropics: Sustainable Industries Thriving in Southeast Asia",
      insightDate: "Aug 28th’ 23",
      tag: "Newsletter",
    },
  ]); */
  const [insightData, setInsightData] = useState<InsightCardData[]>([]);
  const [insightDetail, setInsightDetail] = useState<InsightCardData>();
  //const history = useHistory()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const insightId = searchParams.get("insightcode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    // Make a GET request with the specified headers
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/insight?lang_id=${
          getSessionKey("userData")?.lang_id
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setInsightData(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    // Make a GET request with the specified headers

    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/insight?insightcode=${insightId}&lang_id=${
          getSessionKey("userData")?.lang_id
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setInsightDetail(response?.data?.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, [insightId]);

  /* const [insightDetail, setNewsDetail] = useState({
    id: "001",
    insightTitle: "Hatsun Agro Standalone September 2023 Net Sales at 1cr.",
    insightDate: "7th December, 2023",
    insightBody: `<p>In a strategic move to expand its footprint in the microfinance sector, CAGL (Consolidated Agro Growth Limited) has successfully acquired Madura Micro Finance for a staggering ₹876 crore. The acquisition, finalized after months of negotiations and due diligence, marks a significant milestone for CAGL, positioning the company as a key player in the financial inclusion landscape.</p>
    <p>Madura Micro Finance, a well-established player in the microfinance industry, boasts a widespread network and a strong client base in rural and semi-urban areas. The acquisition aligns with CAGL's vision to contribute to agricultural and rural development by providing financial services to underserved communities.</p>
    <p>This strategic move not only allows CAGL to diversify its portfolio but also strengthens its commitment to empowering rural economies. The infusion of ₹876 crore into Madura Micro Finance is expected to enhance the latter's operational capabilities, technological infrastructure, and outreach.</p>
    <p>Industry experts anticipate that this acquisition will pave the way for innovative financial products and services tailored to the unique needs of rural customers. As CAGL continues to invest in sustainable development, the acquisition of Madura Micro Finance reflects the company's dedication to fostering financial inclusion and driving economic growth at the grassroots level.</p>`,
    tag: "Newsletter",
  }); */
  console.log("insightDetail", insightDetail);
  const getDownloadObj = {
    filename: insightDetail?.file_name,
    fileurl: insightDetail?.file_url,
    // tenure: insightDetail?.tag,
    // CompanyName: insightDetail?.headline,
    PrimaryText: "Download Insight",
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <header>
            {/* <Header backLink={true} actionItems={["download", "share"]}></Header> */}
            <Header
              backLink={true}
              actionItems={["download"]}
              detailData={getDownloadObj!}
            ></Header>
          </header>
          <main>
            <div className="lyt-main">
              <section>
                <div className="bs-section">
                  <div className="sec-cont">
                    <div className={`${styles.insightDetailWrap}`}>
                      <span className={`${styles.cardType}`}>
                        {insightDetail?.tag}
                      </span>
                      <div className={`${styles.contentWrap}`}>
                        <div className={`${styles.contHead}`}>
                          <span className={`${styles.date}`}>
                            {formatDate(insightDetail?.insightDate!)}
                          </span>
                          <h1 className={`${styles.insightTitle}`}>
                            {insightDetail?.headline}
                          </h1>
                        </div>
                        <div
                          className="cont-body"
                          dangerouslySetInnerHTML={{
                            __html: insightDetail?.description || "",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {insightData && insightData.length > 1 ? (
                <section>
                  <div className="bs-section typ-insight">
                    <div className="sec-head">
                      <h2 className="cm-sec-title typ-sm">
                        {t("home_moreInsights")}
                      </h2>
                    </div>
                    <div className="sec-cont">
                      <div className="bs-swiper typ-insight">
                        <SwiperComponent
                          swiperConfig={{
                            slidesPerView: "auto",
                            spaceBetween: 12,
                            observer: true,
                            cssMode: true,
                            breakpoints: {
                              768: {
                                spaceBetween: 20,
                                cssMode: false,
                              },
                              1280: {
                                cssMode: false,
                                spaceBetween: 24,
                              },
                            },
                          }}
                        >
                          {insightData
                            ?.filter((item) => item.id != insightId)
                            .map((item, index) => {
                              return (
                                <div key={index} className="slide-item">
                                  <InsightCard {...item}></InsightCard>
                                </div>
                              );
                            })}
                          <div className="slide-item card-view-all">
                            <Link to="/insight-list">
                              <div className="title-wrap">
                                <span>{t("home_viewAllBtn")}</span>
                                <span>{t("home_insights")}</span>
                              </div>
                              <i></i>
                            </Link>
                          </div>
                        </SwiperComponent>
                      </div>
                    </div>
                  </div>
                </section>
              ) : null}
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default InsightDetail;
