import { useState, useEffect } from "react";
import styles from "./style.module.scss";
import NewsCard, { NewsCardData } from "src/components/news-card/NewsCard";
import Button from "src/components/common/button/Button";
import Header from "src/components/common/header/Header";
import SwiperComponent from "src/components/common/swiper/SwiperComponent";
import axios from "axios";
import { authToken, headers } from "src/helper/authToken";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "src/helper/formatDate";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function chipGeneration(tag: string) {
  let resultTag = "";
  switch (tag) {
    case "Leading":
      resultTag = "leading";
      break;
    case "Highlight":
      resultTag = "highlight";
      break;
    case "Latest":
      resultTag = "latest";
      break;
    case "In Focus":
      resultTag = "focus";
      break;
    case "Trending":
      resultTag = "trending";
      break;
    default:
      resultTag = "";
      break;
  }
  return resultTag;
}

function NewsDetail() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Home"]);
  const { loading, setLoading } = useLoader();
  const [newsCards, setNewsCards] = useState<NewsCardData[]>([]);
  const [newsDetail, setNewsDetail] = useState<NewsCardData>();
  //const history = useHistory()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const newsId = searchParams.get("newcode");
  useEffect(() => {
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/news?lang_id=${
          getSessionKey("userData")?.lang_id
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setNewsCards(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/news?newcode=${newsId}&lang_id=${
          getSessionKey("userData")?.lang_id
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setNewsDetail(response?.data?.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, [newsId]);

  const getDownloadObj = {
    link: newsDetail?.link,
  };
  /* const [newsCards, setNewsCards] = useState<NewsCardData[]>([
    {
      newsID: "001",
      title: "CAGL to acquire Madura Micro Finance for ₹876 cr",
      Newdate: "04th November, 2023",
      chip: "Leading",
      imgUrl:
        "https://fastly.picsum.photos/id/20/3670/2462.jpg?hmac=CmQ0ln-k5ZqkdtLvVO23LjVAEabZQx2wOaT4pyeG10I",
        addhome:true
    },
    {
      newsID: "002",
      title: "Creditas raises pre-series A funding from Crowd",
      Newdate: "19th October, 2023",
      chip: "Trending",
      imgUrl:
        "https://fastly.picsum.photos/id/24/4855/1803.jpg?hmac=ICVhP1pUXDLXaTkgwDJinSUS59UWalMxf4SOIWb9Ui4",
        addhome:true
    },
    {
      newsID: "003",
      title:
        "Startup by 2 Friends Has Turned Plastic Waste Into 300 Million FMCG Bottles",
      Newdate: "11th October, 2023",
      chip: "Highlight",
      imgUrl:
        "https://fastly.picsum.photos/id/26/4209/2769.jpg?hmac=vcInmowFvPCyKGtV7Vfh7zWcA_Z0kStrPDW3ppP0iGI",
        addhome:true
    },
    {
      newsID: "004",
      title:
        "Startup by 2 Friends Has Turned Plastic Waste Into 300 Million FMCG Bottles",
      Newdate: "11th October, 2023",
      chip: "In Focus",
      imgUrl:
        "https://fastly.picsum.photos/id/48/5000/3333.jpg?hmac=y3_1VDNbhii0vM_FN6wxMlvK27vFefflbUSH06z98so",
        addhome:true
    },
  ]); */
  /* const [newsDetail, setNewsDetail] = useState({
    id: "001",
    title:
      "CreditAccess Grameen to acquire Madura Micro Finance for ₹876 cr",
    Newdate: "7th December, 2023",
    newsBody: `<p>In a strategic move to expand its footprint in the microfinance sector, CAGL (Consolidated Agro Growth Limited) has successfully acquired Madura Micro Finance for a staggering ₹876 crore. The acquisition, finalized after months of negotiations and due diligence, marks a significant milestone for CAGL, positioning the company as a key player in the financial inclusion landscape.</p>
        <p> Madura Micro Finance, a well-established player in the microfinance industry, boasts a widespread network and a strong client base in rural and semi-urban areas. The acquisition aligns with CAGL's vision to contribute to agricultural and rural development by providing financial services to underserved communities.</p>
        <p>This strategic move not only allows CAGL to diversify its portfolio but also strengthens its commitment to empowering rural economies. The infusion of ₹876 crore into Madura Micro Finance is expected to enhance the latter's operational capabilities, technological infrastructure, and outreach.</p>
        <p>Industry experts anticipate that this acquisition will pave the way for innovative financial products and services tailored to the unique needs of rural customers. As CAGL continues to invest in sustainable development, the acquisition of Madura Micro Finance reflects the company's dedication to fostering financial inclusion and driving economic growth at the grassroots level.</p>`,
    imgUrl:
      "https://fastly.picsum.photos/id/20/3670/2462.jpg?hmac=CmQ0ln-k5ZqkdtLvVO23LjVAEabZQx2wOaT4pyeG10I",
    chip: "Leading",
    addhome:true
  }); */
  return (
    <>
      {loading ? <Loader /> : null}
      <header>
        <Header
          backLink={true}
          actionItems={["share"]}
          detailData={getDownloadObj}
        ></Header>
      </header>
      <main>
        <div className="lyt-main typ-newsdetail">
          <section>
            <div className="bs-section">
              <div className="sec-cont">
                <div className={`${styles.newsDetailWrap}`}>
                  <div className={`${styles.imgWrap}`}>
                    <img src={`${newsDetail?.imgUrl}`} alt="" />
                    <span
                      className={`${styles.tag} cm-tag typ-${chipGeneration(
                        newsDetail?.chip!
                      )}`}
                    >
                      {newsDetail?.chip}
                    </span>
                  </div>
                  <div className={`${styles.contentWrap}`}>
                    <div className={`${styles.contHead}`}>
                      <h1 className={`${styles.title}`}>{newsDetail?.title}</h1>
                      <span className={`${styles.date}`}>
                        {formatDate(newsDetail?.Newdate!)}
                      </span>
                    </div>
                    <div
                      className="cont-body"
                      dangerouslySetInnerHTML={{
                        __html: newsDetail?.description || "",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {newsCards && newsCards.length > 1 ? (
            <section>
              <div className="bs-section typ-news">
                <div className="sec-head">
                  <h2 className="cm-sec-title typ-sm">{t("home_moreNews")}</h2>
                </div>
                <div className="sec-cont">
                  <div className="bs-swiper typ-news">
                    <SwiperComponent
                      swiperConfig={{
                        slidesPerView: "auto",
                        spaceBetween: 12,
                        observer: true,
                      }}
                    >
                      {newsCards
                        ?.filter((item) => item.id != newsId)
                        .map((item, index) => {
                          return (
                            <NewsCard
                              key={index}
                              {...item}
                              smallCard={true}
                            ></NewsCard>
                          );
                        })}
                    </SwiperComponent>
                    <div className={`${styles.actWrap} act-wrap`}>
                      <Button
                        btnStyle="text"
                        navigation={true}
                        navigationUrl="/news-list"
                      >
                        <span>{t("home_viewAllBtn")}</span>
                        <i className="icon-next"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
        </div>
      </main>
    </>
  );
}

export default NewsDetail;
