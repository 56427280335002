import { useEffect, useState } from "react";
import SuccessError, {
  AcknowledgementMsgProp,
} from "src/components/success-error/SuccessError";
import Button from "../../components/common/button/Button";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { useLocation, useNavigate } from "react-router-dom";
import { removeSessionKey } from "src/helper/sessionServices";

function Acknowledgement() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Acknowledgement"]);
  const lang = getSessionKey("selectedLangCode");
  const location = useLocation();
  const historyData: any = location.state;
  const [acknowledgementMsg, setAcknowledgementMsg] =
    useState<AcknowledgementMsgProp>({
      title:
        getSessionKey("authUser") !== null
          ? `${t("acknowledgement_userCreatedTitle")}`
          : historyData?.reference === "highlight interested"
          ? `${t("acknowledgement_interestNotedTitle")}`
          : historyData?.reference === "relation added" ||
            historyData?.reference === "delete user" ||
            historyData?.reference === "express interest"
          ? `${t("acknowledgement_requestSentTitle")}`
          : historyData?.reference === "pin changed"
          ? `${t("acknowledgement_pinChangedTitle")}`
          : historyData?.reference === "reject chat"
          ? `${t("acknowledgement_rejectRequestTitle")}`
          : historyData?.reference === "end chat"
          ? `${t("acknowledgement_chatClosedTitle")}`
          : historyData?.reference === "AI officer invited"
          ? `${t("acknowledgement_officerInvitedTitle")}`
          : historyData?.reference === "AI officer Request Confirm"
          ? `${t("acknowledgement_officerRequestConfirmTitle")}`
          : historyData?.reference === "AI officer Request Rejected"
          ? `${t("acknowledgement_rejectRequestTitle")}`
          : historyData?.reference === "document approval pending"
          ? `${t("acknowledgement_kycTitle")}`
          : historyData?.reference === "delete request"
          ? `${t("acknowledgement_requestDeletedTitle")}`
          : historyData?.reference === "sent request"
          ? `${t("acknowledgement_requestSentTitle")}`
          : historyData?.reference === "complaint raised"
          ? `${t("acknowledgement_reportSubmittedTitle")}`
          : `${t("acknowledgement_title")}`,
      content:
        getSessionKey("authUser") !== null
          ? `${t("acknowledgement_onboardedTitle")}`
          : historyData?.reference === "highlight interested"
          ? `${t("acknowledgement_interestNotedMessage")}`
          : historyData?.reference === "relation added"
          ? `${t("acknowledgement_addRelationMessage")}`
          : historyData?.reference === "pin changed"
          ? `${t("acknowledgement_pinChangedDesc")}`
          : historyData?.reference === "delete user"
          ? `${t("acknowledgement_deleteRequestMessage")}`
          : historyData?.reference === "express interest"
          ? `${t("acknowledgement_expressInterestTitle")}`
          : historyData?.reference === "reject chat" ||
            historyData?.reference === "end chat" ||
            historyData?.reference === "delete request" ||
            historyData?.reference === "sent request"
          ? ""
          : historyData?.reference === "AI officer invited"
          ? `${t("acknowledgement_officerInvitedMessage")}`
          : historyData?.reference === "AI officer Request Confirm"
          ? `${t("acknowledgement_officerRequestConfirmMessage")}`
          : historyData?.reference === "document approval pending"
          ? `${t("acknowledgement_kycPendingMessage")}`
          : historyData?.reference === "complaint raised"
          ? `${t("acknowledgement_reviewMessage")}`
          : `${t("acknowledgement_message")}`,
      type:
        historyData?.reference === "reject chat" ||
        historyData?.reference === "end chat" ||
        historyData?.reference === "delete request" ||
        historyData?.reference === "AI officer Request Rejected"
          ? "error"
          : historyData?.reference === "document approval pending"
          ? "kyc-error"
          : "success",
    });

  const handleProceed = () => {
    if (
      historyData?.reference === "delete user" ||
      getSessionKey("authUser") !== null
    ) {
      const keysToKeep = [
        "selectedLangCode",
        "langMaster",
        "selectedLang",
        "authUser",
      ];
      const keys = Object.keys(localStorage);
      // Iterate over the keys and remove the ones not in keysToKeep
      keys.forEach((key) => {
        if (!keysToKeep.includes(key)) {
          removeSessionKey(key);
        }
      });
      navigate("/login");
    } else {
      removeSessionKey("settingType");
      navigate("/impact");
    }
  };
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  return (
    <>
      <SuccessError {...acknowledgementMsg}></SuccessError>
      <div className={`${styles.footWrap}`}>
        <Button btnStyle="primary" onClick={handleProceed}>
          {t("acknowledgement_proceedBtn")}
        </Button>
      </div>
    </>
  );
}

export default Acknowledgement;
