import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import styles from "./style.module.scss";
import PhoneNumber from "../phone-number/PhoneNumber";
import UploadCard, { UploadCardProps } from "../upload-card/UploadCard";
import { BaseStructureUBODataType } from "src/pages/signup/legal-entity/ubo-details/UboDetails";
import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  DocumentDataType,
  IdentityProofObj,
} from "src/pages/signup/common/identity-proof/IdentityProof";
import { NULL } from "sass";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface UBOValuesDataType {
  [key: string]: any;
  fname: string;
  lname: string;
  countryCode: string;
  phn: string;
  idProof: string;
  residenceProof: string;
  shareholdingRegister: string;
}

const initialValues: UBOValuesDataType = {
  fname: "",
  lname: "",
  countryCode: "+91",
  phn: "",
  idProof: "",
  residenceProof: "",
  shareholdingRegister: "",
};

function AddUbo(
  props: BaseStructureUBODataType & { isEdit: boolean } & {
    cardIndex: number;
  } & { setUboStructuresState: any }
) {
  const { i18n, t } = useTranslation(["CompanyDetails"]);
  const validationSchema = yup.object({
    fname: yup.string().required(`${t("companydetails_validationFirstName")}`),
    lname: yup.string().required(`${t("companydetails_validationLastName")}`),
    phn: yup.number().required(`${t("companydetails_validationMobileNumber")}`),
    // .test("phone-num-length", "Invalid phone number", (value) => {
    //   return String(value).length === 10;
    // }),
    idProof: yup.string().required(`${t("companydetails_validationIdentity")}`),
    residenceProof: yup
      .string()
      .required(`${t("companydetails_validationAddress")}`),
    shareholdingRegister: yup
      .mixed()
      .required(`${t("companydetails_validationShareHolding")}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        (value) => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"];
            // console.log('value', value);
            // return supportedFormats.includes(value?.name.split('.').pop());
          }
          return true;
        }
      )
      .test("fileSize", "File size must not be more than 5MB", (value) => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true;
      }),
  });
  const formik = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("ubo details values: ", values);
    },
  });
  const [docUploadField, setDocUploadField] = useState<UploadCardProps[]>([
    {
      id: null,
      cardId: "01",
      uploadType: "Identity Proof",
      cardTitle: "",
      uploadText: `${t("companydetails_identityProofSubTitle")}`,
      helpText: "This is a dummy tooltip",
      popupTitle: `${t("companydetails_identityProofTitle")}`,
      popupDesc: `${t("companydetails_essentialDetailsSubTitle")}`,
      uploadStatus: false,
      documentData: null,
      docFileObj: null,
      errorMsg: "",
      fieldKey: "idProof",
      docSecDataObj: null,
    },
    {
      id: null,
      cardId: "02",
      uploadType: "Address",
      cardTitle: "",
      uploadText: `${t("companydetails_addressTitle")}`,
      helpText: "This is a dummy tooltip",
      popupTitle: `${t("companydetails_addressTitle")}`,
      popupDesc: `${t("companydetails_essentialDetailsSubTitle")}`,
      uploadStatus: false,
      documentData: null,
      docFileObj: null,
      errorMsg: "",
      fieldKey: "residenceProof",
      docSecDataObj: null,
    },
    {
      id: null,
      cardId: "03",
      uploadType: "Document",
      cardTitle: "",
      uploadText: `${t("companydetails_shareholdingSubTitle")}`,
      helpText: "This is a dummy tooltip",
      popupTitle: `${t("companydetails_shareholdingTitle")}`,
      popupDesc: `${t("companydetails_essentialUbo")}`,
      uploadStatus: false,
      documentData: null,
      docFileObj: null,
      errorMsg: "",
      fieldKey: "shareholdingRegister",
      docSecDataObj: null,
    },
  ]);

  const handleFileUpload = (
    file: any,
    fileData: DocumentDataType | null | undefined,
    index: any,
    secData?: any,
    fieldKey?: string
  ) => {
    updateItemValue(index, "uploadStatus", true);
    updateItemValue(index, "documentData", fileData);
    updateItemValue(index, "docFileObj", file);
    updateItemValue(index, "docSecDataObj", secData);
    if (fileData === null) {
      formik.setFieldValue(`${fieldKey}`, "");
    } else {
      formik.setFieldValue(`${fieldKey}`, fileData?.fileurl);
    }

    props.setUboStructuresState((prevArray: BaseStructureUBODataType[]) => {
      const newState = prevArray.map((obj) => {
        if (obj.UBOsCardId === props.UBOsCardId) {
          return {
            ...obj,
            document: obj.document.map((doc) => {
              if (doc.fieldId === fieldKey) {
                if (fieldKey === "idProof") {
                  return {
                    ...doc,
                    documenturl: secData.documentFileURL,
                    documentsubtype: secData.identityProofType,
                    expirydate: new Date(secData.documentExpiryDate),
                    documentnumber: secData.documentNo,
                    filetype: secData.documentFileType,
                    fileName: secData.documentFileName,
                  };
                } else if (fieldKey === "residenceProof") {
                  return {
                    ...doc,
                    documenturl: secData.documentFileURL,
                    documentsubtype: secData.documentType,
                    country: secData.country,
                    provience: secData.province,
                    zipcode: secData.zipcode,
                    city: secData.city,
                    street: secData.streetArea,
                    housenumber: secData.houseNo,
                    fileType: secData.documentFileType,
                    fileName: secData.documentFileName,
                    region: secData.region,
                    expirydate: secData.documentDate,
                  };
                } else if (fieldKey === "shareholdingRegister") {
                  return {
                    ...doc,
                    documenturl: fileData?.fileurl,
                    fileType: fileData?.fileurl,
                    fileName: fileData?.filename,
                  };
                }
              }
              return doc;
            }),
          };
        }
        return obj;
      });
      return newState;
    });

    // checkUBOValidState();
  };

  // Function to update a specific value in an item by index
  const updateItemValue = (
    index: number,
    key: keyof UploadCardProps,
    value: any
  ) => {
    setDocUploadField((prevItems) => {
      const updatedItems = [...prevItems];
      const updatedItem = { ...updatedItems[index] };
      updatedItem[key] = value;
      updatedItems[index] = updatedItem;
      return updatedItems;
    });
  };

  const handleOnBlur = (e: any, fieldRef: any, stateRef: any) => {
    // console.log("handleOnBlur", e, fieldRef);
    props.setUboStructuresState((prevArray: BaseStructureUBODataType[]) =>
      prevArray.map((obj) =>
        obj.UBOsCardId === props.UBOsCardId
          ? {
              ...obj,
              [stateRef]: formik.values[fieldRef],
              countrycode: formik.values.countryCode,
            }
          : obj
      )
    );
    formik.handleBlur(e);

    checkUBOValidState();
  };

  useEffect(() => {
    checkUBOValidState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.idProof,
    formik.values.residenceProof,
    formik.values.shareholdingRegister,
    formik.errors.idProof,
    formik.errors.residenceProof,
    formik.errors.shareholdingRegister,
  ]);

  const checkUBOValidState = () => {
    let isFilledStatus: boolean = false;
    if (formik.isValid) {
      isFilledStatus = true;
    }
    // console.log("isFilledStatus", isFilledStatus);
    props.setUboStructuresState((prevArray: BaseStructureUBODataType[]) =>
      prevArray.map((obj) =>
        obj.UBOsCardId === props.UBOsCardId
          ? {
              ...obj,
              isFilled: isFilledStatus,
            }
          : obj
      )
    );
  };

  useEffect(() => {
    if (props.isEdit) {
      formik.setFieldValue("fname", props.firstname);
      formik.setFieldValue("lname", props.lastname);
      formik.setFieldValue("countryCode", props.countrycode);
      formik.setFieldValue("phn", props.contact);

      props.document.forEach((item: any, idx: number) => {
        const index = findIndexByKey(docUploadField, "fieldKey", item.fieldId);
        // console.log('doc array index', index);

        getImageFileFromUrl(item?.documenturl, item?.fileName).then(
          (fileObj) => {
            // console.log('fileObj', formik.values.financialStatements, docUploadField[index].fieldKey, index);
            if (fileObj) {
              const prefillFileDataObj = {
                fileurl: item?.documenturl,
                filename: item?.fileName,
                filesize: "" + fileObj.size,
                filetype:
                  (item?.fileName).split(".")[
                    (item?.fileName).split(".").length - 1
                  ],
              };

              if (item.documenttype === "Identity Proof") {
                let identityProofObj: IdentityProofObj = {
                  identityProofType: "",
                  documentExpiryDate: new Date(),
                  documentNo: "",
                  documentFileURL: "",
                  documentFileName: "",
                  documentFileSize: "",
                  documentFileType: "",
                  id: null,
                };

                identityProofObj["documentFileURL"] = item?.documenturl;
                identityProofObj["documentFileName"] = item?.fileName;
                identityProofObj["documentFileSize"] = "" + fileObj.size;
                identityProofObj["documentFileType"] =
                  (item?.fileName).split(".")[
                    (item?.fileName).split(".").length - 1
                  ];
                identityProofObj["identityProofType"] = item?.documentsubtype;
                identityProofObj["documentExpiryDate"] = new Date(
                  item?.expirydate!
                );
                identityProofObj["documentNo"] = item?.documentnumber;
                identityProofObj["id"] = item?.id;

                updateItemValue(index, "docSecDataObj", identityProofObj);
              } else if (item.documenttype === "Residence Proof") {
                let residenceProofData = {
                  id: item?.id,
                  zipcode: item?.zipcode,
                  country: item?.country,
                  province: item?.provience,
                  region: "",
                  city: item?.city,
                  streetArea: item?.street,
                  houseNo: item?.housenumber,
                  documentType: item?.documentsubtype,
                  documentDate: new Date(item?.expirydate!),
                  documentFile: fileObj,
                  documentFileURL: item?.documenturl,
                  documentFileName: item?.fileName,
                  documentFileSize: "" + fileObj.size,
                  documentFileType:
                    (item?.fileName).split(".")[
                      (item?.fileName).split(".").length - 1
                    ],
                };
                updateItemValue(index, "docSecDataObj", residenceProofData);
              }

              updateItemValue(index, "uploadStatus", true);
              updateItemValue(index, "documentData", prefillFileDataObj);
              updateItemValue(index, "docFileObj", fileObj);
              updateItemValue(index, "id", item.id);

              if (prefillFileDataObj === null) {
                formik.setFieldValue(`${docUploadField[index].fieldKey}`, "");
              } else {
                formik.setFieldValue(
                  `${docUploadField[index].fieldKey}`,
                  item?.documenturl
                );
              }
            }
          }
        );
      });
    }
  }, [props.isEdit]);

  const filterItemsByType = (data: any, type: string) => {
    return data[0].userdetail.userlrdetail.filter(
      (item: any) => item.detailtype === type
    );
  };

  async function getImageFileFromUrl(imageUrl: any, fileName: any) {
    try {
      // Fetch image data
      const response = await fetch(imageUrl, {
        mode: "no-cors",
      });
      // fetch(imageUrl);
      const blob = await response.blob();

      // Create a File object from the blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  }

  const findIndexByKey = (
    array: UploadCardProps[],
    key: string,
    value: string
  ) => {
    return array.findIndex(
      (item: UploadCardProps) =>
        item[key as keyof UploadCardProps].toLowerCase() === value.toLowerCase()
    );
  };

  return (
    <div
      className={`${styles.cardWrap} ${
        props.isFilled ? `${styles.dataFilled}` : ""
      }`}
    >
      <Accordion className="bs-accordion typ-2" defaultExpanded={true}>
        <AccordionSummary id="panel2a-header">
          <div className={`${styles.cardHeader}`}>
            <span className={`${styles.icon}`}>
              <i></i>
            </span>
            <div className={`${styles.cardTitle}`}>
              <span>
                {!props.isFilled ? `Add` : ""} UBO {props.cardIndex}
              </span>
              {props.isFilled ? (
                <strong>
                  {props.firstname} {props.lastname}
                </strong>
              ) : (
                ""
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={`${styles.cardCont}`}>
            <div className="form-section typ-card">
              <h3 className="frm-sec-title">
                {t("companydetails_essentialDetailsTitle")}
              </h3>
              <p className="frm-sec-desc">
                {t("companydetails_essentialDetailsSubTitle")}
              </p>
              <div className="form-group">
                <FormControl variant="standard">
                  <TextField
                    id="fname"
                    name="fname"
                    label={t("companydetails_firstName")}
                    placeholder={t("companydetails_firstName")}
                    value={formik.values.fname}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      handleOnBlur(e, "fname", "firstname");
                    }}
                    error={formik.touched.fname && Boolean(formik.errors.fname)}
                    helperText={formik.touched.fname && formik.errors.fname}
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <FormControl variant="standard">
                  <TextField
                    id="lname"
                    name="lname"
                    label={t("companydetails_lastName")}
                    placeholder={t("companydetails_lastName")}
                    value={formik.values.lname}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      handleOnBlur(e, "lname", "lastname");
                    }}
                    error={formik.touched.lname && Boolean(formik.errors.lname)}
                    helperText={formik.touched.lname && formik.errors.lname}
                  />
                </FormControl>
              </div>
              <div className="form-group typ-phone typ-inner">
                <PhoneNumber
                  id="phn"
                  countryCodeId="countryCode"
                  value={formik.values.phn}
                  formikObj={formik}
                  onChange={formik.handleChange}
                  onBlur={handleOnBlur}
                  error={formik.touched.phn && Boolean(formik.errors.phn)}
                  helperText={formik.touched.phn && formik.errors.phn}
                ></PhoneNumber>
              </div>
            </div>
            {docUploadField.map((item, index) => {
              return (
                <div className="form-section typ-card" key={index}>
                  <h3 className="frm-sec-title">{item.popupTitle}</h3>
                  <p className="frm-sec-desc">
                    {t("companydetails_essentialDetailsTitle")}
                  </p>
                  <UploadCard
                    key={index}
                    {...item}
                    uploaded={item.uploadStatus}
                    onFileUpload={handleFileUpload}
                    index={index}
                    formikObj={formik}
                  ></UploadCard>
                </div>
              );
            })}

            {/* <div className="form-section typ-card">
              <h3 className="frm-sec-title">Identity Proof</h3>
              <p className="frm-sec-desc">
                Essential for background check and verification.
              </p>
              <UploadCard
                uploaded={false}
                uploadType="Identity Proof"
                uploadText="Add Identity Proof"
                cardId="001"
                popupTitle="Identity Proof"
                popupDesc="Essential for background check and verification."
              ></UploadCard>
            </div>
            <div className="form-section typ-card">
              <h3 className="frm-sec-title">Proof of Address</h3>
              <p className="frm-sec-desc">
                Essential for background check and verification.
              </p>
              <UploadCard
                uploaded={true}
                uploadType="Address"
                uploadText="Add Address"
                cardId="002"
                popupTitle="Proof of Address"
                popupDesc="Essential for background check and verification."
              ></UploadCard>
            </div>
            <div className="form-section typ-card">
              <h3 className="frm-sec-title">Shareholding Register</h3>
              <p className="frm-sec-desc">
                Essential for background check and verification.
              </p>
              <UploadCard
                uploaded={false}
                uploadType="Document"
                uploadText="Add Shareholding Register"
                cardId="003"
                popupTitle="Shareholding Register"
                popupDesc="Essential for UBO's background check."
              ></UploadCard>
            </div> */}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AddUbo;
