import React, { useEffect, useState } from "react";
import Header from "src/components/common/header/Header";
import { Tab, Tabs } from "@mui/material";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import CheckBox from "src/components/common/checkbox/CheckBox";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import BuyerSellerCard, {
  BuyerSellerCardData,
} from "../../components/buyer-seller-card/BuyerSellerCard";
import RequestCard from "src/components/request-card/RequestCard";
import Button from "src/components/common/button/Button";
import { useLoader } from "src/context/LoaderContext";
import axios from "axios";
import { getSessionKey } from "src/helper/sessionServices";
import Loader from "src/components/common/loader/Loader";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function CompanyDetailsPage() {
  const { i18n, t } = useTranslation(["CompanyDetails"]);
  const lang = getSessionKey("selectedLangCode");
  const userData = getSessionKey("userData");
  const navigate = useNavigate();
  const { loading, setLoading } = useLoader();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get("id");

  const [companyData, setCompanyData] = useState<any>({});
  const [mainTabValue, setMainTabValue] = useState(0);
  const [bottomSheetTabValue, setBottomSheetTabValue] = useState(0);
  const [openSortDrawer, setOpenSortDrawer] = useState(false);
  const [openNoteDrawer, setOpenNoteDrawer] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showExpressInterest, setShowExpressInterest] = useState(true);

  const handleCheckboxChange = (event: any) => {
    event.target.checked
      ? setIsCheckboxChecked(true)
      : setIsCheckboxChecked(false);
  };

  const handleButtonClick = () => {
    setOpenNoteDrawer(false);
    let historyData = {
      companyId: companyId,
    };
    if (userData.status === "document approval pending" || userData.status === "document reject") {
      let locationData = {
        reference: "document approval pending",
      };
      navigate("/acknowledgement", { state: locationData });
    } else {
      navigate("/express-interest", { state: historyData });
    }
  };

  const handleChangeMainTabs = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setMainTabValue(newValue);
  };

  const handleChangeBottomSheetTabs = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setBottomSheetTabValue(newValue);
  };
  const sortTabItems: { [key: string]: any[] } = {
    en: [
      {
        id: "tab01",
        title: "Title",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "checkbox",
            itemId: "request1",
            label: "Request 1",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "checkbox",
            itemId: "request2",
            label: "Request 2",
            count: 0,
            isChecked: false,
          },
        ],
      },
      {
        id: "tab03",
        title: "Date",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "radio",
            itemId: "last1month",
            label: "Last 1 Month",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "radio",
            itemId: "last3months",
            label: "Last 3 Months",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "radio",
            itemId: "all",
            label: "All",
            count: 0,
            isChecked: false,
          },
        ],
      },
    ],
    it: [
      {
        id: "tab01",
        title: "Title",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "checkbox",
            itemId: "request1",
            label: "Request 1",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "checkbox",
            itemId: "request2",
            label: "Request 2",
            count: 0,
            isChecked: false,
          },
        ],
      },
      {
        id: "tab03",
        title: "Data",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "radio",
            itemId: "last1month",
            label: "Ultimo mese",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "radio",
            itemId: "last3months",
            label: "Ultimi 3 mesi",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "radio",
            itemId: "all",
            label: "Tutte",
            count: 0,
            isChecked: false,
          },
        ],
      },
    ],
  };

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/company?id=${companyId}&lang_id=${
          getSessionKey("userData")?.lang_id
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        console.log(response?.data?.data[0]);
        setCompanyData(response?.data?.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if(Object.keys(companyData).length > 0) {
      companyData?.ActiveRequests.forEach((item: any) => {
        if(item?.useridInvestor === (getSessionKey("userRole") === "advisor" ? getSessionKey("selectedClient")?.investor?.id : getSessionKey("authToken")?.id)) {
          const createDate = new Date(item?.createdAt);
          const currentDate = new Date();
          const differenceInMillis = currentDate.getTime() - createDate.getTime();
          const differenceInDays = differenceInMillis / (1000 * 3600 * 24);
          console.log('differenceInDays', differenceInDays)
          if(differenceInDays < 30)
            setShowExpressInterest(false);
          else
            setShowExpressInterest(true);
        }
      });
    }
  }, [companyData])

  console.log('showExpressInterest', showExpressInterest)
  const summaryCompany =
    companyData?.sectiondata && companyData?.sectiondata.length > 0
      ? companyData?.sectiondata.filter(
          (comapny: any) =>
            comapny.sectionname === "summary" && comapny.key === "description"
        )
      : [];
  const impactCompany =
    companyData?.sectiondata && companyData?.sectiondata.length > 0
      ? companyData?.sectiondata.filter(
          (comapny: any) =>
            comapny.sectionname === "impact" && comapny.key === "description"
        )
      : [];
  const sustainGoalCompany =
    companyData?.sectiondata && companyData?.sectiondata.length > 0
      ? companyData?.sectiondata.filter(
          (comapny: any) =>
            comapny.sectionname === "sustainGoal" &&
            comapny.key === "description"
        )
      : [];
  const summaryCompanyData =
    companyData?.sectiondata && companyData?.sectiondata.length > 0
      ? companyData?.sectiondata.filter(
          (comapny: any) =>
            comapny.sectionname === "summary" && comapny.key !== "description"
        )
      : [];
  const impactCompanyData =
    companyData?.sectiondata && companyData?.sectiondata.length > 0
      ? companyData?.sectiondata.filter(
          (comapny: any) =>
            comapny.sectionname === "impact" && comapny.key !== "description"
        )
      : [];
  const sustainGoalCompanyData =
    companyData?.sustainarr && companyData?.sustainarr.length > 0
      ? companyData?.sustainarr.filter((comapny: any) => comapny.SustainGoal)
      : [];

  return (
    <>
      {loading ? <Loader /> : null}
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-com-detail typ-sticky-tabs">
          <div className={`${styles.portfolioHead}`}>
            <div className={`${styles.companyWrap}`}>
              <span className={`${styles.companyLogo}`}>
                <img src="/assets/logos/banyan-nation-logo.png" alt="" />
              </span>
              <div className={`${styles.companyIntro}`}>
                <strong className={`${styles.companyName}`}>
                  {companyData?.name}
                </strong>
                <span className={`${styles.tag}`}>{companyData?.country}</span>
              </div>
            </div>
          </div>
          <div className={`${styles.companyBody}`}>
            <div className="bs-tabs typ-list count-2">
              <Tabs value={mainTabValue} onChange={handleChangeMainTabs}>
                <Tab label={t("companydetails_overviewTitle")} />
                <Tab
                  label={
                    <React.Fragment>
                      {t("companydetails_activeRequestsTitle")}
                      <span className="count">
                        {companyData?.ActiveRequests?.length}
                      </span>
                    </React.Fragment>
                  }
                />
              </Tabs>
              <div className={`${styles.panelWrap}`}>
                <div
                  className={`${styles.tabPanel}`}
                  role="tabpanel"
                  hidden={mainTabValue !== 0}
                >
                  <div className={`${styles.overviewList}`}>
                    {sustainGoalCompany[0]?.value !== undefined ||
                    sustainGoalCompanyData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("companydetails_overviewSustainability")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: sustainGoalCompany[0]?.value || "",
                            }}
                          ></div>
                          <div className={`${styles.subWrap}`}>
                            <ul className={`${styles.subList}`}>
                              {sustainGoalCompanyData.map((company: any) => (
                                <li className={`${styles.subItem}`}>
                                  <i
                                    className={`${company.SustainGoal.classname}`}
                                  ></i>
                                  <span className={`${styles.text}`}>
                                    {company.SustainGoal.value}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* <div className={`${styles.subWrap}`}>
                            <ul className={`${styles.subList}`}>
                              <li className={`${styles.subItem}`}>
                                <i className={`${styles.growth}`}></i>
                                <span className={`${styles.text}`}>
                                  Decent Work and Economic Growth
                                </span>
                              </li>
                              <li className={`${styles.subItem}`}>
                                <i className={`${styles.industry}`}></i>
                                <span className={`${styles.text}`}>
                                  Industry Innovation and Infrastructure
                                </span>
                              </li>
                              <li className={`${styles.subItem}`}>
                                <i className={`${styles.climate}`}></i>
                                <span className={`${styles.text}`}>
                                  Climate Action
                                </span>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    ) : null}
                    {summaryCompany[0]?.value !== undefined ||
                    summaryCompanyData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("companydetails_overviewSummary")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: summaryCompany[0]?.value || "",
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : null}
                    {impactCompany[0]?.value !== undefined ||
                    impactCompanyData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("companydetails_overviewImpact")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: impactCompany[0]?.value || "",
                            }}
                          ></div>
                          {/* <div className={`${styles.tableWrap}`}>
                            <div className={`${styles.tableBody}`}>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Plastic Collected
                                </span>
                                <span className={`${styles.value}`}>
                                  120 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Plastic Recycled
                                </span>
                                <span className={`${styles.value}`}>
                                  130 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Volume Sold
                                </span>
                                <span className={`${styles.value}`}>
                                  1054 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Premium Sales (Volume){" "}
                                </span>
                                <span className={`${styles.value}`}>
                                  929 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Granules Produced
                                </span>
                                <span className={`${styles.value}`}>
                                  877 Tonnes
                                </span>
                              </div>
                            </div>
                          </div> */}
                          <div className={`${styles.tableWrap}`}>
                            <div className={`${styles.tableBody}`}>
                              {impactCompanyData.map((company: any) => (
                                <div className={`${styles.tableItem}`}>
                                  <span className={`${styles.label}`}>
                                    {company.key}
                                  </span>
                                  <span className={`${styles.value}`}>
                                    {company.value}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`${styles.tabPanel}`}
                  role="tabpanel"
                  hidden={mainTabValue !== 1}
                >
                  <div className={`${styles.requestsWrap}`}>
                    <div className={`${styles.headWrap}`}>
                      <h3 className={`${styles.listTitle}`}>
                        {t("companydetails_requestsTitle")}
                      </h3>
                      {/* <button
                        className={`${styles.sortBtn}`}
                        onClick={() => setOpenSortDrawer(true)}
                      >
                        <i></i>
                        {t("companydetails_sortBtn")}
                      </button> */}
                    </div>
                    <div className={`${styles.requestsList}`}>
                      {companyData?.ActiveRequests &&
                        companyData?.ActiveRequests.map(
                          (item: any, index: number) => {
                            return (
                              <div
                                className={`${styles.requestsItem}`}
                                key={index}
                              >
                                <RequestCard
                                  {...item}
                                  parentRef="companyDetails"
                                  index={index + 1}
                                  companyName={companyData?.name}
                                ></RequestCard>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showExpressInterest && getSessionKey("userData")?.status !== "freeze" &&
          getSessionKey("userRole") === "advisor" &&
          getSessionKey("selectedClient") !== null &&
          getSessionKey("selectedClient")?.expressInterest ? (
            <div className="act-foot">
              <Button
                onClick={() => setOpenNoteDrawer(true)}
                btnStyle="primary"
              >
                {t("companydetails_interestTitle")}
              </Button>
            </div>
          ) : (showExpressInterest && getSessionKey("userData")?.status !== "freeze" &&
              (getSessionKey("userRole") === "individual_investor") ||
            getSessionKey("userRole") === "legalrepresent") ? (
            <div className="act-foot">
              <Button
                onClick={() => setOpenNoteDrawer(true)}
                btnStyle="primary"
              >
                {t("companydetails_interestTitle")}
              </Button>
            </div>
          ) : null}
        </div>
      </main>
      <BottomSheet
        title={t("companydetails_sortBtn")}
        primaryBtnText={t("companydetails_applyBtn")}
        secondaryBtnText={t("companydetails_clearAllBtn")}
        size="large"
        open={openSortDrawer}
        setOpen={setOpenSortDrawer}
      >
        <div className="bs-filter">
          <div className="left-wrap">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={bottomSheetTabValue}
              onChange={handleChangeBottomSheetTabs}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              {sortTabItems[i18n.language].map((item: any, index: number) => {
                return <Tab key={index} label={item.title} />;
              })}
            </Tabs>
          </div>
          <div className="right-wrap">
            {sortTabItems[i18n.language].map((item: any, index: number) => {
              return (
                <div
                  className="tabpanel"
                  role="tabpanel"
                  hidden={bottomSheetTabValue !== index}
                  key={index}
                >
                  <div className="panel-list">
                    {item.tabPanelItems.map((item: any, index: number) => {
                      return (
                        <div className="filter-item" key={index}>
                          {item.itemType === "radio" ? (
                            <>
                              <div className="radio-group">
                                <input
                                  type="radio"
                                  id={item.itemId}
                                  name={item.itemType}
                                />
                                <label htmlFor={item.itemId}>
                                  {item.label}
                                </label>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {item.itemType === "checkbox" ? (
                            <>
                              <CheckBox
                                id={item.itemId}
                                label={item.label}
                                name={item.itemId}
                              ></CheckBox>
                            </>
                          ) : (
                            ""
                          )}
                          {item.count !== 0 ? (
                            <span className="count">{item.count}</span>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        title={t("companydetails_drawerNoteTitle")}
        primaryBtnText={t("companydetails_drawerAgreeBtn")}
        size="medium"
        open={openNoteDrawer}
        setOpen={setOpenNoteDrawer}
        btnDisabled={!isCheckboxChecked}
        btnOnClick={handleButtonClick}
        disableOverlayClose={true}
        className="disable-close"
      >
        <div className={`${styles.contentWrap}`}>
          <p className={`${styles.desc}`}>
            {t("companydetails_drawerNoteMessage")}
          </p>
        </div>
        <div className="check-foot">
          <CheckBox
            id="legalNote"
            label={t("companydetails_drawerNoteAcceptCheck")}
            name="Legal Note"
            onChange={handleCheckboxChange}
            isChecked={isCheckboxChecked}
          ></CheckBox>
        </div>
      </BottomSheet>
    </>
  );
}

export default CompanyDetailsPage;
