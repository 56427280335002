export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    
    // Get day
    const day = date.getDate();
    
    // Get month name
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const month = monthNames[date.getMonth()];
    
    // Get year
    //const year = date.getFullYear();
    const year = date.toLocaleString('default', { year: '2-digit' });
    
    // Get suffix for day (e.g., st, nd, rd, th)
    let daySuffix;
    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }
    
    // Format the date
    const formattedDate = `${day}${daySuffix} ${month} ${year}`;
    
    return formattedDate;
  }


