import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { getSessionKey, removeSessionKey } from "src/helper/sessionServices";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface PersonalDetailsType {
  fname: string;
  lname: string;
  phn: string;
}

export interface IdentificationDetailsType {
  identityProofType: string;
  documentNo: string;
}

export interface ResidenceDetailsType {
  documentType: string;
  houseNo: string;
  streetArea: string;
  region: string;
  province: string;
  city: string;
  country: string;
  zipcode: string;
}

export interface CompanyDetailsType {
  articlesOfAssociation: string;
  authSignatoriesOfCompany: string;
  companyIncorporationDate: string;
  companyName: string;
  financialStatements: string;
  noOfLRs: number;
  noOfUBOs: number;
  taxIdentificationNo: string;
  tradeRegister: string;
}

function ConfirmDetails() {
  const { i18n, t } = useTranslation(["ConfirmDetails"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const [personalDetails, setPersonalDetails] = useState<PersonalDetailsType>();
  const [identificationDetails, setIdentificationDetails] =
    useState<IdentificationDetailsType>();
  const [residenceDetails, setResidenceDetails] =
    useState<ResidenceDetailsType>();

  const [companyDetails, setCompanyDetails] = useState<CompanyDetailsType>();
  const [uboDetails, setUboDetails] = useState<any>();
  const [lrDetails, setLrDetails] = useState<any>();
  const [pocDetails, setPocDetails] = useState<any>();
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    if (getSessionKey("userRole") === "advisor") {
      setPersonalDetails(getSessionKey("personalDetails"));
      setIdentificationDetails(getSessionKey("identityProofObj"));
    } else if (getSessionKey("userRole") === "individual_investor") {
      setPersonalDetails(getSessionKey("personalDetails"));
      setIdentificationDetails(getSessionKey("identityProofObj"));
      setResidenceDetails(getSessionKey("residenceProofObj"));
    } else if (getSessionKey("userRole") === "legalrepresent") {
      setCompanyDetails(getSessionKey("companyDetails"));
      console.log("companyDetails", getSessionKey("companyDetails"));
      console.log(
        "companyDetailsDocuments",
        getSessionKey("companyDetailsDocuments")
      );
      setUboDetails(getSessionKey("uboDetails"));
      console.log("uboDetails", getSessionKey("uboDetails"));
      setLrDetails(getSessionKey("lrDetails"));
      console.log("lrDetails", getSessionKey("lrDetails"));
      setPocDetails(getSessionKey("lrPOIDetails"));
      console.log("setPocDetails", getSessionKey("lrPOIDetails"));
    }
  }, []);

  const handleRedirection = (route: string) => {
    navigate(route);
  };

  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-details-form">
          <div className={`${styles.section}`}>
            <div className={`${styles.sectionHead}`}>
              <h1 className={`${styles.pgTitle}`}>
                {t("confirmdetails_pgTitle")}
              </h1>
            </div>
            <div className="bs-confirmation">
              {personalDetails &&
              personalDetails !== null &&
              Object.keys(personalDetails).length > 0 ? (
                <div className="confirm-card">
                  <div className="head-wrap">
                    <strong className="card-title">
                      {t("confirmdetails_personalTitle")}
                    </strong>
                    <Button
                      btnStyle="text"
                      onClick={() => handleRedirection("/signup/details-form")}
                    >
                      {t("confirmdetails_editBtn")}
                    </Button>
                  </div>
                  <div className="cont-wrap">
                    <ul className="label-value-list">
                      <li className="label-value-item">
                        <label>{t("confirmdetails_name")}</label>
                        <span className="value">
                          {personalDetails?.fname} {personalDetails?.lname}
                        </span>
                      </li>
                      <li className="label-value-item">
                        <label>{t("confirmdetails_phoneNumber")}</label>
                        <span className="value">{personalDetails?.phn}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}

              {identificationDetails &&
              identificationDetails !== null &&
              Object.keys(identificationDetails).length > 0 ? (
                <div className="confirm-card">
                  <div className="head-wrap">
                    <strong className="card-title">
                      {t("confirmdetails_identificationTitle")}
                    </strong>
                    <Button
                      btnStyle="text"
                      onClick={() =>
                        handleRedirection("/signup/details-form/identity-proof")
                      }
                    >
                      {t("confirmdetails_editBtn")}
                    </Button>
                  </div>
                  <div className="cont-wrap">
                    <ul className="label-value-list">
                      <li className="label-value-item">
                        <label>{t("confirmdetails_identityProof")}</label>
                        <span className="value">
                          {identificationDetails?.identityProofType}
                        </span>
                      </li>
                      <li className="label-value-item">
                        <label>
                          {`${
                            identificationDetails?.identityProofType ===
                            "passport"
                              ? `${t("confirmdetails_passportTitle")}`
                              : `${t("confirmdetails_identityTitle")}`
                          }`}{" "}
                          {t("confirmdetails_number")}
                        </label>
                        <span className="value">
                          {identificationDetails?.documentNo}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}

              {residenceDetails &&
              residenceDetails !== null &&
              Object.keys(residenceDetails).length > 0 ? (
                <div className="confirm-card">
                  <div className="head-wrap">
                    <strong className="card-title">
                      {t("confirmdetails_residenceTitle")}
                    </strong>
                    <Button
                      btnStyle="text"
                      onClick={() =>
                        handleRedirection(
                          "/signup/details-form/residence-proof"
                        )
                      }
                    >
                      {t("confirmdetails_editBtn")}
                    </Button>
                  </div>
                  <div className="cont-wrap">
                    <ul className="label-value-list">
                      <li className="label-value-item">
                        <label>{t("confirmdetails_address")}</label>
                        <span className="value">
                          {`${residenceDetails?.houseNo} ${residenceDetails?.streetArea} ${residenceDetails?.region} ${residenceDetails?.province} ${residenceDetails?.city} ${residenceDetails?.country} ${residenceDetails?.zipcode}`}
                        </span>
                      </li>
                      <li className="label-value-item">
                        <label>{t("confirmdetails_addressProof")}</label>
                        <span className="value">
                          {residenceDetails?.documentType}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}

              {companyDetails &&
              companyDetails !== null &&
              Object.keys(companyDetails).length > 0 ? (
                <Accordion className="bs-accordion" defaultExpanded={true}>
                  <AccordionSummary id="panel2a-header">
                    <div className={`${styles.cardHeader}`}>
                      <div className={`${styles.cardTitle}`}>
                        {t("confirmdetails_companyTitle")}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={`${styles.cardCont}`}>
                      <div className="confirm-card">
                        <div className="head-wrap">
                          <strong className="card-title">
                            {t("confirmdetails_detailsTitle")}
                          </strong>
                          <Button
                            btnStyle="text"
                            onClick={() => {
                              if(getSessionKey("userExistData") !== null) {
                                removeSessionKey("companyDetails")
                              }
                              handleRedirection(
                                "/signup/details-form/company-details"
                              )
                            }}
                          >
                            {t("confirmdetails_editBtn")}
                          </Button>
                        </div>
                        <div className="cont-wrap">
                          <ul className="label-value-list">
                            <li className="label-value-item">
                              <label>{t("confirmdetails_companyName")}</label>
                              <span className="value">
                                {companyDetails?.companyName}
                              </span>
                            </li>
                            <li className="label-value-item">
                              <label>{t("confirmdetails_taxNumber")}</label>
                              <span className="value">
                                {companyDetails?.taxIdentificationNo}
                              </span>
                            </li>
                            <li className="label-value-item">
                              <label>
                                {t("confirmdetails_incorporationDate")}
                              </label>
                              <span className="value">
                                {companyDetails?.companyIncorporationDate}
                              </span>
                            </li>
                            <li className="label-value-item">
                              <div>
                                <label>{t("confirmdetails_ubo")}</label>
                                <span className="value">
                                  {uboDetails?.length}
                                </span>
                              </div>
                              <div>
                                <label>{t("confirmdetails_lr")}</label>
                                <span className="value">
                                  {lrDetails?.length}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ) : null}

              {uboDetails && uboDetails !== null && uboDetails.length > 0 ? (
                <Accordion className="bs-accordion" defaultExpanded={false}>
                  <AccordionSummary id="panel2a-header">
                    <div className={`${styles.cardHeader}`}>
                      <div className={`${styles.cardTitle}`}>
                        {t("confirmdetails_uboDetails")}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={`${styles.cardCont}`}>
                      {uboDetails &&
                        uboDetails.map((item: any, index: number) => {
                          return (
                            <div className="confirm-card" key={index}>
                              <div className="head-wrap">
                                <strong className="card-title">{`UBO${
                                  index + 1
                                }`}</strong>
                                <Button
                                  btnStyle="text"
                                  onClick={() => {
                                    if(getSessionKey("userExistData") !== null) {
                                      removeSessionKey("uboDetails")
                                    }
                                    handleRedirection(
                                      "/signup/details-form/ubo-details"
                                    )
                                  }}
                                >
                                  {t("confirmdetails_editBtn")}
                                </Button>
                              </div>
                              <div className="cont-wrap">
                                <ul className="label-value-list">
                                  <li className="label-value-item">
                                    <label>{t("confirmdetails_name")}</label>
                                    <span className="value">
                                      {item?.firstname} {item?.lastname}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("confirmdetails_phoneNumber")}
                                    </label>
                                    <span className="value">
                                      {item?.countrycode} - {item?.contact}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("confirmdetails_identityProof")}
                                    </label>
                                    <span className="value">
                                      {item?.document[0].documentsubtype}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>{t("confirmdetails_address")}</label>
                                    <span className="value">
                                      {`${item?.document[1].housenumber}, ${item?.document[1].street}, ${item?.document[1].provience}, ${item?.document[1].zipcode}, ${item?.document[1].city}, ${item?.document[1].country}`}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("confirmdetails_addressProof")}
                                    </label>
                                    <span className="value">
                                      {item?.document[1].documentsubtype}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ) : null}

              {lrDetails && lrDetails !== null && lrDetails.length > 0 ? (
                <Accordion className="bs-accordion" defaultExpanded={false}>
                  <AccordionSummary id="panel2a-header">
                    <div className={`${styles.cardHeader}`}>
                      <div className={`${styles.cardTitle}`}>
                        {t("confirmdetails_lrDetails")}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={`${styles.cardCont}`}>
                      {lrDetails &&
                        lrDetails.map((item: any, index: number) => {
                          return (
                            <div className="confirm-card" key={index}>
                              <div className="head-wrap">
                                <strong className="card-title">{`${t(
                                  "confirmdetails_lrSingular"
                                )}${index + 1}`}</strong>
                                <Button
                                  btnStyle="text"
                                  onClick={() => {
                                    if(getSessionKey("userExistData") !== null) {
                                      removeSessionKey("lrDetails")
                                    }
                                    handleRedirection(
                                      "/signup/details-form/legal-representative-details"
                                    )
                                  }}
                                >
                                  {t("confirmdetails_editBtn")}
                                </Button>
                              </div>
                              <div className="cont-wrap">
                                <ul className="label-value-list">
                                  <li className="label-value-item">
                                    <label>{t("confirmdetails_name")}</label>
                                    <span className="value">
                                      {item?.firstname} {item?.lastname}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("confirmdetails_phoneNumber")}
                                    </label>
                                    <span className="value">
                                      {item?.countrycode} - {item?.contact}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("confirmdetails_identityProof")}
                                    </label>
                                    <span className="value">
                                      {item?.document[0].documentsubtype}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>{t("confirmdetails_address")}</label>
                                    <span className="value">
                                      {`${item?.document[1].housenumber}, ${item?.document[1].street}, ${item?.document[1].provience}, ${item?.document[1].zipcode}, ${item?.document[1].city}, ${item?.document[1].country}`}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("confirmdetails_addressProof")}
                                    </label>
                                    <span className="value">
                                      {item?.document[1].documentsubtype}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ) : null}

              {pocDetails &&
              pocDetails !== null &&
              Object.keys(pocDetails).length > 0 ? (
                <Accordion className="bs-accordion" defaultExpanded={false}>
                  <AccordionSummary id="panel2a-header">
                    <div className={`${styles.cardHeader}`}>
                      <div className={`${styles.cardTitle}`}>
                        {t("confirmdetails_pointOfContactTitle")}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={`${styles.cardCont}`}>
                      <div className="confirm-card">
                        <div className="head-wrap">
                          <strong className="card-title">
                            {t("confirmdetails_detailsTitle")}
                          </strong>
                          <Button
                            btnStyle="text"
                            onClick={() =>
                              handleRedirection(
                                "/signup/details-form/contact-details"
                              )
                            }
                          >
                            {t("confirmdetails_editBtn")}
                          </Button>
                        </div>
                        <div className="cont-wrap">
                          <ul className="label-value-list">
                            <li className="label-value-item">
                              <label>{t("confirmdetails_name")}</label>
                              <span className="value">
                                {pocDetails?.firstname} {pocDetails?.lastname}
                              </span>
                            </li>
                            <li className="label-value-item">
                              <label>{t("confirmdetails_email")}</label>
                              <span className="value small">{pocDetails?.email}</span>
                            </li>
                            <li className="label-value-item">
                              <label>
                                {t("confirmdetails_countryOfResidence")}
                              </label>
                              <span className="value">
                                {pocDetails?.country}
                              </span>
                            </li>
                            <li className="label-value-item">
                              <label>{t("confirmdetails_phoneNumber")}</label>
                              <span className="value">
                                {pocDetails?.countryCode} -{" "}
                                {pocDetails?.contact}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ) : null}
            </div>
            <div className="form-foot">
              <Button
                btnStyle="primary"
                navigation={true}
                navigationUrl="/terms-conditions"
              >
                {t("confirmdetails_proceedBtn")}
              </Button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ConfirmDetails;
