import { Dispatch } from "react";
import axios from "axios";
import { LanguageAction } from "src/store/actions/common/language";
import { languageActionTypes } from "src/store/actionTypes/common/language";

export const getLanguages = () => {
    return async (dispatch: Dispatch<LanguageAction>) => {
      // Add login logic here
      dispatch({ 
        type: languageActionTypes.GET_LANGUAGES
      });
      
      try {
        const { data } = await axios({
          method: 'get',
          url: 'https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/language'
        });

        dispatch({
          type: languageActionTypes.GET_LANGUAGES_SUCCESS,
          payload: data.data
        })
      } catch (error: any) {
        console.log('error', error);
        dispatch({
          type: languageActionTypes.GET_LANGUAGES_FAIL,
          payload: error
        })
      }
    };
};

export const setLanguage = (selectedLang: string | number) => {
  return async (dispatch: Dispatch<LanguageAction>) => {
    dispatch({
      type: languageActionTypes.SET_LANGUAGES,
      payload: selectedLang
    })
  };
}