import { languageActionTypes } from "src/store/actionTypes/common/language";
import { LanguageAction } from "src/store/actions/common/language";

interface LanguageData {
  language_code: string,
  language_name: string,
  language_icon: string | undefined
} 

interface LanguageStates {
  loading: boolean,
  error: object | null,
  data: LanguageData[],
  selectedLang: string | number
}

const initialState: LanguageStates = {
  loading: false,
  data: [],
  error: null,
  selectedLang: ''
};

const languageReducer = (state: LanguageStates = initialState, action: LanguageAction) => {
    switch (action.type) {
      case languageActionTypes.GET_LANGUAGES:
        return {
          loading: true,
          data: [],
          error: null
        };

      case languageActionTypes.GET_LANGUAGES_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: null
        };

      case languageActionTypes.GET_LANGUAGES_FAIL:
        return {
          ...state,
          loading: false,
          data: [],
          error: action.payload
        };

      case languageActionTypes.SET_LANGUAGES:
        return {
          ...state,
          selectedLang: action.payload
        };

      default:
        return state;
    }
};
  
export default languageReducer;