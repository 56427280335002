import styles from "./style.module.scss";
import Button from "../common/button/Button";

export interface ExploreData {
  buttonText?: string;
  navigationLink?: string;
  logoURL: string;
  description: string;
  handleBtnClick?: any;
  data?: any;
}

function Exploration(props: ExploreData) {
  return (
    <div className={`${styles.logoContentWrap} notice-container`}>
      <div className={`${styles.exploreImage}`}>
        {/* <img src={props.exploreImage} alt={`explore-${[props.title]}`} /> */}
        <img src={props.logoURL} alt="opp" />
      </div>
      <div className={`${styles.contentWrap}`}>
        <h2 className={`${styles.title}`}>{props.description}</h2>
      </div>
      <div className={`${styles.footWrap}`}>
        {props.navigationLink !== undefined ? (
          <Button
            navigation={true}
            navigationUrl={props.navigationLink}
            btnStyle="primary"
          >
            {props.buttonText}
          </Button>
        ) : (
          <Button
            onClick={() => props.handleBtnClick(props.data)}
            btnStyle="primary"
          >
            {props.buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default Exploration;
