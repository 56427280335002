import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "src/components/common/button/Button";
import SwiperComponent from "src/components/common/swiper/SwiperComponent";
import { Pagination } from "swiper";
import LatestTransaction from "src/components/latest-transaction/LatestTransaction";
import NewsCard, { NewsCardData } from "src/components/news-card/NewsCard";
import InsightCard, {
  InsightCardData,
} from "src/components/insight-card/InsightCard";
import ReportsCard, {
  ReportCardData,
} from "src/components/reports-card/ReportsCard";
import HighlightCard, {
  HighlightCardData,
} from "src/components/highlight-card/HighlightCard";
import { Autoplay } from "swiper";
import axios from "axios";
import { authToken, headers } from "src/helper/authToken";
import { getSessionKey } from "src/helper/sessionServices";
import { useLoader } from "../../context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styles from "./style.module.scss";
import { setSessionKey } from "src/helper/sessionServices";
function ImpactPage() {
  const { userData, loading, setLoading } = useLoader();
  const [highlightData, setHighlightData] = useState<HighlightCardData[]>([]);
  const [newsCards, setNewsCards] = useState<NewsCardData[]>([]);
  const [reportsData, setReportsData] = useState<ReportCardData[]>([]);
  const [insightData, setInsightData] = useState<InsightCardData[]>([]);
  const [openOnboardDrawer, setOpenOnboardDrawer] = useState(false);
  const [selectedOnboardType, setSelectedOnboardType] = useState("");
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
  const handleProfileDrawerButtonClick = () => {
    setOpenProfileDrawer(false);
    navigate("/profile-update/create-pin");
  };
  //setSessionKey("addClientObj", "")
  //const userData = getSessionKey("userData");
  const navigate = useNavigate();
  const handleNavigate = () => {
    //setSessionKey("userRole", JSON.stringify(selectedOnboardType));
    navigate("/add-client");
  };

  useEffect(() => {
    // const userData = getSessionKey("userData");
    console.log(
      "userData",
      userData?.pin,
      !userData?.isSetPinSkip,
      userData?.isSetPinSkip,
      userData?.pin === null &&
        !userData?.isSetPinSkip &&
        userData?.isSetPinSkip !== undefined
    );
    if (
      userData?.pin === null &&
      !userData?.isSetPinSkip &&
      userData?.isSetPinSkip !== undefined
    ) {
      setOpenProfileDrawer(true);
    }
    // else if (userData?.role === "advisor" && !userData?.addedClient) {
    //   handleOpenBottomSheet();
    // }
  }, [userData]);

  useEffect(() => {
    setLoading(true);
    // Define your header
    if (getSessionKey("userRole") !== "explorer") {
      // get reports endpoint call
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/report?addhome=1&lang_id=${getSessionKey(
            "selectedLang"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          setReportsData(response?.data?.data);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });

      // get highlights endpoint call
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/highlight?addhome=1&lang_id=${getSessionKey(
            "selectedLang"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          setHighlightData(response?.data?.data);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }

    // Make a GET request with the specified headers
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/news?addhome=1&offset=1&limit=4&lang_id=${getSessionKey(
          "selectedLang"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setNewsCards(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });

    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/insight?addhome=1&lang_id=${getSessionKey(
          "selectedLang"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setInsightData(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });

    setTimeout(() => {
      setLoading(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [triggerGetHighlight, setTriggerGetHighlight] =
    useState<boolean>(false);
  useEffect(() => {
    if (triggerGetHighlight && getSessionKey("userRole") !== "explorer") {
      setLoading(true);
      // get highlights endpoint call
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/highlight?addhome=1&lang_id=${getSessionKey(
            "selectedLang"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          setHighlightData(response?.data?.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
      setTriggerGetHighlight(false);
    }
  }, [triggerGetHighlight]);

  const handleOpenBottomSheet = () => {
    setOpenOnboardDrawer(true);
    // const userData = getSessionKey("userData");
    // userData.addedClient = true
    // setSessionKey("userData", userData)
    //setSelectedOnboardType("");
  };

  const handleCloseBottomSheet = () => {
    setOpenOnboardDrawer(false);
    //setSelectedOnboardType("");
  };
  const handleRadio = (event: any) => {
    setSelectedOnboardType(event.target.id);
  };

  const { i18n, t } = useTranslation(["Home"]);
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  // const handleLanguageChange = (e: any) => {
  //   i18next.changeLanguage(e.target.value);
  // };
  return (
    <>
      {loading ? <Loader /> : <></>}
      <div className="lyt-main typ-main">
        {highlightData && highlightData.length > 0 && (
          <section>
            <div className="bs-section typ-news typ-depth">
              <div className="sec-head">
                <h2 className="cm-sec-title">{t("home_highlights")}</h2>
                <div className="act-wrap">
                  <Button
                    btnStyle="text"
                    navigation={true}
                    navigationUrl="/highlight-list"
                  >
                    <span>{t("home_viewAllBtn")}</span>
                    <i className="icon-next"></i>
                  </Button>
                </div>
              </div>
              <div className="sec-cont">
                <div className="bs-swiper typ-highlight">
                  <SwiperComponent
                    swiperConfig={{
                      slidesPerView: "auto",
                      spaceBetween: 8,
                      observer: true,
                      modules: [Pagination, Autoplay],
                      cssMode: false,
                      loop: true,
                      pagination: {
                        clickable: true,
                      },
                      autoplay: {
                        delay: 3000,
                      },
                      breakpoints: {
                        768: {
                          spaceBetween: 24,
                          cssMode: false,
                        },
                        1280: {
                          cssMode: false,
                          spaceBetween: 62,
                          autoplay: false,
                        },
                      },
                    }}
                  >
                    {/* {highlightData?.filter(item => item.addhome).map((item, index) => { */}
                    {highlightData?.map((item, index) => {
                      return (
                        <div key={index} className="slide-item">
                          <HighlightCard
                            {...item}
                            setTriggerGetHighlight={setTriggerGetHighlight}
                          ></HighlightCard>
                        </div>
                      );
                    })}
                  </SwiperComponent>
                  {/* <div className="swiper-pagination"></div> */}
                </div>
              </div>
            </div>
          </section>
        )}
        {newsCards && newsCards.length > 0 && (
          <section>
            <div className="bs-section typ-news typ-depth">
              <div className="sec-head">
                <h2 className="cm-sec-title">{t("home_news")}</h2>
                <div className="act-wrap">
                  <Button
                    btnStyle="text"
                    navigation={true}
                    navigationUrl="/news-list"
                  >
                    <span>{t("home_viewAllBtn")}</span>
                    <i className="icon-next"></i>
                  </Button>
                </div>
              </div>
              <div className="sec-cont">
                <div className="news-grid">
                  {newsCards.map((item, index) => {
                    return (
                      <div key={index} className="grid-item">
                        <NewsCard {...item} smallCard={true}></NewsCard>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        )}
        {reportsData && reportsData.length > 0 && (
          <section>
            <div className="bs-section typ-reports">
              <div className="sec-head">
                <h2 className="cm-sec-title">{t("home_reports")}</h2>
              </div>
              <div className="sec-cont">
                <div className="bs-swiper typ-reports">
                  <SwiperComponent
                    swiperConfig={{
                      slidesPerView: "auto",
                      spaceBetween: 12,
                      observer: true,
                      cssMode: true,
                      breakpoints: {
                        768: {
                          spaceBetween: 20,
                          cssMode: false,
                        },
                        1280: {
                          cssMode: false,
                          spaceBetween: 24,
                        },
                      },
                    }}
                  >
                    {reportsData.map((item, index) => {
                      return (
                        <div key={index} className="slide-item">
                          <ReportsCard {...item}></ReportsCard>
                        </div>
                      );
                    })}
                    <div className="slide-item card-view-all">
                      <Link to="/report-list">
                        <div className="title-wrap">
                          <span>{t("home_viewAllBtn")}</span>
                          <span>{t("home_reports")}</span>
                        </div>
                        <i></i>
                      </Link>
                    </div>
                  </SwiperComponent>
                </div>
              </div>
            </div>
          </section>
        )}
        {insightData && insightData.length > 0 && (
          <section>
            <div className="bs-section typ-insight">
              <div className="sec-head">
                <h2 className="cm-sec-title">{t("home_insights")}</h2>
              </div>
              <div className="sec-cont">
                <div className="bs-swiper typ-insight">
                  <SwiperComponent
                    swiperConfig={{
                      slidesPerView: "auto",
                      spaceBetween: 12,
                      observer: true,
                      cssMode: true,
                      breakpoints: {
                        768: {
                          spaceBetween: 20,
                          cssMode: false,
                        },
                        1280: {
                          cssMode: false,
                          spaceBetween: 24,
                        },
                      },
                    }}
                  >
                    {insightData.map((item, index) => {
                      return (
                        <div key={index} className="slide-item">
                          <InsightCard {...item}></InsightCard>
                        </div>
                      );
                    })}
                    <div className="slide-item card-view-all">
                      <Link to="/insight-list">
                        <div className="title-wrap">
                          <span>{t("home_viewAllBtn")}</span>
                          <span>{t("home_insights")}</span>
                        </div>
                        <i></i>
                      </Link>
                    </div>
                  </SwiperComponent>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* <section>
          <div className="bs-section typ-transaction">
            <div className="sec-head">
              <h2 className="cm-sec-title">Latest Transactions</h2>
            </div>
            <div className="sec-cont">
              <LatestTransaction></LatestTransaction>
            </div>
          </div>
        </section> */}
      </div>
      <BottomSheet
        title={t("home_drawerAddClientTitle")}
        subText={t("home_drawerAddClientSubTitle")}
        primaryBtnText={t("home_drawerAddNowBtn")}
        secondaryBtnText={t("home_drawerLaterBtn")}
        size="medium"
        open={openOnboardDrawer}
        setOpen={handleCloseBottomSheet}
        //btnDisabled={selectedOnboardType === ""}
        btnOnClick={handleNavigate}
        btnSecondaryOnClick={handleCloseBottomSheet}
      >
        <div className={`${styles.cardCont}`}>
          <div className={`${styles.cardWrap}`}>
            <div className={`${styles.journeyCard}`}></div>
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        open={openProfileDrawer}
        primaryBtnText={t("home_drawerCompleteBtn")}
        title={t("home_drawerSetupTitle")}
        subText={t("home_drawerSetupSubTitle")}
        size="medium"
        setOpen={setOpenProfileDrawer}
        className="disable-close"
        btnOnClick={handleProfileDrawerButtonClick}
      >
        <div className={`${styles.cardCont}`}>
          <ul className={`${styles.list}`}>
            <li className={`${styles.item}`}>
              <i className={`${styles.lock}`}></i>
              <span className={`${styles.text}`}>
                {t("home_drawerStepPin")}
              </span>
            </li>
            <li className={`${styles.item}`}>
              <i className={`${styles.addGallery}`}></i>
              <span className={`${styles.text}`}>
                {t("home_drawerStepPicture")}
              </span>
            </li>
            {/* <li className={`${styles.item}`}>
              <i className={`${styles.addProfile}`}></i>
              <span className={`${styles.text}`}>
                {userData?.role === "advisor"
                  ? `${t("home_drawerAddClientTitle")}`
                  : `${t("home_drawerStepAdvisor")}`}
              </span>
            </li> */}
          </ul>
        </div>
      </BottomSheet>
    </>
  );
}
export default ImpactPage;
