import InsightCard, {
  InsightCardData,
} from "src/components/insight-card/InsightCard";
import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import Header from "src/components/common/header/Header";
import axios from "axios";
import { authToken, headers } from "src/helper/authToken";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import { getSessionKey } from "src/helper/sessionServices";
import { useNavigate } from "react-router-dom";

function InsightListing() {
  const navigate = useNavigate();
  const { loading, setLoading } = useLoader();
  const [insightData, setInsightData] = useState<InsightCardData[]>([])
  useEffect(() => {
    setLoading(true);
    // Make a GET request with the specified headers
    axios.get(`https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/insight?lang_id=${getSessionKey("userData")?.lang_id}`, { headers: {"Authorization": `Bearer ${getSessionKey('authToken') !== null ? getSessionKey('authToken').accesstoken : ''}`} })
      .then(response => {
        setInsightData(response?.data?.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);
  
  return (
    <>
      {loading ?
        <Loader />
      : (<>
        <header>
          <Header backLink={true} pageTitle="Insights"></Header>
        </header>
        <main>
          <div className="lyt-main">
            <section>
              <div className="bs-section typ-depth">
                <div className="sec-cont">
                  <div className={`${styles.insightList}`}>
                    {insightData.map((item, index) => {
                      return (
                        <div className={`${styles.item}`}>
                          <InsightCard {...item} bigCard={true}></InsightCard>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </>)}
    </>
  );
}

export default InsightListing;
