import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import { formatDate } from "src/helper/formatDate";

export interface InsightCardData {
  id: string;
  headline: string;
  insightDate: string;
  tag: string;
  description: string;
  file_name?: string;
  tenure?: string;
  file_url?: string;
  insightcode: string;
  Company?: {
    name?: string;
  };
}

function InsightCard(props: InsightCardData & { bigCard?: boolean }) {
  return (
    <div
      className={`${styles.cardWrap} ${
        props.bigCard ? `${styles.typBig}` : ""
      }`}
    >
      {props.bigCard ? <></> : <span className={`${styles[props.tag]}`}></span>}
      <Link to={`/insight-detail?insightcode=${props.insightcode}`}>
        <div className={`${styles.headWrap}`}>
          <span className={`${styles.cardType}`}>{props.tag}</span>
        </div>
        <div className={`${styles.footWrap}`}>
          <h3 className={`${styles.cardTitle}`}>{props.headline}</h3>
          <span className={`${styles.date}`}>
            {formatDate(props.insightDate)}
            {props.bigCard ? <i></i> : <></>}
          </span>
        </div>
      </Link>
    </div>
  );
}

export default InsightCard;
