import Button from "src/components/common/button/Button";
import styles from "./style.module.scss";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DocumentUpload from "src/components/common/document-upload/DocumentUpload";
import { useNavigate } from "react-router-dom";
import { StepContext } from "../../DetailsForm";
import { useContext, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en";
import "dayjs/locale/it";
import { DocumentDataType } from "../identity-proof/IdentityProof";
import CountryData from "../../../../data/countryData.json";
import axios from "axios";
import { setSessionKey } from "src/helper/sessionServices";
import { useEffect } from "react";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

dayjs.extend(localizedFormat);

export interface PropsDataType {
  currentStep?: number;
  postDataToParent?: (data: any, file: any, fileData: any) => void;
  file?: any;
  documentData?: DocumentDataType | null;
  data?: any;
}
export interface ProvinceOptionDataType {
  province: string;
  province_code: string;
}

export interface CityOptionDataType {
  city: string;
}

export interface ResidencyProofDataType {
  id: string | number | null;
  zipcode: string;
  country: string;
  province: string;
  region: string;
  city: string;
  streetArea: string;
  houseNo: string;
  documentType: string;
  documentDate: Date | null;
  documentFile: null;
  documentFileURL: string;
  documentFileName: string;
  documentFileSize: string;
  documentFileType: string;
}

const intialValues: ResidencyProofDataType = {
  id: null,
  zipcode: "",
  country: "",
  province: "",
  region: "",
  city: "",
  streetArea: "",
  houseNo: "",
  documentType: "",
  documentDate: null,
  documentFile: null,
  documentFileURL: "",
  documentFileName: "",
  documentFileSize: "",
  documentFileType: "",
};

// function ResidenceProof(props: PropsDataType, { currentStep }: { currentStep: number }) {
function ResidenceProof(props: PropsDataType) {
  const { i18n, t } = useTranslation(["ResidenceProof"]);
  const [locale, setLocale] = useState<string>("en");
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    const currentLang = i18n.language;
    dayjs.locale(currentLang);
  }, [i18n.language]);
  const [prefillFileObj, setPrefillFileObj] = useState<any>(null);
  const [prefillUploadedFile, setPrefillUploadedFile] = useState<any>(null);
  const [documentData, setDocumentData] = useState<DocumentDataType>({
    fileurl: "",
    filename: "",
    filesize: "",
    filetype: "",
  });
  const userData = getSessionKey("userData");
  const validationSchema = yup.object({
    zipcode: yup.string().required(`${t("residenceproof_validationZipcode")}`),
    country: yup.string().required(`${t("residenceproof_validationCountry")}`),
    province: yup
      .string()
      .required(`${t("residenceproof_validationProvince")}`),
    region: yup.string().required(`${t("residenceproof_validationRegion")}`),
    city: yup.string().required(`${t("residenceproof_validationCity")}`),
    streetArea: yup.string().required(`${t("residenceproof_validationField")}`),
    houseNo: yup.string().required(`${t("residenceproof_validationHouse")}`),
    documentType: yup
      .string()
      .required(`${t("residenceproof_validationDocumentType")}`),
    documentDate: yup
      .date()
      .required(`${t("residenceproof_validationDocumentIssuance")}`),
    documentFile: yup
      .mixed()
      .required(`${t("residenceproof_validationDocument")}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        (value) => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"];
            // return supportedFormats.includes(value?.name.split('.').pop());
          }
          return true;
        }
      )
      .test("fileSize", "File size must not be more than 5MB", (value) => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true;
      }),
  });
  const [optionCountry, setOptionCountry] = useState(CountryData.countryList);
  const [optionProvince, setOptionProvince] = useState<
    ProvinceOptionDataType[]
  >([]);
  const [optionCity, setOptionCity] = useState<CityOptionDataType[]>([]);
  const [previewFileURL, setPreviewFileURL] = useState<string | undefined>("");
  const { step, setStep } = useContext(StepContext)!;
  const navigate = useNavigate();

  const formik = useFormik({
    validateOnMount: true,
    initialValues: intialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const authToken = getSessionKey("authToken");
      let residenceProofData = { ...values };
      residenceProofData["documentFileURL"] = documentData?.fileurl;
      residenceProofData["documentFileName"] = documentData?.filename;
      residenceProofData["documentFileSize"] = documentData?.filesize;
      residenceProofData["documentFileType"] = documentData?.filetype;

      if (getSessionKey("settingType") === "Residence") {
        //console.log("identity-->",identityProofObj)
        let registerUserPayload = {};
        /* if (userData.role === "advisor") {
          registerUserPayload = {
            userid: userData.id,
            userdetailid: userData.userDetailId | userData.userdetail.id,
            lang_id: userData.lang_id,
            contact: userData.phn,
            role: userData.role,
            //"country": residenceProofObj.country,
            firstname: userData.userdetail.firstname,
            lastname: userData.userdetail.lastname,
            //"residencecountry": residenceProofObj.country,
            //"zipcode": residenceProofObj.zipcode,
            //"city": residenceProofObj.city,
            gender: "",
            dateofbirth: "",
            linkDevice: "",
            img: userData.userdetail.img,
            //"street": residenceProofObj.streetArea,
            //"housenumber": residenceProofObj.houseNo,
            state: "",
            document: [
              {
                userid: userData.id,
                userdetailid: userData.userDetailId | userData.userdetail.id,
                documenturl: residenceProofData.documentFileURL,
                documenttype: "Identity Proof",
                documentsubtype: residenceProofData.identityProofType,
                expirydate: String(new Date(residenceProofData.documentExpiryDate)),
                documentnumber: residenceProofData.documentNo,
                fileType: residenceProofData.documentFileType,
                fileName: residenceProofData.documentFileName,
                provience: "",
              },
            ],
          };
        } else  */ if (userData.role === "individual_investor") {
          registerUserPayload = {
            userid: userData.id,
            userdetailid: userData.userDetailId | userData.userdetail.id,
            lang_id: userData.lang_id,
            contact: userData.phn,
            role: userData.role,
            firstname: userData.userdetail.firstname,
            lastname: userData.userdetail.lastname,
            residencecountry: userData.userdetail.document[1].country,
            gender: "",
            dateofbirth: null,
            linkDevice: "",
            img: userData.userdetail.img,
            state: "",
            document: [
              /* {
                userid: authToken.id,
                userdetailid: authToken.userDetailId | authToken.userdetail.id,
                documenturl: residenceProofData.documentFileURL,
                documenttype: "Identity Proof",
                documentsubtype: identityProofObj.identityProofType,
                expirydate: String(new Date(identityProofObj.documentExpiryDate)),
                documentnumber: identityProofObj.documentNo,
                fileType: identityProofObj.documentFileType,
                fileName: identityProofObj.documentFileName,
                provience: "",
                id: identityProofObj.id
              }, */
              {
                userid: authToken.id,
                userdetailid: authToken.userDetailId | authToken.userdetail.id,
                documenturl: residenceProofData.documentFileURL,
                documenttype: "Residence Proof",
                documentsubtype: residenceProofData.documentType,
                expirydate: String(new Date(residenceProofData.documentDate!)),
                documentnumber: "",
                fileType: residenceProofData.documentFileType,
                fileName: residenceProofData.documentFileName,
                provience: residenceProofData.province,
                region: residenceProofData.region,
                country: residenceProofData.country,
                zipcode: residenceProofData.zipcode,
                city: residenceProofData.city,
                street: residenceProofData.streetArea,
                housenumber: residenceProofData.houseNo,
                id: residenceProofData.id,
              },
            ],
          };
        } else if (userData.role === "legalrepresent") {
          registerUserPayload = {
            userid: userData.id,
            userdetailid: userData.userDetailId | userData.userdetail.id,
            lang_id: userData.lang_id,
            role: "legalrepresent",
            lrpointofcontact: userData.contact,
          };
        }
        const headers = {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
            "Content-Type": "application/json",
          },
        };
        axios
          .put(
            "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/userdetaildocument",
            registerUserPayload,
            {
              headers: {
                Authorization: `Bearer ${
                  getSessionKey("authToken").accesstoken
                }`,
              },
            }
          )
          .then((res) => {
            console.log("res", res);
            if (res.data.success) {
              //setLoading(false);
              userData.userdetail.document[1].documenturl =
                residenceProofData.documentFileURL;
              //userData.userdetail.document[1].documenttype = "Residence Proof";
              //userData.userdetails[0].document[0].documentsubtype = residenceProofData.identityProofType;
              //userData.userdetails[0].document[0].expirydate = String(new Date(residenceProofData.documentExpiryDate));
              //userData.userdetails[0].document[1].documentnumber = residenceProofData.documentNo ;
              userData.userdetail.document[1].fileType =
                residenceProofData.documentFileType;
              userData.userdetail.document[1].fileName =
                residenceProofData.documentFileName;
              userData.userdetail.document[1].city = residenceProofData.city;
              userData.userdetail.document[1].country =
                residenceProofData.country;
              userData.userdetail.document[1].provience =
                residenceProofData.province;
              userData.userdetail.document[1].region =
                residenceProofData.region;
              userData.userdetail.document[1].zipcode =
                residenceProofData.zipcode;
              userData.userdetail.document[1].street =
                residenceProofData.streetArea;
              userData.userdetail.document[1].housenumber =
                residenceProofData.houseNo;
              userData.userdetail.document[1].documentsubtype =
                residenceProofData.documentFileType;
              userData.userdetail.document[1].expirydate =
                residenceProofData.documentDate;
              setSessionKey("userData", userData);
              navigate("/client-approve");
            }
          })
          .catch((error) => {
            //setLoading(false);
            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              navigate("/enter-pin");
            } else {
              console.error("Error fetching data:", error.message);
            }
          });
      } else if (getSessionKey("userRole") === "legalrepresent") {
        if (props.postDataToParent) {
          props.postDataToParent(
            residenceProofData,
            values.documentFile,
            documentData
          );
        }
      } else {
        setSessionKey("residenceProofObj", JSON.stringify(residenceProofData));
        navigate("/signup/confirm-details");
      }
      //console.log('formik',formik)
    },
  });

  const handleFileUpload = (file: any, fileData: DocumentDataType | null) => {
    // const file = event.target.files && event.target.files[0];
    setDocumentData(fileData!);
    setPreviewFileURL(fileData === null ? "" : fileData?.fileurl);
    formik.setFieldValue("documentFile", file);
  };

  // const handleZipCodeBlur = (e: any) => {
  //   axios.get(`https://app.zipcodebase.com/api/v1/search?codes=${e.target.value}`, { headers: {"apikey": "02720a60-07a7-11ef-bc51-ab1625262ad1"} })
  //   .then((res) => {
  //     if(res.data.results) {
  //       setOptionProvince(res.data.results[formik.values.zipcode as keyof typeof res.data.results])
  //       formik.setFieldValue("country", res.data.results[formik.values.zipcode as keyof typeof res.data.results][0].country_code);
  //       setOptionCity(res.data.results[formik.values.zipcode as keyof typeof res.data.results])
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error fetching data:', error.message);
  //     // Due to CORS changes done here once cors issue resolved will remove this
  //     const res = {
  //       data: {
  //         "query": {
  //           "codes": [
  //               "421201"
  //           ],
  //           "country": null
  //         },
  //         "results": {
  //           "421201": [
  //               {
  //                   "postal_code": "421201",
  //                   "country_code": "IN",
  //                   "latitude": "19.21670000",
  //                   "longitude": "73.08330000",
  //                   "city": "Dombivali",
  //                   "state": "Maharashtra",
  //                   "city_en": "Dombivali",
  //                   "state_en": "Maharashtra",
  //                   "state_code": "MH",
  //                   "province": "Thane",
  //                   "province_code": "TN"
  //               },
  //               {
  //                   "postal_code": "421201",
  //                   "country_code": "IN",
  //                   "latitude": "19.21670000",
  //                   "longitude": "73.08330000",
  //                   "city": "Thakurli",
  //                   "state": "Maharashtra",
  //                   "city_en": "Thakurli",
  //                   "state_en": "Maharashtra",
  //                   "state_code": "MH",
  //                   "province": "Thane",
  //                   "province_code": "TN"
  //               },
  //               {
  //                   "postal_code": "421201",
  //                   "country_code": "IN",
  //                   "latitude": "19.21670000",
  //                   "longitude": "73.08330000",
  //                   "city": "Ramnagar (Thane)",
  //                   "state": "Maharashtra",
  //                   "city_en": "Ramnagar (Thane)",
  //                   "state_en": "Maharashtra",
  //                   "state_code": "MH",
  //                   "province": "Thane",
  //                   "province_code": "TN"
  //               },
  //               {
  //                   "postal_code": "421201",
  //                   "country_code": "IN",
  //                   "latitude": "19.21670000",
  //                   "longitude": "73.08330000",
  //                   "city": "Tilaknagar (Thane)",
  //                   "state": "Maharashtra",
  //                   "city_en": "Tilaknagar (Thane)",
  //                   "state_en": "Maharashtra",
  //                   "state_code": "MH",
  //                   "province": "Thane",
  //                   "province_code": "TN"
  //               }
  //           ]
  //         }
  //       }
  //     }
  //     setOptionProvince(res.data.results[formik.values.zipcode as keyof typeof res.data.results])
  //     formik.setFieldValue("country", res.data.results[formik.values.zipcode as keyof typeof res.data.results][0].country_code);
  //     setOptionCity(res.data.results[formik.values.zipcode as keyof typeof res.data.results])
  //   });
  //   formik.handleBlur(e);
  // }

  // const prepopulatePincodeData = (pincode: any) => {
  //   axios.get(`https://app.zipcodebase.com/api/v1/search?codes=${pincode}`, { headers: {"apikey": "02720a60-07a7-11ef-bc51-ab1625262ad1"} })
  //   .then((res) => {
  //     if(res.data.results) {
  //       setOptionProvince(res.data.results[pincode as keyof typeof res.data.results])
  //       formik.setFieldValue("country", res.data.results[pincode as keyof typeof res.data.results][0].country_code);
  //       setOptionCity(res.data.results[pincode as keyof typeof res.data.results])
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error fetching data:', error.message);
  //     // Due to CORS changes done here once cors issue resolved will remove this
  //     const res = {
  //       data: {
  //         "query": {
  //           "codes": [
  //               "421201"
  //           ],
  //           "country": null
  //         },
  //         "results": {
  //           "421201": [
  //               {
  //                   "postal_code": "421201",
  //                   "country_code": "IN",
  //                   "latitude": "19.21670000",
  //                   "longitude": "73.08330000",
  //                   "city": "Dombivali",
  //                   "state": "Maharashtra",
  //                   "city_en": "Dombivali",
  //                   "state_en": "Maharashtra",
  //                   "state_code": "MH",
  //                   "province": "Thane",
  //                   "province_code": "TN"
  //               },
  //               {
  //                   "postal_code": "421201",
  //                   "country_code": "IN",
  //                   "latitude": "19.21670000",
  //                   "longitude": "73.08330000",
  //                   "city": "Thakurli",
  //                   "state": "Maharashtra",
  //                   "city_en": "Thakurli",
  //                   "state_en": "Maharashtra",
  //                   "state_code": "MH",
  //                   "province": "Thane",
  //                   "province_code": "TN"
  //               },
  //               {
  //                   "postal_code": "421201",
  //                   "country_code": "IN",
  //                   "latitude": "19.21670000",
  //                   "longitude": "73.08330000",
  //                   "city": "Ramnagar (Thane)",
  //                   "state": "Maharashtra",
  //                   "city_en": "Ramnagar (Thane)",
  //                   "state_en": "Maharashtra",
  //                   "state_code": "MH",
  //                   "province": "Thane",
  //                   "province_code": "TN"
  //               },
  //               {
  //                   "postal_code": "421201",
  //                   "country_code": "IN",
  //                   "latitude": "19.21670000",
  //                   "longitude": "73.08330000",
  //                   "city": "Tilaknagar (Thane)",
  //                   "state": "Maharashtra",
  //                   "city_en": "Tilaknagar (Thane)",
  //                   "state_en": "Maharashtra",
  //                   "state_code": "MH",
  //                   "province": "Thane",
  //                   "province_code": "TN"
  //               }
  //           ]
  //         }
  //       }
  //     }
  //     setOptionProvince(res.data.results[pincode as keyof typeof res.data.results])
  //     formik.setFieldValue("country", res.data.results[pincode as keyof typeof res.data.results][0].country_code);
  //     setOptionCity(res.data.results[pincode as keyof typeof res.data.results])
  //   });
  //   formik.handleBlur("zipcode");
  // }

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  useEffect(() => {
    let userDetails: any;
    if (getSessionKey("settingType") === "Residence") {
      userDetails = [userData];
    } else if (getSessionKey("userRole") === "legalrepresent") {
      userDetails = props.data;
    } else {
      setStep(props.currentStep!);
      userDetails =
        getSessionKey("residenceProofObj") !== null
          ? getSessionKey("residenceProofObj")
          : getSessionKey("userExistData");
    }

    //const userExistData = getSessionKey("userExistData")
    console.log("userDetails", userDetails);
    if (userDetails !== null) {
      /* formik.setFieldValue("fname", userDetails.fname ? userDetails.fname: userDetails[0].userdetail.firstname);
      formik.setFieldValue("lname", userDetails.lname ? userDetails.lname: userDetails[0].userdetail.lastname);
      formik.setFieldValue("phn", userDetails.phn ? userDetails.phn: userDetails[0].contact);
      formik.handleBlur("phn"); */
      formik.setFieldValue(
        "id",
        userDetails?.id === null
          ? null
          : userDetails.id
          ? userDetails.id
          : userDetails[0].userdetail.document[1].id
      );
      formik.setFieldValue(
        "zipcode",
        userDetails.zipcode
          ? userDetails.zipcode
          : userDetails[0].userdetail.document[1].zipcode
      );
      // prepopulatePincodeData(userDetails.zipcode? userDetails.zipcode : userDetails[0].userdetail.document[1].zipcode)
      formik.setFieldValue(
        "country",
        userDetails.country
          ? userDetails.country
          : userDetails[0].userdetail.document[1].country
      );
      formik.setFieldValue(
        "province",
        userDetails.province
          ? userDetails.province
          : userDetails[0].userdetail.document[1].provience
      );
      formik.setFieldValue(
        "region",
        userDetails?.region
          ? userDetails?.region
          : userDetails[0]?.userdetail.document[1]?.region
      );
      formik.setFieldValue(
        "city",
        userDetails.city
          ? userDetails.city
          : userDetails[0].userdetail.document[1].city
      );
      formik.setFieldValue(
        "streetArea",
        userDetails.streetArea
          ? userDetails.streetArea
          : userDetails[0].userdetail.document[1].street
      );
      formik.setFieldValue(
        "houseNo",
        userDetails.houseNo
          ? userDetails.houseNo
          : userDetails[0].userdetail.document[1].housenumber
      );
      formik.setFieldValue(
        "documentType",
        userDetails.documentType
          ? userDetails.documentType
          : userDetails[0].userdetail.document[1].documentsubtype
      );
      formik.setFieldValue(
        "documentDate",
        userDetails.documentDate
          ? dayjs(userDetails.documentDate)
          : dayjs(userDetails[0].userdetail.document[1].expirydate)
      );
      //formik.handleChange("phn")
      //formik.touched.phn
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* useEffect(() => {
    const userDetails = getSessionKey("userExistData");
    //const userExistData = getSessionKey("userExistData")
    if(userDetails !== null) {
       setDocType(getSessionKey("userExistData")[0].userdetail.document[0].documentsubtype);
    }
    
  },[]) */
  // console.log("formik",formik)

  useEffect(() => {
    let userDetails: any;
    if (getSessionKey("settingType") === "Residence") {
      userDetails = [userData];
    } else if (getSessionKey("userRole") === "legalrepresent") {
      userDetails = props.data;
    } else {
      userDetails =
        getSessionKey("residenceProofObj") !== null
          ? getSessionKey("residenceProofObj")
          : getSessionKey("userExistData");
    }

    // const userDetails = getSessionKey("residenceProofObj")!== null? getSessionKey("residenceProofObj") : getSessionKey("userExistData");
    //const userExistData = getSessionKey("userExistData")
    if (userDetails !== null) {
      //setDocType(getSessionKey("userExistData")[0].userdetail.document[0].documentsubtype);
      getImageFileFromUrl(
        userDetails.documentFileURL
          ? userDetails.documentFileURL
          : userDetails[0].userdetail.document[0].documenturl,
        userDetails.documentFileName
          ? userDetails.documentFileName
          : userDetails[0].userdetail.document[0].fileName
      ).then((fileObj) => {
        if (fileObj) {
          // console.log('fileObj', fileObj);
          // console.log('userDetails', userDetails[0].userdetail.document[0]);
          const prefillDocumentData = {
            id: "" + fileObj.lastModified,
            fileurl: userDetails.documentFileURL
              ? userDetails.documentFileURL
              : userDetails[0].userdetail.document[0].documenturl,
            filename: userDetails.documentFileName
              ? userDetails.documentFileName
              : userDetails[0].userdetail.document[0].fileName,
            filesize: "" + fileObj.size,
            filetype: userDetails.documentFileType
              ? userDetails.documentFileType
              : userDetails[0].userdetail.document[0].fileType,
          };

          const prefillUploadedDocumentData = {
            id: "" + fileObj.lastModified,
            name: userDetails.documentFileName
              ? userDetails.documentFileName
              : userDetails[0].userdetail.document[0].fileName,
            size: "" + fileObj.size,
            type: userDetails.documentFileType
              ? userDetails.documentFileType
              : userDetails[0].userdetail.document[0].fileType,
          };
          // console.log('prefillDocumentData', prefillDocumentData);
          // if(userDetails[0].userdetail.document[0].documentsubtype===documentType){
          setPrefillFileObj(fileObj);
          setPrefillUploadedFile(prefillUploadedDocumentData);
          handleFileUpload(fileObj, prefillDocumentData);
          /* } else {
              setPrefillFileObj(null)
              setPrefillUploadedFile(null)
            } */
        }
      });
    }
  }, []);

  async function getImageFileFromUrl(imageUrl: any, fileName: any) {
    try {
      // Fetch image data
      const response = await fetch(imageUrl, {
        mode: "no-cors",
      });
      // fetch(imageUrl);
      const blob = await response.blob();

      // Create a File object from the blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  }

  return (
    <div className="lyt-main typ-details-form">
      <div className={`${styles.section}`}>
        {getSessionKey("userRole") !== "legalrepresent" && (
          <div className={`${styles.sectionHead}`}>
            <h1 className={`${styles.pgTitle}`}>
              {t("residenceproof_pgTitle")}
            </h1>
          </div>
        )}
        <div className="bs-form">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-body">
              <div className="form-section">
                <h3 className="frm-sec-title">
                  {t("residenceproof_addAddress")}
                </h3>
                <div className="form-group">
                  <FormControl variant="standard">
                    <TextField
                      id="zipcode"
                      name="zipcode"
                      label={t("residenceproof_zipCode")}
                      placeholder={t("residenceproof_zipCode")}
                      value={formik.values.zipcode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.zipcode && Boolean(formik.errors.zipcode)
                      }
                      helperText={
                        formik.touched.zipcode && formik.errors.zipcode
                      }
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <div className="bs-select">
                    <FormControl fullWidth>
                      <InputLabel className="input-label">
                        {t("residenceproof_country")}
                      </InputLabel>
                      <Select
                        id="country"
                        name="country"
                        label={t("residenceproof_country")}
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                      >
                        {optionCountry
                          ? optionCountry.map((country, index) => {
                              // Here goes your models option
                              return (
                                <MenuItem value={country.value} key={index}>
                                  <em>{country.label}</em>
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                      {formik.touched.country && formik.errors.country ? (
                        <FormHelperText
                          sx={{
                            color: "#bf3333",
                            marginLeft: "16px !important",
                          }}
                        >
                          {formik.touched.country && formik.errors.country}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </div>
                </div>
                <div className="form-group">
                  <FormControl variant="standard">
                    <TextField
                      id="province"
                      placeholder={t("residenceproof_province")}
                      label={t("residenceproof_province")}
                      value={formik.values.province}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.province &&
                        Boolean(formik.errors.province)
                      }
                      helperText={
                        formik.touched.province && formik.errors.province
                      }
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl variant="standard">
                    <TextField
                      id="region"
                      name="region"
                      placeholder={t("residenceproof_region")}
                      label={t("residenceproof_region")}
                      value={formik.values.region}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.region && Boolean(formik.errors.region)
                      }
                      helperText={formik.touched.region && formik.errors.region}
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  {/* <div className="bs-select"> */}
                  <FormControl variant="standard">
                    <TextField
                      id="city"
                      name="city"
                      label={t("residenceproof_city")}
                      placeholder={t("residenceproof_city")}
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    />
                  </FormControl>
                  {/* <FormControl fullWidth>
                      <InputLabel className="input-label">City</InputLabel>
                      <Select 
                        id="city"
                        name="city" 
                        label="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.city &&
                          Boolean(formik.errors.city)
                        }
                      >
                        {optionCity ? optionCity.map((item, index) => {
                          // Here goes your models option
                          return <MenuItem value={item && item.city} key={index}>
                              <em>{item.city}</em>
                          </MenuItem>
                          }) : null
                        }
                      </Select>
                      {formik.touched.city && formik.errors.city ? (
                        <FormHelperText
                            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                        >
                            {formik.touched.city && formik.errors.city}
                        </FormHelperText>
                      ) : null}
                    </FormControl> */}
                  {/* </div> */}
                </div>
                <div className="form-group">
                  <FormControl variant="standard">
                    <TextField
                      id="streetArea"
                      name="streetArea"
                      label={t("residenceproof_streetArea")}
                      placeholder={t("residenceproof_streetArea")}
                      value={formik.values.streetArea}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.streetArea &&
                        Boolean(formik.errors.streetArea)
                      }
                      helperText={
                        formik.touched.streetArea && formik.errors.streetArea
                      }
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl variant="standard">
                    <TextField
                      id="houseNo"
                      name="houseNo"
                      placeholder={t("residenceproof_houseNumber")}
                      label={t("residenceproof_houseNumber")}
                      value={formik.values.houseNo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.houseNo && Boolean(formik.errors.houseNo)
                      }
                      helperText={
                        formik.touched.houseNo && formik.errors.houseNo
                      }
                    />
                  </FormControl>
                </div>
              </div>
              <div className="form-section">
                <h3 className="frm-sec-title">
                  {t("residenceproof_uploadAddProof")}
                </h3>
                <div className="form-group">
                  <div className="bs-select">
                    <FormControl fullWidth>
                      <InputLabel className="input-label">
                        {t("residenceproof_chooseDoc")}
                      </InputLabel>
                      <Select
                        id="documentType"
                        name="documentType"
                        label="Age"
                        value={formik.values.documentType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.documentType &&
                          Boolean(formik.errors.documentType)
                        }
                      >
                        <MenuItem value={`${t("residenceproof_selectGas")}`}>
                          {t("residenceproof_selectGas")}
                        </MenuItem>
                        <MenuItem value={`${t("residenceproof_selectWater")}`}>
                          {t("residenceproof_selectWater")}
                        </MenuItem>
                        <MenuItem
                          value={`${t("residenceproof_selectElectricity")}`}
                        >
                          {t("residenceproof_selectElectricity")}
                        </MenuItem>
                        <MenuItem
                          value={`${t("residenceproof_selectInternet")}`}
                        >
                          {t("residenceproof_selectInternet")}
                        </MenuItem>
                        <MenuItem value={`${t("residenceproof_selectTV")}`}>
                          {t("residenceproof_selectTV")}
                        </MenuItem>
                        <MenuItem
                          value={`${t("residenceproof_selectLandline")}`}
                        >
                          {t("residenceproof_selectLandline")}
                        </MenuItem>
                        <MenuItem value={`${t("residenceproof_selectTax")}`}>
                          {t("residenceproof_selectTax")}
                        </MenuItem>
                        <MenuItem
                          value={`${t("residenceproof_selectResidence")}`}
                        >
                          {t("residenceproof_selectResidence")}
                        </MenuItem>
                      </Select>
                      {formik.touched.documentType &&
                      formik.errors.documentType ? (
                        <FormHelperText
                          sx={{
                            color: "#bf3333",
                            marginLeft: "16px !important",
                          }}
                        >
                          {formik.touched.documentType &&
                            formik.errors.documentType}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </div>
                </div>
                <div className="form-group">
                  <div className="bs-datepicker">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}
                      localeText={{
                        okButtonLabel: `${t("residenceproof_calenderDoneBtn")}`,
                        cancelButtonLabel: `${t(
                          "residenceproof_calenderCloseBtn"
                        )}`,
                      }}
                    >
                      <DatePicker
                        name="documentDate"
                        className={
                          formik.errors.documentDate &&
                          formik.touched.documentDate
                            ? "Mui-error"
                            : ""
                        }
                        format="MM-DD-YYYY"
                        label={t("residenceproof_issuanceDate")}
                        value={formik.values.documentDate}
                        onChange={(date) =>
                          formik.setFieldValue("documentDate", date)
                        }
                        onClose={() => {
                          formik.setFieldTouched("documentDate", true);
                        }}
                        // maxDate={dayjs().add(0, 'day')}
                        disableFuture
                      />
                      {formik.touched.documentDate &&
                      formik.errors.documentDate ? (
                        <span className="MuiFormHelperText-root Mui-error">
                          {formik.errors.documentDate}
                        </span>
                      ) : null}
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="form-section">
                <h3 className="frm-sec-title">
                  {t("residenceproof_uploadProof")}
                </h3>
                <div className="form-group">
                  <DocumentUpload
                    id="documentFile"
                    value={formik.values.documentFile}
                    getFileData={handleFileUpload}
                    // onChange={formik.handleChange}
                    error={
                      formik.touched.documentFile &&
                      Boolean(formik.errors.documentFile)
                    }
                    helperText={
                      formik.touched.documentFile && formik.errors.documentFile
                    }
                    prefillUploadedFileData={prefillUploadedFile}
                    prefillFileObjData={prefillFileObj}
                    previewFileURL={previewFileURL}
                  ></DocumentUpload>
                </div>
              </div>
            </div>
            <div className="form-foot">
              <Button
                btnStyle="primary"
                type="submit"
                disabled={!formik.isValid}
              >
                {t("residenceproof_proceedBtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResidenceProof;
