import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import RadioGroupField, {
  RadioField,
} from "src/components/common/radio-group/RadioGroupField";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { actionCreators, useAppDispatch, useAppSelector } from "src/store";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import Button from "src/components/common/button/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface LanguageIcon {
  language_code: string;
  language_icon: string;
}

interface LanguageData {
  language_code: string;
  language_name: string;
  language_icon: string | undefined;
  id?: string | number;
}

function LanguageSelection() {
  const { loading, setLoading } = useLoader();
  const dispatch = useAppDispatch();
  const [languageData, setLanguageData] = useState<any[]>(
    getSessionKey("langMaster")
  );
  const [selectedLang, setSelectedLang] = useState<string | number>(
    getSessionKey("selectedLang")
  );
  const [languageCode, setLanguageCode] = useState<string | undefined>("en");
  const { data } = useAppSelector((state) => state.getLanguage);
  const [languageSelectionRadio, setLanguageSelectionRadio] = useState<
    RadioField[]
  >([]);
  const langIconArr: LanguageIcon[] = [
    {
      language_code: "it",
      language_icon: "/assets/flags/italy.png",
    },
    {
      language_code: "en",
      language_icon: "/assets/flags/england.png",
    },
    {
      language_code: "hin",
      language_icon: "/assets/flags/india.png",
    },
  ];
  useEffect(() => {
    setLoading(true);
    dispatch(actionCreators.getLanguages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      const modifiedData = data.map((item) => ({
        ...item,
        language_icon: langIconArr.find(
          (ele) => ele?.language_code === item?.language_code
        )?.language_icon,
      }));
      setLanguageData(modifiedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (languageData.length > 0) {
      let languageSelectionRadioArr: any[] = [];
      languageData.forEach((item) => {
        let temp = {
          value: item.id,
          label: (
            <span>
              <img
                // srcSet={`https://flagcdn.com/w40/${item.language_code.toLowerCase()}.png 2x`}
                // src={`https://flagcdn.com/w20/${item.language_code.toLowerCase()}.png`}
                src={item.language_icon}
                alt="lang icon"
              />
              <span>
                {`${
                  item.language_code === "en"
                    ? "English"
                    : item.language_code === "it"
                    ? "Italiano"
                    : ""
                }`}
                {/* Continue in {item.language_name} */}
              </span>
            </span>
          ),
        };
        languageSelectionRadioArr.push(temp);
      });
      setLanguageSelectionRadio(languageSelectionRadioArr);
      setLoading(false);
    }
  }, [languageData]);

  const { i18n, t } = useTranslation(["Onboarding"]);
  const handleLanguageChange = (e: any) => {
    i18next.changeLanguage(e.language_code);
  };

  const languageChange = (e: any) => {
    console.log('languageChange', languageChange);
    setSelectedLang(e.target.value);
    setSessionKey("selectedLang", e.target.value);
    console.log(e.target);
  };
  const navigate = useNavigate();

  const confirmLanguage = () => {
    setLoading(true);
    const payload = {
      "id": getSessionKey("authToken")?.id,
      "lang_id": Number(selectedLang)
    }
    axios
      .put(`https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user`, payload, {
        headers: {
          Authorization: `Bearer ${
            getSessionKey("authToken") !== null
              ? getSessionKey("authToken").accesstoken
              : ""
          }`,
        },
      })
      .then((response) => {
        if(response?.data?.success) {
          const temp = languageData.find((item: any) => Number(item.id) === Number(selectedLang));
          handleLanguageChange(temp);
          setLanguageCode(temp?.language_code);

          setSessionKey("selectedLangCode", JSON.stringify(temp?.language_code));
          navigate("/impact");
          
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };
  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main">
          <section>
            <div className="bs-section">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  {t("onboard_langTitle")}
                </h1>
                <p className={`${styles.subtitle}`}>{t("onboard_langText")}</p>
              </div>
              <div className="sec-cont">
                <div className="card-radio-group">
                  <RadioGroupField
                    name="language-selection"
                    radioField={languageSelectionRadio}
                    value={selectedLang}
                    radioChange={languageChange}
                  ></RadioGroupField>
                </div>
              </div>
            </div>
            <div className="form-foot">
              <Button
                btnStyle="primary"
                type="button"
                onClick={confirmLanguage}
              >
                {t("onboard_langConfirmBtn")}
              </Button>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default LanguageSelection;
