import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface PortfolioCardData {
  companyId: string;
  companyName: string;
  companyLogo: string;
  companyLocation: string;
  requestCount: number;
  investmentCount: number;
  buyPrice: string;
  lastTradePrice: string;
}

function PortfolioCard(props: any) {
  const { i18n, t } = useTranslation(["PortfolioDetails"]);
  return (
    <div className="bs-portfolio-card">
      <Link to={`/portfolio-detail?id=${props?.id}`}>
        <div className="head-wrap">
          <div className="profile-wrap">
            <div className="profile-logo">
              <img src={props?.Company?.companylogo} alt={props?.Company?.name} />
            </div>
            <div className="profile-cont">
              <strong className="profile-name">{props?.Company?.name}</strong>
              <div className="tag-wrap">
                <span>{props?.Company?.country}</span>
                <span>
                  {t("portfoliodetails_activeRequestsTitle")}:{" "}
                  {props?.Company?.activerequest}
                </span>
              </div>
            </div>
            <div className={`${styles.labelValueWrap}`}>
              <span className={`${styles.label}`}>
                {t("portfoliodetails_myDrs")}
              </span>
              <span className={`${styles.value}`}>
                {props?.count} {t("portfoliodetails_drs")}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default PortfolioCard;
