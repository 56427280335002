import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import CompanyChatCard from "src/components/company-chat-card/CompanyChatCard";
import React from "react";
import { Tab, Tabs } from "@mui/material";
import RequestCard from "src/components/request-card/RequestCard";
import Exploration from "src/components/exploration/exploration";
import { getSessionKey } from "src/helper/sessionServices";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Header from "src/components/common/header/Header";
import axios from "axios";
import HighlightCard from "src/components/highlight-card/HighlightCard";
import { useNavigate } from "react-router-dom";

function UserHighlights() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Highlight"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [cards, setcards] = useState<any[]>([
    {
      companyId: "001",
      companyName: "Banyan Nation",
      companyLocation: "India",
      companyLogo: `/assets/logos/banyan-nation-logo.png`,
      holdingCount: 500,
      cardType: "incoming",
      requestDate: "12th Oct’23",
      requestMessage: "Message Box",
      isNew: false,
      interestedProfile: [
        // {
        //   cardId: "02",
        //   cardType: "buyer",
        //   date: "12th Oct’23",
        //   count: 1,
        //   chatFeature: true,
        // },
      ],
    },
    {
      companyId: "002",
      companyName: "Vilcart",
      companyLocation: "India",
      companyLogo: `/assets/logos/vilcart-logo.png`,
      holdingCount: 500,
      cardType: "outgoing",
      requestDate: "12th Oct’23",
      requestMessage: "Message Box",
      isNew: false,
      interestedProfile: [],
    },
    {
      companyId: "003",
      companyName: "Banyan Nation",
      companyLocation: "India",
      companyLogo: `/assets/logos/banyan-nation-logo.png`,
      holdingCount: 500,
      cardType: "pending",
      requestDate: "12th Oct’23",
      requestMessage: "Message Box",
      isNew: false,
      interestedProfile: [
        {
          cardId: "01",
          cardType: "seller",
          date: "12th Oct’23",
          count: 2,
          chatFeature: true,
        },
        {
          cardId: "02",
          cardType: "seller",
          date: "12th Oct’23",
          count: 1,
          chatFeature: true,
        },
      ],
    },
    {
      companyId: "002",
      companyName: "Vilcart",
      companyLocation: "India",
      companyLogo: `/assets/logos/vilcart-logo.png`,
      holdingCount: 500,
      cardType: "outgoing",
      requestDate: "12th Oct’23",
      requestMessage: "Message Box",
      isNew: false,
      interestedProfile: [
        {
          cardId: "01",
          cardType: "seller",
          date: "12th Oct’23",
          count: 2,
          chatFeature: true,
        },
        {
          cardId: "02",
          cardType: "seller",
          date: "12th Oct’23",
          count: 1,
          chatFeature: true,
        },
      ],
    },
  ]);
  const outgoingCard = cards.filter((obj) => {
    return obj.cardType === "outgoing";
  });
  const incomingCard = cards.filter((obj) => {
    return obj.cardType === "incoming";
  });
  const pendingCard = cards.filter((obj) => {
    return obj.cardType === "pending";
  });

  const [triggerGetHighlight, setTriggerGetHighlight] = useState<boolean>(true);
  const [highlightFav, setHighlightFav] = React.useState<any[]>([]);
  const [highlightInterested, setHighlightInterested] = React.useState<any[]>(
    []
  );
  useEffect(() => {
    if (triggerGetHighlight) {
      setLoading(true);
      // Make a GET request with the specified headers
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/highlight?lang_id=${getSessionKey("userData")?.lang_id}`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          console.log("response", response.data.data);
          let tempFav: any[] = [];
          let tempInterested: any[] = [];
          response.data.data.forEach((item: any) => {
            if (item.HighlightInterestNFavourites.length > 0) {
              item.HighlightInterestNFavourites.forEach((element: any) => {
                if (element.type === "interested") {
                  tempInterested.push(item);
                } else if (element.type === "favourite") {
                  tempFav.push(item);
                }
              });
            }
          });
          setHighlightFav(tempFav);
          setHighlightInterested(tempInterested);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });

      setTriggerGetHighlight(false);
    }
  }, [triggerGetHighlight]);

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <header>
            <Header backLink={true} pageTitle="Highlights"></Header>
          </header>
          <main>
            <div className="lyt-main typ-main">
              <section>
                <div className="bs-section typ-depth">
                  <div className="sec-cont">
                    <div className="bs-tabs typ-btn">
                      <Tabs value={value} onChange={handleChange}>
                        <Tab label={t("highlight_interestedTab")} />
                        <Tab label={t("highlight_favouriteTab")} />
                      </Tabs>
                      <div className={`${styles.panelWrap}`}>
                        <div
                          className={`${styles.tabPanel}`}
                          role="tabpanel"
                          hidden={value !== 0}
                        >
                          <div className={`${styles.bidsList}`}>
                            {highlightInterested.map((item, index) => {
                              return (
                                <div className={`${styles.item}`} key={index}>
                                  <HighlightCard
                                    {...item}
                                    setTriggerGetHighlight={
                                      setTriggerGetHighlight
                                    }
                                  ></HighlightCard>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div
                          className={`${styles.tabPanel}`}
                          role="tabpanel"
                          hidden={value !== 1}
                        >
                          <div className={`${styles.bidsList}`}>
                            {highlightFav.map((item, index) => {
                              return (
                                <div className={`${styles.item}`} key={index}>
                                  <HighlightCard
                                    {...item}
                                    setTriggerGetHighlight={
                                      setTriggerGetHighlight
                                    }
                                  ></HighlightCard>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default UserHighlights;
