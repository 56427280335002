import React, { useEffect } from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "src/components/common/header/Header";
import { getSessionKey } from "src/helper/sessionServices";
import introPageData from "../../data/activationSteps.json";

export const StepContext = React.createContext<{
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
} | null>(null);
function DetailsForm() {
  const [step, setStep] = useState(1);
  const userRole = getSessionKey("userRole");
  const typedintroPageData = introPageData as Record<
    "en" | "it",
    { [key: string]: any }
  >;
  return (
    <StepContext.Provider value={{ step, setStep }}>
      <header>
        <Header
          backLink={true}
          showProgress={true}
          currentStep={step}
          totalSteps={
            typedintroPageData["en"][userRole as keyof typeof introPageData]
              .activationSteps.length
          }
        ></Header>
      </header>
      <Outlet />
    </StepContext.Provider>
  );
}

export default DetailsForm;
