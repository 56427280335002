import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import Button from "src/components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import RequestCard from "src/components/request-card/RequestCard";
import { formatDate } from "src/helper/formatDate";

function RequestDetails() {
  const { i18n, t } = useTranslation(["Chat"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const [openDeleteDrawer, setOpenDeleteDrawer] = useState(false);
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  const requestDetailData = getSessionKey("requestChatDetail");
  console.log("requestDetailData", requestDetailData);
  return (
    <>
      <header>
        <Header
          backLink={true}
          actionItems={["deleteRequest"]}
          pageTitle={t("chat_requestDetails")}
          deleteRequestDataId={requestDetailData.activerequestid}
        ></Header>
      </header>
      <main>
        <div className="bs-portfolio-card typ-chat">
          <div className="head-wrap">
            <div className="profile-wrap">
              <div className="profile-logo">
                <img
                  src={requestDetailData?.Company?.companylogo}
                  alt={requestDetailData?.Company?.name}
                />
              </div>
              <div className="profile-cont">
                <strong className="profile-name">
                  {requestDetailData?.Company?.name}
                </strong>
                {requestDetailData.cardType === "incoming" ? (
                  <div className="tag-wrap">
                    <span>{requestDetailData?.Company?.country}</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {requestDetailData.cardType === "outgoing" ? (
              <span className="bid-date">
                {formatDate(requestDetailData.createdAt)}
              </span>
            ) : (
              ""
            )}
            {requestDetailData.cardType === "incoming" ? (
              <div className={`${styles.holdingValueWrap}`}>
                <span className={`${styles.label}`}>
                  {t("chat_holdingsTitle")}
                </span>
                <span className={`${styles.value}`}>
                  {requestDetailData?.Company?.Portfolios[0]?.count} DRs
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* {requestDetailData?.message &&
            <div className="req-description">
              <p>{requestDetailData?.message}</p>
            </div>
          } */}
          {/* {requestDetailData.cardType === "chat" ? (
            <div className="foot-wrap">
              <div className="label-value-wrap">
                <span className="label">No. of DRs</span>
                <span className="value">{requestDetailData.investmentCount} DRs</span>
              </div>
              <div className="label-value-wrap">
                <span className="label">Price per DR</span>
                <span className="value">{requestDetailData.currentPrice}</span>
              </div>
              <div className="label-value-wrap">
                <span className="label">Bid Price</span>
                <span className="value">{requestDetailData.bidPrice}</span>
              </div>
            </div>
          ) : (
            <></>
          )} */}
          {/* {console.log(requestDetailData, "requestDetailData")} */}
          {/* {requestDetailData.request_status === "accepted" && ( */}
          <div
            className={`seller-wrap ${
              requestDetailData?.activerequestchathistory?.length <= 0
                ? "disabled"
                : ""
            }`}
          >
            <Accordion
              className="bs-accordion"
              defaultExpanded={true}
              disabled={
                requestDetailData?.activerequestchathistory?.length <= 0
              }
            >
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <div className="seller-head">
                  <strong>
                    Chats
                    <span className="request-count">
                      {requestDetailData?.activerequestchathistory?.length}
                    </span>
                  </strong>
                </div>
              </AccordionSummary>
              {requestDetailData?.activerequestchathistory?.length > 0 ? (
                <AccordionDetails>
                  <div className="seller-cont">
                    <ul className="list">
                      {requestDetailData?.activerequestchathistory.map(
                        (item: any, index: number) => {
                          return (
                            <li key={index}>
                              <RequestCard
                                parentRef="companyChatCard"
                                index={index}
                                data={item}
                              ></RequestCard>
                            </li>
                          );
                        }
                      )}
                      {/* <li>
                        <RequestCard parentRef="companyChatCard" index={1}></RequestCard>
                      </li>
                      <li>
                        <RequestCard parentRef="companyChatCard" index={1}></RequestCard>
                      </li> */}
                    </ul>
                  </div>
                </AccordionDetails>
              ) : (
                <></>
              )}
            </Accordion>
          </div>
          {/* )} */}
        </div>
      </main>
      <BottomSheet
        title={t("chat_requestDetailsDrawerTitle")}
        subText={t("chat_requestDetailsSubDrawerTitle")}
        primaryBtnText={t("chat_requestDetailsDrawerDeleteBtn")}
        size="medium"
        open={openDeleteDrawer}
        setOpen={setOpenDeleteDrawer}
      >
        <div className={`${styles.contentWrap}`}>
          <p className={`${styles.desc}`}>
            {t("chat_requestDetailsDrawerMessage")}
          </p>
        </div>
      </BottomSheet>
    </>
  );
}

export default RequestDetails;
