import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface OpportunitiesCardData {
  id: number;
  companylogo: string;
  companycode: string;
  country: string;
  activerequest: string;
  ebita: string;
  name: string;
  turnover: string;
}

function OpportunitiesCard(props: OpportunitiesCardData) {
  const { i18n, t } = useTranslation(["CompanyDetails"]);
  return (
    <div className="bs-portfolio-card typ-opportunities">
      <Link to={`/company-details?id=${props.id}`}>
        <div className="head-wrap">
          <div className="profile-wrap">
            <div className="profile-logo">
              <img src={props.companylogo} alt={props.companycode} />
            </div>
            <div className="profile-cont">
              <strong className="profile-name">{props.name}</strong>
              <div className="tag-wrap">
                <span>{props.country}</span>
              </div>
            </div>
          </div>
          <div className="active-requests">
            <span className="label">
              {t("companydetails_activeRequestsTitle")}
            </span>
            <span className="value">{props.activerequest}</span>
          </div>
        </div>
        <div className="foot-wrap">
          <div className="label-value-wrap">
            <span className="label">EBITDA(YoY):</span>
            <span className="value">{props.ebita}%</span>
          </div>
          <div className="label-value-wrap">
            <span className="label">Turnover(YoY):</span>
            <span className="value">{props.turnover}%</span>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default OpportunitiesCard;
