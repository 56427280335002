import React from "react";
import "./Loader.css"; // You can define your loader styles here
import { Player } from "@lottiefiles/react-lottie-player";
import loadingAnimation from "../../../animation/loading-animation.json";
import { useLoader } from "src/context/LoaderContext";

const Loader: React.FC = () => {
  const { loading } = useLoader(); // Using the loading state from the context

  if (!loading) return null;
  return (
    <div className="loader-container">
      {/* <div className="loader"></div> */}
      <Player
        style={{ height: "20rem", width: "20rem" }}
        background=""
        src={loadingAnimation}
        loop
        autoplay
      />
    </div>
  );
};

export default Loader;
