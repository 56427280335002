import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ChatProfileCard, {
  ChatProfileCardData,
} from "../chat-profile-card/ChatProfileCard";
import RequestCard from "../request-card/RequestCard";
import styles from "./style.module.scss";
import { formatDate } from "src/helper/formatDate";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";

// export interface CompanyChatCardData {
//   companyId: string;
//   companyName: string;
//   companyLogo: string;
//   companyLocation?: string;
//   requestDate?: string;
//   holdingCount?: number;
//   requestMessage?: string;
//   isNew?: boolean;
//   cardType?: "outgoing" | "incoming" | "pending";
//   interestedProfile?: ChatProfileCardData[];
// }

function CompanyChatCard(props: any) {
  const { i18n, t } = useTranslation(["Chat"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const handleOnClick = () => {
    setSessionKey("requestChatDetail", props);
    navigate("/chat-request-details");
  };
  // console.log("props", props);
  return (
    <div className="bs-portfolio-card typ-chat">
      <div className="head-wrap" onClick={handleOnClick}>
        <div className="profile-wrap">
          <div className="profile-logo">
            <img src={props?.Company?.companylogo} alt={props?.Company?.name} />
          </div>
          <div className="profile-cont">
            <strong className="profile-name">{props?.Company?.name}</strong>
            {props.cardType === "incoming" ? (
              <div className="tag-wrap">
                <span>{props?.Company?.country}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {props.cardType === "outgoing" ? (
          <span className="bid-date">{formatDate(props.createdAt)}</span>
        ) : (
          ""
        )}
        {props.cardType === "incoming" ? (
          <div className={`${styles.holdingValueWrap}`}>
            <span className={`${styles.label}`}>{t("chat_holdingsTitle")}</span>
            <span className={`${styles.value}`}>
              {props?.Company?.Portfolios[0]?.count} DRs
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* {props?.message &&
        <div className="req-description">
          <p>{props?.message}</p>
        </div>
      } */}
      {/* {props.cardType === "chat" ? (
        <div className="foot-wrap">
          <div className="label-value-wrap">
            <span className="label">No. of DRs</span>
            <span className="value">{props.investmentCount} DRs</span>
          </div>
          <div className="label-value-wrap">
            <span className="label">Price per DR</span>
            <span className="value">{props.currentPrice}</span>
          </div>
          <div className="label-value-wrap">
            <span className="label">Bid Price</span>
            <span className="value">{props.bidPrice}</span>
          </div>
        </div>
      ) : (
        <></>
      )} */}
      {/* {console.log(props, "Props")} */}
      {/* {props.request_status === "accepted" && ( */}
      <div
        className={`seller-wrap ${
          props?.activerequestchathistory?.length <= 0 ? "disabled" : ""
        }`}
      >
        <Accordion
          className="bs-accordion"
          defaultExpanded={true}
          disabled={props?.activerequestchathistory?.length <= 0}
        >
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
            <div className="seller-head">
              <strong>
                Chats
                {props?.request_status === "pending" && props.cardType === "outgoing" ?
                  null
                : (
                  <span className="request-count">
                    {props?.activerequestchathistory?.length}
                  </span>
                )}
              </strong>
            </div>
          </AccordionSummary>
          {props?.activerequestchathistory?.length > 0 ? (
            <AccordionDetails>
              <div className="seller-cont">
                <ul className="list">
                  {props?.request_status === 'pending' && props.cardType === "outgoing" ? (<>
                    <li>
                      <RequestCard
                        parentRef="companyChatCard"
                        index={0}
                        data={props?.activerequestchathistory[0]}
                        status={props?.request_status}
                        cardType={props?.cardType}
                      ></RequestCard>
                    </li>
                  </>) : (<>
                    {props?.activerequestchathistory.map(
                      (item: any, index: number) => {
                        return (
                          <li key={index}>
                            <RequestCard
                              parentRef="companyChatCard"
                              index={index}
                              data={item}
                              status={props?.request_status}
                              cardType={props?.cardType}
                            ></RequestCard>
                          </li>
                        );
                      }
                    )}
                  </>)}
                  {/* <li>
                    <RequestCard parentRef="companyChatCard" index={1}></RequestCard>
                  </li>
                  <li>
                    <RequestCard parentRef="companyChatCard" index={1}></RequestCard>
                  </li> */}
                </ul>
              </div>
            </AccordionDetails>
          ) : (
            <></>
          )}
        </Accordion>
      </div>
      {/* )} */}
    </div>
  );
}

export default CompanyChatCard;
