import Header from "src/components/common/header/Header";
import Button from "../../components/common/button/Button";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { useEffect } from "react";

function AddClients() {
  const { i18n, t } = useTranslation(["PortfolioDetails"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/add-client");
  };
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  return (
    <div className={`${styles.logoContentWrap}`}>
      <div className={`${styles.image}`}>
        <img src="/assets/icons/user-add.svg" alt="add-client" />
      </div>
      <div className={`${styles.contentWrap}`}>
        <h2 className={`${styles.title}`}>
          {t("portfoliodetails_addClientTitle")}
        </h2>
        <p className={`${styles.description}`}>
          {t("portfoliodetails_clientSubTitle")}
        </p>
      </div>
      <div className={`${styles.footWrap}`}>
        <Button btnStyle="primary" onClick={handleClick}>
          {t("portfoliodetails_addClientTitle")}
        </Button>
      </div>
    </div>
  );
}

export default AddClients;
