import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface Menuitems {
  menuTitle: string;
  isActive: boolean;
  iconName: string;
}
function NavBar() {
  const { i18n, t } = useTranslation(["NavBar"]);
  return (
    <div className="bs-navbar">
      <ul className="nav-list">
        <li className="nav-item">
          <NavLink to="/impact">
            <i className="home"></i>
            <span>{t("navbar_home")}</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/portfolios">
            <i className="portfolio"></i>
            <span>{t("navbar_portfolio")}</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/opportunities">
            <i className="opportunities"></i>
            <span>{t("navbar_opportunities")}</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/chat">
            <i className="chat"></i>
            <span>{t("navbar_chat")}</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default NavBar;
