import { useState } from "react";
import SuccessError, {
  AcknowledgementMsgProp,
} from "src/components/success-error/SuccessError";
import Button from "../../components/common/button/Button";
import styles from "./style.module.scss";
import { getSessionKey } from "src/helper/sessionServices";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
function ClientApprove() {
  const { i18n, t } = useTranslation(["Acknowledgement"]);
  const lang = getSessionKey("selectedLangCode");
  const settingType = getSessionKey("settingType");
  let contentText = "";
  let titleText = "";
  if (settingType === "Email") {
    titleText = `${t(`${t("updateacknowledgement_emailTitle")}`)}`;
    contentText = `${t(`${t("updateacknowledgement_emailSubTitle")}`)}`;
  } else if (settingType === "Phone") {
    titleText = `${t(`${t("updateacknowledgement_phoneTitle")}`)}`;
    contentText = `${t(`${t("updateacknowledgement_phoneSubTitle")}`)}`;
  } else if (
    settingType === "Identity" ||
    settingType === "Residence" ||
    settingType === "Company" ||
    settingType === "UBO" ||
    settingType === "LR"
  ) {
    titleText = `${t("updateacknowledgement_detailsTitle")}`;
    contentText = `${t("updateacknowledgement_detailsSubTitle")}`;
  } else {
    if (getSessionKey("userData")?.role === "advisor") {
      titleText = `${t(`${t("updateacknowledgement_clientTitle")}`)}`;
      contentText = `${t(`${t("updateacknowledgement_clientSubTitle")}`)}`;
    } else {
      titleText = `${t(`${t("updateacknowledgement_advisorTitle")}`)}`;
      contentText = `${t(`${t("updateacknowledgement_advisorSubTitle")}`)}`;
    }
  }
  const [acknowledgementMsg, setAcknowledgementMsg] =
    useState<AcknowledgementMsgProp>({
      title: titleText,
      content: contentText,
      type: "success",
    });

  return (
    <>
      <SuccessError {...acknowledgementMsg}></SuccessError>
      <div className={`${styles.footWrap}`}>
        <Button btnStyle="primary" navigation={true} navigationUrl="/impact">
          {t("acknowledgement_proceedBtn")}
        </Button>
      </div>
    </>
  );
}

export default ClientApprove;
