import { useState, useEffect } from "react";
import styles from "./style.module.scss";
import Header from "src/components/common/header/Header";
import HighlightCard, {
  HighlightCardData,
} from "src/components/highlight-card/HighlightCard";
import React from "react";
import { Tab, Tabs } from "@mui/material";
import axios from "axios";
import { authToken, headers } from "src/helper/authToken";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { useNavigate } from "react-router-dom";

function HighlightPage() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Highlight"]);
  const lang = getSessionKey("selectedLangCode");
  const [value, setValue] = React.useState(0);
  const [highlightData, setHighlightData] = useState<HighlightCardData[]>([]);
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  useEffect(() => {
    // Make a GET request with the specified headers
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/highlight?lang_id=${getSessionKey("userData")?.lang_id}`,
        { headers }
      )
      .then((response) => {
        setHighlightData(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const pageTabItems = [
    {
      id: "tab01",
      title: "Bids",
      isActive: false,
    },
    {
      id: "tab02",
      title: "Offers",
      isActive: false,
    },
  ];
  /* const [cards, setCards] = useState<HighlightCardData[]>([
    {
      eventType: "communication",
      subtype: "Important",
      name: "Navigating Business Opportunities in India",
      imageUrl: `/assets/logos/online.png`,
      provience: "",
      country:"",
      eventdate: "29th May, 24",
      eventtime: "4-5pm CET",
      isFavorite: true,
      eventendtime:"",
      addhome:true,
    },
    {
      eventType: "offline",
      name: "Focus on the Rural Ecosystem",
      imageUrl: `/assets/logos/offline.png`,
      provience: "Milan, Italy",
      country:"",
      eventdate: "22nd Sept, 24",
      eventtime: "4-5pm CET",
      isFavorite: false,
      eventendtime:"",
      addhome:true,
    },
    {
      eventType: "communication",
      subtype: "Research",
      name: "Addressing Volatility in South Asia",
      imageUrl: `/assets/logos/arya-logo.png`,
      provience: "",
      country:"",
      eventdate: "29th May, 24",
      eventtime: "",
      isFavorite: true,
      eventendtime:"",
      addhome:true,
    },
  ]); */
  const interestedCard = highlightData.filter((obj) => {
    return obj.isFavorite === false;
  });
  const favoriteCard = highlightData.filter((obj) => {
    return obj.isFavorite === true;
  });
  return (
    <>
      <header>
        <Header backLink={true} pageTitle="Highlights"></Header>
      </header>
      <main>
        <div className="lyt-main">
          <section>
            <div className="bs-section typ-depth">
              <div className="sec-cont">
                <div className="bs-tabs typ-btn typ-highlight">
                  <Tabs value={value} onChange={handleChange}>
                    <Tab label={t("highlight_interestedTab")} />
                    <Tab label={t("highlight_favouriteTab")} />
                  </Tabs>
                  <div className={`${styles.panelWrap}`}>
                    <div
                      className={`${styles.tabPanel}`}
                      role="tabpanel"
                      hidden={value !== 0}
                    >
                      <div className={`${styles.highlightList}`}>
                        {interestedCard.map((item, index) => {
                          return (
                            <div key={index} className={`${styles.item}`}>
                              <HighlightCard {...item}></HighlightCard>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className={`${styles.tabPanel}`}
                      role="tabpanel"
                      hidden={value !== 1}
                    >
                      <div className={`${styles.highlightList}`}>
                        {favoriteCard.map((item, index) => {
                          return (
                            <div key={index} className={`${styles.item}`}>
                              <HighlightCard {...item}></HighlightCard>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default HighlightPage;
