import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { LoaderProvider } from "./context/LoaderContext";
import { Suspense } from "react";
// import { Provider } from "react-redux";
// import { store } from "./store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    {/* <Provider store={store}> */}
    <Suspense fallback={"loading"}>
      <LoaderProvider>
        <App />
      </LoaderProvider>
    </Suspense>
    {/* </Provider> */}
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
