export const convertTime = (timeString: string, endTime: string) => {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes] = timeString.split(":").map(Number);
  const [hours1, minutes1] = endTime.split(":").map(Number);

  // Determine if it's AM or PM
  const period = hours >= 12 ? "PM" : "AM";
  const period1 = hours1 >= 12 ? "PM" : "AM";
  // Convert hours to 12-hour format
  const displayHours = hours % 12 || 12;
  const displayHours1 =
    timeString === endTime ? hours1 + (1 % 12) || 12 : hours1 % 12 || 12;

  const displayMinute = String(minutes).padStart(2, "0");
  const displayMinute1 = String(minutes1).padStart(2, "0");
  // Construct the new time string
  const formattedTime =
    period === period1
      ? `${displayHours}:${displayMinute} - ${displayHours1}:${displayMinute1} ${period1} `
      : `${displayHours}:${displayMinute} ${period}` +
        `-` +
        `${displayHours1}:${displayMinute1} ${period1} `;

  return formattedTime;
};
