import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";

function AiMessage() {
  const { i18n, t } = useTranslation(["Chat"]);
  const location = useLocation();
  const lang = getSessionKey("selectedLangCode");
  const [notificationData, setNotificationData] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    i18next.changeLanguage(lang);
    const userDataNotification =
      location.state.reference === "notification"
        ? getSessionKey("notificationData")
        : "";
    const filteredData = userDataNotification.filter((item: any) => {
      return item.id === location.state.notificationId;
    });
    setNotificationData(filteredData[0]);
    console.log(filteredData);
  }, []);
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main">
          <section>
            <div className="bs-section typ-news">
              <div className={`${styles.contWrap}`}>
                <div className={`${styles.card}`}>
                  <div className={`${styles.cardHead}`}>
                    <h2 className={`${styles.cardTitle}`}>
                      Message from AI-X Officer
                    </h2>
                  </div>
                  <div className={`${styles.cardCont}`}>
                    <p className={`${styles.desc}`}>
                      {notificationData?.message}
                    </p>
                  </div>
                </div>
                <div className={`${styles.card}`}>
                  <div className={`${styles.companyWrap}`}>
                    <span className={`${styles.companyLogo}`}>
                      <img
                        src={notificationData.company?.companylogo}
                        alt={notificationData.company?.name}
                      />
                    </span>
                    <div className={`${styles.companyIntro}`}>
                      <strong className={`${styles.companyName}`}>
                        {notificationData.company?.name}
                      </strong>
                    </div>
                    <span className={`${styles.tag}`}>
                      {notificationData.company?.country}
                    </span>
                  </div>
                </div>
                <div className={`${styles.card}`}>
                  <div className={`${styles.labelValue}`}>
                    <span className={`${styles.label}`}>Involved Parties</span>
                    <strong className={`${styles.value}`}>
                      {notificationData.Buyer_details?.role === "legalrepresent"
                        ? notificationData.Buyer_details?.lrDetails[0]
                            ?.companyname
                        : notificationData.Buyer_details?.userdetail
                            ?.firstname}{" "}
                      &{" "}
                      {notificationData.Seller_details?.role ===
                      "legalrepresent"
                        ? notificationData.Seller_details?.lrDetails[0]
                            ?.companyname
                        : notificationData.Seller_details?.userdetail
                            ?.firstname}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className={`${styles.footWrap}`}>
            <Button navigationUrl="/chat" navigation={true} btnStyle="primary">
              OK
            </Button>
          </div>
        </div>
      </main>
    </>
  );
}

export default AiMessage;
