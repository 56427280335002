import { loginActionTypes } from "src/store/actionTypes/auth/login";
import { LoginAction } from "src/store/actions/auth/login";

interface LoginStates {
  loading: boolean,
  error: object | null,
  user: object | null,
  isLoggedIn: boolean
}

const initialState = {
  loading: false,
  user: null,
  isLoggedIn: false,
  error: null
};

const loginReducer = (state: LoginStates = initialState, action: LoginAction) => {
    switch (action.type) {
      case loginActionTypes.LOGIN_USER:
        return {
          loading: true,
          user: null,
          isLoggedIn: false,
          error: null
        };

      case loginActionTypes.LOGIN_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
          isLoggedIn: true,
          error: null
        };

      case loginActionTypes.LOGIN_USER_FAIL:
        return {
          ...state,
          loading: false,
          user: null,
          isLoggedIn: false,
          error: action.payload
        };

      default:
        return state;
    }
};
  
export default loginReducer;