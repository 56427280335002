import { useState } from "react";
import SuccessError, {
  AcknowledgementMsgProp,
} from "src/components/success-error/SuccessError";
import Button from "../../components/common/button/Button";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";

function ClientReject() {
  const { i18n, t } = useTranslation(["Acknowledgement"]);
  const lang = getSessionKey("selectedLangCode");
  const [acknowledgementMsg, setAcknowledgementMsg] =
    useState<AcknowledgementMsgProp>({
      title: `${t("updateacknowledgement_clientRejectTitle")}`,
      content: `${t("updateacknowledgement_clientRejectSubTitle")}`,
      type: "error",
    });

  return (
    <>
      <SuccessError {...acknowledgementMsg}></SuccessError>
      <div className={`${styles.footWrap}`}>
        <Button btnStyle="primary" navigation={true} navigationUrl="/impact">
          {t("acknowledgement_proceedBtn")}
        </Button>
      </div>
    </>
  );
}

export default ClientReject;
