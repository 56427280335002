import Header from "src/components/common/header/Header";
import Button from "../../components/common/button/Button";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import { getSessionKey } from "src/helper/sessionServices";

function DeletePage() {
  const { loading, setLoading, setUserData } = useLoader();
  const { i18n, t } = useTranslation(["Delete"]);
  const lang = getSessionKey("selectedLangCode");
  const [openDeleteDrawer, setOpenDeleteDrawer] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const navigate = useNavigate();
  const handleNavigate = () => {
    setLoading(true);
    axios
      .delete(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user/del?id=${
          getSessionKey("authToken")?.id
        }&deletereason=${deleteReason}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          let historyData = {
            reference: "delete user",
          };
          setUserData(null);
          navigate("/acknowledgement", { state: historyData });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  const handleRadio = (e: any) => {
    setDeleteReason(e.target.value);
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className={`${styles.introScreen} introScreen`}>
          <div className={`${styles.logoContentWrap}`}>
            <i className={`${styles.danger}`}></i>
            <div className={`${styles.contentWrap}`}>
              <h2 className={`${styles.title}`}>{t("delete_warnTitle")}</h2>
              <p className={`${styles.description}`}>
                {t("delete_warnMessage")}
              </p>
              <p className={`${styles.warnNote}`}>
                {t("delete_confirmationTitle")}
              </p>
            </div>
          </div>
        </div>
      </main>
      <div className={`${styles.footWrap}`}>
        <Button btnStyle="primary" onClick={() => setOpenDeleteDrawer(true)}>
          {t("delete_proceedBtn")}
        </Button>
      </div>
      <BottomSheet
        title={t("delete_drawerTitle")}
        primaryBtnText={t("delete_drawerSubmitBtn")}
        size="large"
        open={openDeleteDrawer}
        setOpen={setOpenDeleteDrawer}
        btnOnClick={handleNavigate}
      >
        <div className={`${styles.drawer}`}>
          <div className={`${styles.titleWrap}`}>
            <span className={`${styles.title}`}>
              {t("delete_drawerSubTitle")}
            </span>
          </div>
          <ul className={`${styles.list}`}>
            <li className={`${styles.item}`}>
              <input
                type="radio"
                id="delete1"
                name="deleteRequest"
                value={t("delete_drawerOptionNotUsing")}
                onChange={handleRadio}
              />
              <label htmlFor="delete1">
                {t("delete_drawerOptionNotUsing")}
              </label>
            </li>
            <li className={`${styles.item}`}>
              <input
                type="radio"
                id="delete2"
                name="deleteRequest"
                value={t("delete_drawerOptionPrivacy")}
                onChange={handleRadio}
              />
              <label htmlFor="delete2">{t("delete_drawerOptionPrivacy")}</label>
            </li>
            <li className={`${styles.item}`}>
              <input
                type="radio"
                id="delete3"
                name="deleteRequest"
                value={t("delete_drawerOptionLegal")}
                onChange={handleRadio}
              />
              <label htmlFor="delete3">{t("delete_drawerOptionLegal")}</label>
            </li>
            <li className={`${styles.item}`}>
              <input
                type="radio"
                id="delete4"
                name="deleteRequest"
                value={t("delete_drawerOptionOther")}
                onChange={handleRadio}
              />
              <label htmlFor="delete4">{t("delete_drawerOptionOther")}</label>
            </li>
          </ul>
          <div className={`${styles.content}`}>
            <i className={`${styles.error}`}></i>
            <span>{t("delete_drawerInfoMessage")}</span>
          </div>
        </div>
      </BottomSheet>
    </>
  );
}

export default DeletePage;
